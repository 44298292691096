import houseOne from "../../tmp/houses/house_1.png";
import houseTwo from "../../tmp/houses/house_2.png";
import Sponsor1 from "../../tmp/sponsor/Sponsor1.png";
import Sponsor2 from "../../tmp/sponsor/Sponsor2.png";
import facebookSvg from "../../assets/img/icon/facebookSvg.svg";
import linkedinSvg from "../../assets/img/icon/linkedinSvg.svg";
import youtubeSvg from "../../assets/img/icon/youtubeSvg.svg";
import pinterestSvg from "../../assets/img/icon/pinterestSvg.svg";
import instagramSvg from "../../assets/img/icon/instagramSvg.svg";

export const Api = {
  houses: [
    {
      image: houseOne,
      roof_type: "gable",
      house_area: ["18,3", "48,1"],
      price: "30,000",
      delivery: "4 - 12 weeks",
    },
    {
      image: houseTwo,
      roof_type: "flat",
      house_area: ["18,3", "48,1"],
      price: "30,000",
      delivery: "4 - 12 weeks",
    },
    {
      image: houseOne,
      roof_type: "gable",
      house_area: ["18,3", "48,1"],
      price: "30,000",
      delivery: "4 - 12 weeks",
    },
  ],
};

const description =
  "Nibh cras pulvinar mattis nunc. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate. Ridiculus mus mauris vitae ultricies leo integer malesuada nunc. A scelerisque purusSemper eget duis at tellus. Aliquam ut porttitor leo a diam. Facilisis mauris sit amet massa. Dolor sit amet consectetur adipiscing elit ut aliquam purus sit. Nisi est sit amet facilisis magna.";

export const QuestionApi = [
  {
    title: "Question #1",
    description: description,
  },
  {
    title: "Question #2",
    description: description,
  },
  {
    title: "Question #3",
    description: description,
  },
  {
    title: "Question #4",
    description: description,
  },
  {
    title: "Question #5",
    description: description,
  },
  {
    title: "Question #6",
    description: description,
  },
  {
    title: "Question #7",
    description: description,
  },
];

export const FooterApi = [
  {
    url: "",
    image: facebookSvg,
  },
  {
    url: "",
    image: linkedinSvg,
  },
  {
    url: "",
    image: pinterestSvg,
  },
  {
    url: "",
    image: youtubeSvg,
  },
  {
    url: "",
    image: instagramSvg,
  },
];
