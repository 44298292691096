import productsBg from "../../assets/img/products.jpg";
import ConstructorSection from "../common/Constructor/ConstructorSection";
import HouseSection from "../common/House/HouseSection";
import {
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
  Background,
} from "../common/common";

const ProductsPage = function () {
  return (
    <CommonContent>
      <MainWrapper>
        <Background src={productsBg} />
        <Main>
          <MainTitle>PRODUCTS</MainTitle>
        </Main>
      </MainWrapper>
      <HouseSection view={true} />
      <ConstructorSection />
    </CommonContent>
  );
};

export default ProductsPage;
