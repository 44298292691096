import { Container } from "../common";
import {
  ProcessViewItem,
  ProcessViewTitle,
  ProcessViewItemHr,
  ProcessViewItemText,
  ProcessViewItemTitle,
  ProcessViewWrapper,
  ProcessViewItemWrapper,
  ProcessImage,
} from "./style";
import processSectionSvg from "../../../assets/img/icon/processSectionSvg.svg";

import { useInternacionalization } from "hooks";

const ProcessView = function () {
  const { translate } = useInternacionalization();

  return (
    <Container>
      <ProcessViewWrapper>
        <ProcessViewTitle>{translate("Process")}</ProcessViewTitle>
        <ProcessViewItemWrapper>
          <ProcessViewItem column={1}>
            <ProcessViewItemTitle>reserve online</ProcessViewItemTitle>
            <ProcessViewItemHr />
            <ProcessViewItemText>
              Customer support will contact you
            </ProcessViewItemText>
          </ProcessViewItem>
          <ProcessViewItem column={2} min={true}>
            <ProcessViewItemTitle>10%</ProcessViewItemTitle>
            <ProcessViewItemText>Reservation deposit 10%</ProcessViewItemText>
            <ProcessViewItemHr />
            <ProcessViewItemText>
              Receiving AR drawings for building permit
            </ProcessViewItemText>
          </ProcessViewItem>
          <ProcessViewItem column={3} min={true}>
            <ProcessViewItemTitle>40%</ProcessViewItemTitle>
            <ProcessViewItemText>
              Signing contract. <pr />
              Start of production
            </ProcessViewItemText>
            <ProcessViewItemHr />
            <ProcessViewItemText>
              Finalising order and start of production
            </ProcessViewItemText>
          </ProcessViewItem>
          <ProcessViewItem column={4}>
            <ProcessViewItemTitle>
              12 - 16 weeks production time
            </ProcessViewItemTitle>
          </ProcessViewItem>
          <ProcessViewItem column={5} min={true}>
            <ProcessViewItemTitle>30%</ProcessViewItemTitle>
            <ProcessViewItemText>
              Payment 4 weeks prior shipping
            </ProcessViewItemText>
            <ProcessViewItemHr />
            <ProcessViewItemText>
              Confirming shipping.
              <pr /> Ordering transport.
            </ProcessViewItemText>
          </ProcessViewItem>
          <ProcessViewItem column={6}>
            <ProcessViewItemTitle>20%</ProcessViewItemTitle>
            <ProcessViewItemText>Final payment</ProcessViewItemText>
            <ProcessViewItemHr />
            <ProcessViewItemText>Releasing shipment</ProcessViewItemText>
          </ProcessViewItem>
        </ProcessViewItemWrapper>
        <ProcessImage src={processSectionSvg} />
      </ProcessViewWrapper>
    </Container>
  );
};

export default ProcessView;
