export const getPhoto = (image) => {
  // return `http://localhost:8000${image}`;
  // return `http://127.0.0.1:8000${image}`;
  return `https://api.enahouses.com${image}`;

  // return `https://api.ena.dizair.ee${image}`;
};
export const makePagination = (id, count) => {
  if (count <= 5) {
    return Array.from({ length: count }, (_, i) => i + 1);
  } else {
    if (parseInt(id, 10) <= 3) {
      const temp = Array.from({ length: 4 }, (_, i) => i + 1);
      temp.push("...");
      temp.push(count);
      return temp;
    } else if (parseInt(id, 10) > count - 3) {
      const temp = Array.from({ length: count }, (_, i) => i + 1).splice(
        count - 4,
        4
      );
      temp.unshift("...");
      temp.unshift(1);
      return temp;
    } else {
      const temp = Array.from({ length: count }, (_, i) => i + 1).splice(
        parseInt(id, 10) - 2,
        3
      );
      temp.push("...");
      temp.push(count);
      temp.unshift("...");
      temp.unshift(1);
      return temp;
    }
  }
};

export const baseURL = () => {
  return "https://api.enahouses.com/en/";
  // return "http://127.0.0.1:8000/";

  // const lang = window.navigator.language;
  // if (lang.includes("en")) {
  //   return (
  //     "https://api.enahouses.com/" + localStorage.getItem("language") + "/" ||
  //     ""
  //   );
  //   return "https://api.enahouses.com/en/";
  // } else if (lang.includes("ru")) {
  //   return "https://api.enahouses.com/ru/";
  // }
  // return "https://api.enahouses.com/en/";
};

export const handleDigits = (number) => {
  return number.toString().length < 2 ? `0${number}` : number;
};

export function SplitArray(setter, data, count) {
  let data_partners = [];
  let pack_size = Math.floor(data.length / count);

  for (let index = 0; index < pack_size; index++) {
    data_partners.push(data.slice(index * count, index * count + count));
  }

  if (pack_size * count < data.length)
    setter([
      ...data_partners,
      pack_size * count < data.length - 1 && [
        ...data.slice(pack_size * count, data.length),
      ],
    ]);
  else setter(data_partners);
}
