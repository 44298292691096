import axiosBase from "../axiosBase";

export const apiLoadBlock = (blockId) => () =>
  axiosBase.get("block/" + blockId);
export const apiLoadBlockWithAdvantages = () => () =>
  axiosBase.get("block/advantages");
export const apiLoadHouses = () => axiosBase.get("house/");
export const apiLoadPartners = () => axiosBase.get("partners/");
export const apiLoadLatestNews = () => axiosBase.get("news/first");
export const apiLoadQuestions = () => axiosBase.get("questions/");
