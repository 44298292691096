import { Container } from "../../common/common";
import {
  InfoContent,
  InfoImage,
  InfoImageWrapper,
  InfoItem,
  InfoItemTitle,
  InfoWrapper,
} from "./style";

import { apiLoadContacts } from "api/routes/contact";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";

const InfoSection = function () {
  const [credentials, isLoading] = useApiCall(apiLoadContacts, []);

  return (
    <InfoWrapper>
      <Container>
        <InfoContent>
          {!isLoading &&
            credentials.map((item) => (
              <InfoItem key={item.title}>
                <InfoImageWrapper>
                  <InfoImage src={getPhoto(item.icon)} />
                  <InfoImage
                    className="hover"
                    src={getPhoto(item.icon_hover)}
                  />
                </InfoImageWrapper>
                <InfoItemTitle>{item.title}</InfoItemTitle>
                {item.description}
              </InfoItem>
            ))}
        </InfoContent>
      </Container>
    </InfoWrapper>
  );
};

export default InfoSection;
