import { WideWrapper, StyledWideButton } from "../style";

const RoomSelector = (props) => {
  const { selectedRoom, setSelectedRoom, className = "mobile", rooms } = props;

  if (!rooms) {
    return <></>;
  }

  return (
    <WideWrapper className={className}>
      {rooms.map((room, index) => (
        <StyledWideButton
          onClick={() => setSelectedRoom(room)}
          key={index}
          className={selectedRoom?.id === room.id && "checked"}
        >
          {room.name}
        </StyledWideButton>
      ))}
    </WideWrapper>
  );
};

export default RoomSelector;
