import { useEffect } from "react";
import styled from "styled-components";

export const CommonContent = function ({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ minWidth: "100vw", minHeight: "100vh" }}>{children}</div>
  );
};

export const VioletButton = styled.button`
  font-family: ProximaNova;
  background: linear-gradient(180deg, #750d78 0%, #4c014e 100%);
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  line-height: 25px;
  min-width: 186px;
  height: 45px;
  padding: 0px 8px;
  border-radius: 50px;

  color: #fff;
  :hover {
    background: #750c78;
  }
  :active {
    background: #4c014e;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media screen and (max-width: 600px) {
    font-size: 12px;
    min-width: 134px;
    width: 134px;
    height: 37px;
    padding: 0px !important;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  max-width: 1180px;
  width: 100%;
  height: 100%;
`;

export const HugeContainer = styled(Container)`
  max-width: 1440px;
`;

export const ContainerMobileContent = styled.div`
  width: 100%;
  @media screen and (max-width: 1180px) {
    /* width: calc(100vw - 50px) !important; */
    padding-right: 25px !important;
    padding-left: 25px !important;
  }
`;

export const Background = styled.img`
  width: 100%;
  height: 100%;
  max-height: ${(props) =>
    props.auto ? "auto" : props.height ? props.height : "100vh"};
  object-fit: cover;
  z-index: 1;
  @media screen and (max-width: 1180px) {
    height: ${(props) => (props.mobile ? props.mobile : "600px")};
    border-radius: 0px;
  }
  @media screen and (max-width: 600px) {
    height: ${(props) => (props.mobile ? props.mobile : "302px")};
  }
  object-position: center;
`;

export const VideoBackground = styled.video`
  height: 900px;
  width: 100%;
  max-height: ${(props) =>
    props.auto ? "auto" : props.height ? props.height : "100vh"};
  object-fit: cover;
  z-index: 1;
  @media screen and (max-width: 1180px) {
    height: ${(props) => (props.mobile ? props.mobile : "600px")};
  }
  @media screen and (max-width: 600px) {
    height: ${(props) => (props.mobile ? props.mobile : "302px")};
  }
  object-position: center;
`;

export const GreenButton = styled.button`
  background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
  text-transform: uppercase;
  font-family: ProximaNova;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  width: 186px;
  height: 45px;
  color: #ffffff;
  border-radius: 50px;
  :hover {
    background: #007a4f;
  }
  :active {
    background: #025a3b;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
    width: 134px;
    height: 37px;
  }
`;

export const GreenTextButton = styled.button`
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
  border: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 10px 60px 9px;
  @media screen and (max-width: 600px) {
    padding: 0px 0px 0px;
    font-size: 12px;
    width: 134px;
    height: 37px;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Main = styled.div`
  background: rgba(0, 0, 0, 0.34);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
`;

export const MainTitle = styled.div`
  padding-bottom: 20px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 150%;
  letter-spacing: 0.45em;
  max-width: 1200px;

  @media screen and (max-width: 1180px) {
    font-size: 32px;
    line-height: 130%;
  }

  @media screen and (max-width: 600px) {
    font-size: 28px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 540px) {
    font-size: 24px;
  }
`;

export const CommonTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  text-transform: uppercase;
  color: #262626;
  @media screen and (max-width: 600px) {
    font-size: 27px;
    line-height: 120%;
  }
`;
