import styled from "styled-components";
import loadPDF from "../../assets/img/loadPDF.png";
import loadPDFhover from "../../assets/img/loadPDFhover.png";
import loadPDFpressed from "../../assets/img/loadPDFpressed.png";

export const PDFButton = styled.button`
  margin-left: 12px;
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${loadPDF});
  :hover {
    background-image: url(${loadPDFhover});
  }
  :active {
    background-image: url(${loadPDFpressed});
  }
`;
