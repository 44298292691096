import { Container } from "../common";
import HouseItem from "./HouseItem";

import {
  HouseContent,
  HouseDataWrapper,
  HouseTitle,
  HouseWrapper,
} from "./style";

import { apiLoadHouses } from "api";
import { useApiCall } from "hooks";

import { useInternacionalization } from "hooks";

const HouseSection = function ({ title = false, view = false }) {
  const [houses, isLoading] = useApiCall(apiLoadHouses, []);
  const { translate } = useInternacionalization();

  if (isLoading) {
    return <></>;
  }

  return (
    <HouseWrapper>
      <Container>
        <HouseContent>
          {title && <HouseTitle>{translate("BASIC HOUSE MODELS")}</HouseTitle>}

          <HouseDataWrapper>
            {houses.slice(0, 2).map((elt, index) => (
              <HouseItem key={index} house={elt} />
            ))}
          </HouseDataWrapper>
        </HouseContent>
      </Container>
    </HouseWrapper>
  );
};

export default HouseSection;
