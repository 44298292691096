import { create, useModal } from "@ebay/nice-modal-react";
import { useRef } from "react";
import X from "../../assets/img/icon/X_black.svg";
import OutsideClick from "../../helpers/OutsideClick";
import logo from "../../assets/img/icon/ENA_logo.svg";

import {
  Wrapper,
  Content,
  Close,
  Title,
  Conture,
  Img,
  SubTitle,
  Border,
} from "./styled";
import { GreenButton } from "pages/common/common";
import styled from "styled-components";

const LogoWrapper = styled.div`
  img {
    opacity: 0.17;
    width: 300px;
    height: 100px;
  }
`;

const SubscribeNotification = create(
  ({ id, message = "Thanks for subscribing".toUpperCase() }) => {
    const close = useRef(null);
    const { visible, hide } = useModal(id);
    OutsideClick(close, () => {
      visible && hide();
    });
    return (
      <Wrapper popUp={visible}>
        <Conture
          style={{
            maxHeight: "450px",
            maxWidth: "800px",
            width: "100%",
            borderRadius: "25px",
            background: "white",
          }}
          ref={close}
        >
          <Content
            style={{
              padding: "30px",
              borderRadius: "25px",
              background: "white",
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <Close
              onClick={() => {
                hide();
              }}
            >
              <img src={X} alt="" />
            </Close>
            <LogoWrapper>
              <img src={logo} alt="" />
            </LogoWrapper>
            <Title
              style={{
                color: "black",
                fontSize: "35px",
                fontFamily: "Open Sans",
                fontWeight: "500",
                paddingTop: "0px",
              }}
            >
              {message}
            </Title>
            <GreenButton
              style={{ width: "fit-content", fontWeight: "400" }}
              onClick={() => {
                hide();
              }}
              className="after-horizontal"
            >
              <Border className="after-vertical">Back to site</Border>
            </GreenButton>
          </Content>
        </Conture>
      </Wrapper>
    );
  }
);
export default SubscribeNotification;
