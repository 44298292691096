import { useEffect, useRef, useState } from "react";

import {
  CloseButton,
  Container,
  ContainerWrapper,
  LinearContainer,
  MainImageWrapper,
  ManageButtons,
  MaterialWrapper,
  PriceButton,
  PriceTitle,
  PriceWrapper,
  RoomManageWrapper,
  RoomWrapper,
  StringContainer,
  StyledButtonWrapper,
  StyledHr,
  Title,
  Wrapper,
} from "./style";

import ImageStack from "./ImageStack";

import PdfButton from "commponets/pdfButton/PdfButton";

import AreaSelect from "./components/AreaSelector";
import InteriorExteriorSelect from "./components/InteriorExteriorSelect";
import RoofSelect from "./components/RoofSelect";
import RoomSelector from "./components/RoomSelector";

import { apiLoadExterior, apiLoadRoomOptions } from "api/routes/calculator";
import { MODAL } from "commponets/modals/register";
import { useApiCall } from "hooks";
import MaterialSelect from "./components/MaterialSelect";

import { create, useModal } from "@ebay/nice-modal-react";
import { getPhoto } from "helpers";
import { HOUSE_LOOKS } from "variables";
import CalculatorPDF from "./components/CalculatorPDF";
import { CalculatorProvider, useCalculatorContext } from "./context";
import { ButtonsContainer } from "./style";
import HousePlan from "./HouseSchema/HouseSchema";
import { HouseSchemeProvider } from "./HouseSchema/context";

const CalculatorContent = ({ id }) => {
  const refCalc = useRef(null);
  const { visible, hide } = useModal(id);
  const [selectedRoom, setSelectedRoom] = useState();
  const [houseLook, setHouseLook] = useState(HOUSE_LOOKS.EXTERIOR);
  const [rooms, isLoading] = useApiCall(apiLoadRoomOptions);
  const [exterior, isExteriorLoading] = useApiCall(apiLoadExterior);

  const [roof, setRoof] = useState();
  const [area, setArea] = useState();
  const [roomsMaterials, setRoomMaterials] = useState({});

  const { getElement, currentCombination } = useCalculatorContext();

  const { show: showConsultation } = useModal(MODAL.FORM_MODAL);

  const showConsult = () => {
    showConsultation({ callback: hide });
  };

  const changeRoomMaterial = (categoryName) => (material) =>
    setRoomMaterials((prev) => ({ ...prev, [categoryName]: material }));

  useEffect(() => {
    if (!isLoading && !isExteriorLoading) {
      setSelectedRoom(rooms[0]);
      setRoomMaterials(
        rooms
          .concat([exterior])
          .map((item) => item.categories)
          .flat()
          .reduce((acc, item) => {
            const data = { ...acc, [item.id]: item.materials[0] };
            return data;
          }, {})
      );
    }
  }, [isLoading, isExteriorLoading]);

  const totalPrice = 0;
  const deps = [roof, area, roomsMaterials, houseLook];

  useEffect(() => {
    const hasAll = !deps.map(Boolean).includes(false);

    if (hasAll) {
      let ids;

      if (houseLook === HOUSE_LOOKS.EXTERIOR) {
        ids = exterior?.categories
          .map((category) => roomsMaterials[category?.id]?.id)
          .sort();
      } else {
        ids = selectedRoom?.categories
          .map((category) => roomsMaterials[category?.id]?.id)
          .sort();
      }

      getElement({
        roof: roof.id,
        area: houseLook === HOUSE_LOOKS.INTERIOR ? null : area?.id,
        room:
          houseLook === HOUSE_LOOKS.EXTERIOR ? exterior?.id : selectedRoom?.id,
        house_look: houseLook,
        materials: ids,
      });
    }
  }, [roof, area, roomsMaterials, houseLook, selectedRoom, exterior]);

  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "auto";
  }, [visible]);

  return (
    <ContainerWrapper popUp={visible} id="modal-constructor" ref={refCalc}>
      <Container>
        <CloseButton
          onClick={() => {
            visible && hide();
          }}
        />
        <Wrapper>
          <ManageButtons>
            <StyledButtonWrapper>
              <StringContainer>
                <Title>Choose a roof type: </Title>
                <RoofSelect currentRoof={roof} setRoof={setRoof} />
              </StringContainer>
              <StringContainer>
                <Title>Choose the size of your house: </Title>
                <AreaSelect selectedArea={area} setSelectedArea={setArea} />
              </StringContainer>
            </StyledButtonWrapper>

            <InteriorExteriorSelect
              houseLook={houseLook}
              setHouseLook={setHouseLook}
            />
          </ManageButtons>

          <StyledHr />
          <RoomWrapper>
            {houseLook !== HOUSE_LOOKS.EXTERIOR && (
              <RoomSelector
                rooms={rooms}
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                className="mobile"
              />
            )}

            <MainImageWrapper>
              <ImageStack
                modalId={id}
                src={getPhoto(currentCombination.image)}
              />
              {/* {currentCombination.image} */}
              <HouseSchemeProvider roof={roof?.id}>
                <HousePlan
                  area={area?.id}
                  room={selectedRoom?.id}
                  houseLook={houseLook}
                />
              </HouseSchemeProvider>
            </MainImageWrapper>

            <RoomManageWrapper>
              {houseLook !== HOUSE_LOOKS.EXTERIOR && (
                <RoomSelector
                  rooms={rooms}
                  selectedRoom={selectedRoom}
                  setSelectedRoom={setSelectedRoom}
                  className="desktop"
                />
              )}

              <MaterialWrapper>
                {selectedRoom &&
                  houseLook !== HOUSE_LOOKS.EXTERIOR &&
                  selectedRoom?.categories.map((category) => (
                    <MaterialSelect
                      setRoomMaterials={changeRoomMaterial(category.id)}
                      currentMaterial={roomsMaterials[category.id]}
                      category={category}
                    />
                  ))}

                {exterior &&
                  houseLook === HOUSE_LOOKS.EXTERIOR &&
                  exterior?.categories.map((category) => (
                    <MaterialSelect
                      setRoomMaterials={changeRoomMaterial(category.id)}
                      currentMaterial={roomsMaterials[category.id]}
                      category={category}
                    />
                  ))}
              </MaterialWrapper>

              <LinearContainer>
                <PriceWrapper>
                  Total Price:
                  <PriceTitle>{totalPrice} €</PriceTitle>
                </PriceWrapper>
                <div style={{ display: "flex" }}>
                  {roof && Array.isArray(rooms) && (
                    <PdfButton
                      Document={
                        <CalculatorPDF
                          rooms={rooms}
                          totalPrice={totalPrice}
                          roof={roof}
                        />
                      }
                    />
                  )}
                </div>
              </LinearContainer>

              <ButtonsContainer>
                <PriceButton bg={"green"}>get an offer</PriceButton>
                <PriceButton onClick={showConsult}>
                  BOOK A CONSULTATION
                </PriceButton>
              </ButtonsContainer>
            </RoomManageWrapper>
          </RoomWrapper>
        </Wrapper>
      </Container>
    </ContainerWrapper>
  );
};

const Calculator = create(({ id }) => {
  return (
    <CalculatorProvider>
      <CalculatorContent id={id} />
    </CalculatorProvider>
  );
});

export default Calculator;
