import styled from "styled-components";
import {
  ContainerMobileContent,
  GreenTextButton,
} from "../../../common/common";

export const ProcessWrapper = styled.div`
  background: #fff;
`;

export const ProcessVideo = styled.video`
  border-radius: 25px;
  width: 100%;
  object-fit: cover;

  background: #1f1f1f;

  @media screen and (max-width: 1180px) {
    width: 100%;
    height: auto;
    /* border-radius: 0px; */
  }
`;

export const ProcessContent = styled.div`
  display: flex;

  margin: 100px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column-reverse;
    margin: 32px 0;
  }
`;

export const ProcessDescription = styled(ContainerMobileContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #262626;
  margin-left: 32px;
  margin-right: 96px;
  width: 664px;
  @media screen and (max-width: 1180px) {
    font-size: 14px;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

export const ProcessDescriptionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  color: inherit;
  margin-bottom: 24px;
  width: 140px;

  @media screen and (max-width: 1180px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 36px;
  }
`;

export const ProcessDescriptionButton = styled(GreenTextButton)`
  margin-top: 32px;
  padding: 8px;
  border-radius: 50px;
  border: 2px solid #007a4f;
  width: 224px;

  @media screen and (max-width: 1180px) {
    margin: 12px 0;
    text-align: start;
    font-size: 14px;
    width: auto;
    height: auto;
    padding: 7px 28px;
  }
`;

export const Paragraph = styled.p`
  margin: 4px 0px;
`;

export const Description = styled.div`
  text-align: justify;
`;
