import styled from "styled-components";
import { CommonSlider } from "../../../../commponets/sliders/CommonSlider";
import { CommonTitle, ContainerMobileContent } from "../../../common/common";
export const InteriorSliderSectionWrapper = styled.div`
  width: 100%;
  background: ${(props) => props.bg};
  overflow: hidden;
`;
export const InteriorSliderSectionContentWrapper = styled.div`
  direction: ${(props) => (props.dir ? props.dir : "ltr")};
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  margin-left: 130px;
  width: 100%;

  @media screen and (max-width: 1420px) {
    margin-left: 64px;
  }

  @media screen and (max-width: 1180px) {
    margin-left: 0;
    margin: 48px 0;
  }
  @media screen and (max-width: 600px) {
    margin: 32px 0;
  }
`;

export const InteriorSliderSectionContent = styled.div`
  display: flex;
  height: 480px;
  /* padding-bottom: 126px; */
  width: 100%;
  position: relative;

  @media screen and (max-width: 1180px) {
    height: auto;
  }

  :after {
    content: "";
    display: block;
    position: absolute;
    height: 104%;
    width: 200px;
    background: ${(props) => props.bg};
    z-index: 4;
    left: ${(props) => props.dir === "rtl" && "-200px"};
    right: ${(props) => props.dir === "ltr" && "-200px"};

    @media screen and (max-width: 1180px) {
      height: 0%;
    }
  }
  .slick-arrow {
    background: rgba(38, 38, 38, 0.4);
    ::before,
    ::after {
      background: #fff;
    }
    :hover {
      background: rgba(255, 255, 255, 0.4);
      ::before,
      ::after {
        background: #262626;
      }
    }
  }
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    height: auto;
    align-items: center;
    padding-bottom: 0;
    height: auto;
  }
`;
export const InteriorSliderSectionText = styled(ContainerMobileContent)`
  direction: ltr;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #262626;
  margin: ${(props) => (props.dir === "ltr" ? "0 36px 0 0" : "0 0 0 36px")};

  @media screen and (max-width: 1300px) {
    font-size: 13px;
    margin: ${(props) => (props.dir === "ltr" ? "0 18px 0 0" : "0 0 0 18px")};
  }

  @media screen and (max-width: 1180px) {
    line-height: 140%;
    margin: 0px;
  }
`;

export const InteriorMainSlider = styled(CommonSlider)`
  background: ${(props) => props.interior && props.interior};
  border-radius: 25px;
  margin: 0;
  width: 705px;
  height: ${(props) => (props.showDots ? 488 : 380)}px;

  .slick-list {
    margin: 0;
  }
  img {
    width: 705px;
    height: ${(props) => (props.showDots ? 488 : 380)}px;
  }
  .slick-next {
    left: calc(100% - 43px);
    border-radius: 50px;
  }
  .slick-arrow {
    ::after,
    ::before {
      left: 16px;
    }
  }
  @media screen and (max-width: 1180px) {
    max-width: none;
    width: calc(100vw - 50px);
    height: auto;
    margin-top: 28px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: 250px;
  }
`;
export const InteriorSecondSlider = styled(InteriorMainSlider)`
  ::before,
  ::after {
    display: none;
  }
  width: 1400px;
  height: 114px;
  margin: 0;
  bottom: -25px;
  left: ${(props) => props.dir === "rtl" && "-81px"};
  right: ${(props) => props.dir === "ltr" && "-95px"};
  background: transparent;
  position: absolute;
  .slick-slide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  img {
    width: 186px;
    height: 114px;
    object-fit: cover;
  }
  @media screen and (max-width: 1180px) {
    position: relative;
    left: auto;
    right: auto;
    margin: 0;
    margin-top: 12px;
    bottom: -5px;
    .slick-list {
      margin: 0;
    }
    ::before,
    ::after {
      content: "";
      z-index: 3;
      display: block;
      position: absolute;
      background: ${(props) => props.dir === "#fff" && "#f0f5f3"};
      left: calc(50% - 50vw - 200px);
      width: 225px;
      height: 101%;
    }
    ::after {
      left: auto;
      right: calc(50% - 50vw - 200px);
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 5px;
    height: 72px;
    min-width: 650px;
    max-width: calc(100vw - 50px);
    bottom: 0px;
    img {
      width: 105px;
      height: 72px;
    }
    ::before,
    ::after {
      display: none;
    }
  }
`;

export const InteriorMainImage = styled.img`
  border-radius: 25px;
  width: 705px;
  height: ${(props) => (props.showDots ? 488 : 380)}px;
  object-fit: cover;
  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
`;
export const InteriorSecondImage = styled.img`
  border-radius: 25px;
  width: 183px;
  height: 114px;
`;

export const InteriorSliderSectionTitle = styled(CommonTitle)`
  margin-bottom: 24px !important;

  @media screen and (max-width: 1180px) {
    margin-bottom: 16px !important;
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Description = styled.div`
  text-align: justify;
  font-size: 14.5px;

  @media screen and (max-width: 1180px) {
    font-size: 13px;
  }
`;
