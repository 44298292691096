import styled from "styled-components";
import { ContainerMobileContent } from "../common";

export const FooterWrapper = styled.div`
  display: flex;
  background: #002c1c;
`;

export const FooterContent = styled(ContainerMobileContent)`
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin: 60px 0;
  div {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-decoration: none;
      color: inherit;
      display: block;
      margin: 12px 0;
    }
  }
  @media screen and (max-width: 800px) {
    display: grid;
    margin: 30px 0;
    grid-template-rows: 3fr;
    grid-template-columns: 50%;
    div {
      font-size: 18px;
      a {
        font-size: 14px;
      }
    }
  }
`;

export const FooterLogo = styled.div`
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;

  a {
    img {
      margin-right: 8px;
    }
  }
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    a {
      margin: 0 !important;
    }
    img {
      height: 23px;
    }
    div {
      width: 50%;
      flex-direction: row;
    }
  }
`;
export const PartnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
`;
export const PartnerImg = styled.img`
  max-width: 116px;
  padding: 15px 16px;
  :nth-child(odd) {
    padding-left: 0;
  }
  :nth-child(even) {
    padding-right: 0;
  }
  @media screen and (max-width: 800px) {
    :nth-child(even),
    :nth-child(odd) {
      padding: 15px 0;
    }
  }
`;

const TitleMargin = styled.div`
  @media screen and (max-width: 800px) {
    a {
      :first-child {
        margin-top: 15px;
      }
    }
  }
`;

export const PartnerNavigation = styled(TitleMargin)`
  grid-row: 2;
  grid-column: 1;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
  a {
    :last-child {
      margin-bottom: 24px;
    }
  }
`;

export const PartnerContacts = styled(TitleMargin)`
  grid-row: 2;
  grid-column: 2;
`;

export const PartnerPatners = styled(TitleMargin)`
  grid-row: 3;
  grid-column-start: 1;
  grid-column-end: 3;
  @media screen and (max-width: 800px) {
    img {
      margin-right: 67px;
    }
  }
  @media screen and (max-width: 520px) {
    img {
      :nth-child(even) {
        margin-right: 0;
      }
    }
  }
`;
