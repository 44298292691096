import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { useInternacionalization } from "hooks";
import equipmentBg from "../../../../assets/img/equipmentBg.png";
import { Background, MainWrapper } from "../../../common/common";
import { EquipmentButton, EquipmentMain, EquipmentTitle } from "./style";

const EquipmentSection = function () {
  const { translate } = useInternacionalization();

  const { show: showCalculator } = useModal(MODAL.CALCULATOR_MODAL);

  return (
    <MainWrapper>
      <Background
        style={{ borderRadius: 0 }}
        src={equipmentBg}
        height={"348px"}
      />
      <EquipmentMain>
        <EquipmentTitle>
          {" "}
          DISCOVER THE PERFECT COMBINATION OF LUXURITY AND AFFORDABILITY WITH
          ENA{" "}
        </EquipmentTitle>
        <EquipmentButton onClick={showCalculator}>
          {translate("Order now")}
        </EquipmentButton>
      </EquipmentMain>
    </MainWrapper>
  );
};

export default EquipmentSection;
