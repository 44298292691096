import styled from "styled-components";
import { CommonTitle, Main, VioletButton } from "../../../common/common";

export const EquipmentTitle = styled(CommonTitle)`
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  text-align: center;
  font-size: 28px;
  max-width: 60%;

  @media screen and (max-width: 1440px) {
    max-width: 70%;
  }

  @media screen and (max-width: 1180px) {
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 150%;
  }

  @media screen and (max-width: 424px) {
    font-size: 18px;
    line-height: 150%;
  }
`;

export const EquipmentMain = styled(Main)`
  background: rgba(0, 0, 0, 0.62);
  @media screen and (max-width: 1180px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const EquipmentButton = styled(VioletButton)`
  margin-top: 32px;
  @media screen and (max-width: 600px) {
    margin-top: 24px;
  }
`;
