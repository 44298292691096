import { getPhoto } from "helpers";
import {
  Info,
  Material,
  MaterialDesc,
  MaterialTitle,
  StyledRadio,
} from "../style";

const MaterialSelect = (props) => {
  const { category, setRoomMaterials, currentMaterial } = props;

  return (
    <>
      <MaterialDesc>{category.name}</MaterialDesc>

      {category.materials.map((material, index) => (
        <Material key={index}>
          <StyledRadio
            onClick={setRoomMaterials.bind(null, material)}
            className={
              "floor-radio " +
              (material.id === currentMaterial?.id && "checked")
            }
          />
          <img
            style={{ width: 65, height: 40, objectFit: "cover" }}
            alt="d"
            src={getPhoto(material.image)}
          />
          <MaterialTitle>{material.name}</MaterialTitle>
          <Info />
        </Material>
      ))}
    </>
  );
};

export default MaterialSelect;
