export const getTemplate = ({ user_email, user_phone, content, user_name }) => `
<html lang="en">
  <body>
    <div>
     user email: ${user_email} 
    </div>
    <div>
     user name: ${user_name}
    </div>
    <div>
      user phone: ${user_phone}
    </div>
    <br/>
    <div>
      message: ${content}
    </div>
  </body>
</html>
`;
