import {
  GreenButton,
  GreenTextButton,
  HugeContainer,
} from "../../../common/common";

import {
  AboutButtonWrapper,
  AboutContainer,
  AboutContent,
  AboutImg,
  AboutTitle,
  Paragraph,
  AboutWrapper,
  Description,
} from "./style.js";

import { apiLoadBlock } from "api";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { useHistory } from "react-router-dom";

const AboutSection = function () {
  const [block, isLoading] = useApiCall(apiLoadBlock(12), null);
  const history = useHistory();

  if (isLoading) {
    return <></>;
  }

  return (
    <AboutWrapper>
      <HugeContainer>
        <AboutContainer>
          <AboutImg src={getPhoto(block.image)} />
          <AboutContent>
            <AboutTitle>{block.title}</AboutTitle>
            <Description>
              {block?.descriptions?.length
                ? block.descriptions?.map((item, index) => (
                    <Paragraph key={index}>{item.content}</Paragraph>
                  ))
                : block.description}
            </Description>

            <AboutButtonWrapper>
              <GreenButton onClick={() => history.push("/about-us")}>
                {block.button_text}
              </GreenButton>
              <GreenTextButton
                style={{
                  border: "2px solid #007A4F",
                  borderRadius: "50px",
                }}
                onClick={() => history.push("/contacts")}
              >
                {block.button_text_2}
              </GreenTextButton>
            </AboutButtonWrapper>
          </AboutContent>
        </AboutContainer>
      </HugeContainer>
    </AboutWrapper>
  );
};

export default AboutSection;
