import { HugeContainer } from "../../../common/common";
import {
  Paragraph,
  ProcessContent,
  ProcessDescription,
  ProcessDescriptionButton,
  ProcessDescriptionTitle,
  ProcessWrapper,
  Description,
} from "./style.js";

import HouseConstruction from "../../../../assets/img/HouseConstruction.png";

import { apiLoadBlock } from "api";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { useHistory } from "react-router-dom";

import { VideoPlayer } from "commponets/video";

const ProcessSection = function () {
  const [block, isLoading] = useApiCall(apiLoadBlock(6), null);
  const history = useHistory();
  if (isLoading) {
    return <></>;
  }

  return (
    <ProcessWrapper>
      <HugeContainer>
        <ProcessContent>
          <div style={{ width: "100%", padding: "0px 12px" }}>
            <VideoPlayer
              styles={{ height: "100%" }}
              preview={HouseConstruction}
              src={getPhoto(block.video)}
            />
          </div>
          <ProcessDescription>
            <ProcessDescriptionTitle>{block.title}</ProcessDescriptionTitle>
            <Description>
              {block?.descriptions?.length
                ? block.descriptions.map((item, index) => (
                    <Paragraph key={index}>{item.content}</Paragraph>
                  ))
                : block.description}
            </Description>

            <ProcessDescriptionButton onClick={() => history.push("/contacts")}>
              {block.button_text}
            </ProcessDescriptionButton>
          </ProcessDescription>
        </ProcessContent>
      </HugeContainer>
    </ProcessWrapper>
  );
};

export default ProcessSection;
