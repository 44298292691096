import {
  InvestementContentText,
  InvestementContentTitle,
  InvestementContentDescription,
  InvestementContentTextWrapper,
  InvestementContentTitleMonth,
} from "./style";

const InvensmentCaption = ({ value, totalPrice }) => {
  const months = Math.floor( totalPrice / value);

  return (
    <InvestementContentDescription>
      <InvestementContentTextWrapper>
        <InvestementContentText>
          Montly income
          <InvestementContentTitle>{value}</InvestementContentTitle>
        </InvestementContentText>
        Estimated residiad value in 24 months -30%
      </InvestementContentTextWrapper>
      <InvestementContentText>
        Expected return
        <InvestementContentTitle>
          {months < 1 ? 1 : months}
        </InvestementContentTitle>
        <InvestementContentTitleMonth>
          {" "}
          {months <= 1 ? "month" : "months"}
        </InvestementContentTitleMonth>
      </InvestementContentText>
    </InvestementContentDescription>
  );
};

export default InvensmentCaption;
