import styled from "styled-components";
import { CommonTitle, ContainerMobileContent } from "../../../common/common";

export const TechnologiesItemWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* margin: ${(props) =>
    props.dir === "ltr" ? "0 0 0 96px" : "0 96px 0 0"}; */
  direction: ${(props) => (props.dir ? props.dir : "ltr")};
  margin-bottom: 56px;
  padding: 0px 16px;
  :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 1480px) {
    margin: ${(props) => (props.dir === "ltr" ? "0 0 0 48px" : "0 48px 0 0")};
    margin-bottom: 48px;
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 32px 0;
  }
`;

export const TechnologiesItemImage = styled.img`
  border-radius: 25px;
  margin: ${(props) => (props.dir === "ltr" ? "0 0 0 30px" : "0 30px 0 0")};
  height: 100%;
  min-height: 400px;
  max-height: 500px;
  min-width: 755px;
  max-width: 755px;
  object-fit: cover;
  @media screen and (max-width: 1180px) {
    width: 100%;
    height: auto;
    max-height: 70vw;
    min-width: auto;
    border-radius: 0px;
    min-height: 250px;
    max-width: 100vw;

    margin: 0;
    /* border-radius: 0px; */
  }
  @media screen and (max-width: 600px) {
    width: calc(100vw);
    transform: ${(props) =>
      props.dir === "ltr" ? "translateX(-16px)" : "translateX(16px)"};
  }
`;

export const TechnologiesItemContent = styled(ContainerMobileContent)`
  display: flex;
  flex-direction: column;
  direction: ltr;
  width: 100%;
  max-width: 577px;
  @media screen and (max-width: 1180px) {
    margin-bottom: 28px;
    max-width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding: 0px 8px !important;
  }
`;

export const TechnologiesItemDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 150%;
  color: #262626;
  @media screen and (max-width: 1180px) {
    :last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 140%;
  }
`;

export const TechnologiesWrapper = styled.div`
  margin: 100px 0;
  width: 100%;
  @media screen and (max-width: 1180px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 0px;
  }
`;

export const TechnoligiesTitle = styled(CommonTitle)`
  margin-bottom: 24px;

  @media screen and (max-width: 1180px) {
    margin-bottom: 16px !important;
  }
`;

export const Description = styled.div`
  text-align: justify;
  font-size: 12px;
`;
