import {
  TechnoligiesTitle,
  TechnologiesItemContent,
  TechnologiesItemDescription,
  TechnologiesItemImage,
  TechnologiesItemWrapper,
  Description,
} from "./style";

import { getPhoto } from "helpers";

const TechologiesItem = function ({ techology, dir = "ltr" }) {
  return (
    <>
      {techology && (
        <TechnologiesItemWrapper dir={dir}>
          <TechnologiesItemContent>
            <TechnoligiesTitle>{techology.title}</TechnoligiesTitle>
            <Description>
              {techology.description.map((item, index) => (
                <TechnologiesItemDescription key={item.id}>
                  - {item.content}
                </TechnologiesItemDescription>
              ))}
            </Description>
          </TechnologiesItemContent>
          <TechnologiesItemImage src={getPhoto(techology.image)} dir={dir} />
        </TechnologiesItemWrapper>
      )}
    </>
  );
};

export default TechologiesItem;
