import styled from "styled-components";
import { ContainerMobileContent, GreenButton } from "../common";
export const HouseWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
`;

export const HouseTitle = styled.div`
  display: flex;
  align-items: left;
  /* font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px; */
  text-transform: uppercase;
  color: #262626;
  margin-bottom: 60px;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.45em;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 27px;
    margin-bottom: 30px;
  }
`;
export const HouseContent = styled(ContainerMobileContent)`
  margin-bottom: 25px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;
  @media screen and (max-width: 1180px) {
    margin: 32px 0;
  }
`;
export const HouseDataWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const HouseItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  /* padding-bottom: 47px; */
  margin-top: 30px;
  max-width: 580px;
  margin-bottom: 25px;
  border-radius: 25px;
  :first-child {
    margin-top: 0;
  }
  :nth-child(2) {
    margin-top: 0;
  }
  @media screen and (max-width: 600px) {
    margin-top: 16px;
    padding-bottom: 30px;
    :nth-child(2) {
      margin-top: 16px;
    }
  }
`;
export const HouseImg = styled.img`
  margin-right: 33px;
  height: 48px;
  object-fit: cover;
  width: auto;
  @media screen and (max-width: 600px) {
    margin-right: 9px;
    height: 46px;
  }
`;

export const HouseItemTitle = styled.div`
  font-style: normal;
  text-align: left;
  font-weight: 600;
  font-size: 27px;
  line-height: 33px;
  color: #262626;
  margin: 30px 0 20px 0;
  text-transform: uppercase;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    margin: 20px 0 16px 0;
  }
`;

export const HouseItemDetail = styled.div`
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: left;
  color: #262626;
  padding-top: 20px;
  padding-bottom: 24px;
  width: 100%;
  ::before {
    top: 0;
    left: 0;
    content: "";
    display: block;
    position: absolute;
    background: #4c014e;
    height: 2px;
    width: 100%;
  }
  @media screen and (max-width: 1180px) {
    font-size: 18px;
    padding-top: 16px;
  }
`;

export const HouseItemText = styled.div`
  color: ${(props) => (props.color ? props.color : "inherit")};
  font-weight: 600;
  margin-top: 15px;
  @media screen and (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const HouseItemImg = styled.img`
  border-radius: 25px;
  object-fit: cover;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  max-height: 360px;
`;

export const HouseItemButton = styled(GreenButton)`
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 9px;
  text-align: center;
  @media screen and (max-width: 600px) {
    width: fit-content;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;
