import { useState } from "react";

const useForm = (initialState, defaultValue = "") => {
  const [form, setForm] = useState(initialState);

  const getInputProps = (key) => {
    return {
      value: form[key] || defaultValue,
      onChange: ({ target }) => {
        setForm((prev) => ({ ...prev, [key]: target.value }));
      },
      name: key,
    };
  };

  const cleanForm = () => {
    setForm(initialState);
  };

  const isValid = !Object.values(form)
    .map((item) => !!item)
    .includes(false);

  return { form, setForm, getInputProps, cleanForm, isValid };
};

export default useForm;
