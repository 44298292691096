import styled from "styled-components";
import {
  VioletButton,
  Container,
  ContainerMobileContent,
} from "../../pages/common/common";
import { QuestionTitle } from "../homePage/sections/Question/style";

export const QuestionFormWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
`;
export const QuestionContent = styled(ContainerMobileContent)`
  display: flex;
  margin: 100px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 60px 0;
  }
`;
export const QuestionForm = styled.div`
  display: flex;
  textarea,
  input {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    border: 0;
    border-bottom: 1px solid #262626;
    outline: 0;
    resize: none;
    width: 372px;
    padding: 7px 16px;
    margin-bottom: 16px;
    position: relative;
    text-align: start;
    ::placeholder {
      color: #262626;
    }
    :last-child {
      margin: 0;
    }
  }
  textarea {
    height: 100%;
    background: #f0f5f3;
    ::placeholder {
      position: absolute;
      bottom: 4px;
    }
  }
  input {
    background: #f0f5f3;
    height: 45px;
  }
  button {
    width: 372px;
    padding: 10px 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    button {
      text-align: center;
    }
    input,
    button {
      height: 37px;
    }
    input,
    textarea,
    button {
      margin: 0;
      margin-top: 8px;
      width: 325px;
      :last-child {
        margin-top: 8px;
      }
    }
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 28px;
  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;
export const QuestionContentTitle = styled(QuestionTitle)`
  margin: 0;
  @media screen and (max-width: 1180px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 27px;
    display: flex;
    justify-content: center;
  }
`;

export const CustomInput = styled.input`
  font-family: ProximaNova;

  &:hover {
    &::placeholder {
      color: #6c6c6c;
    }
    border-bottom-color: #750c78;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
    border-bottom-color: #750c78;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const CustomTextArea = styled.textarea`
  font-family: ProximaNova;

  &:hover {
    &::placeholder {
      color: #6c6c6c;
    }
    border-bottom-color: #750c78;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
    border-bottom-color: #750c78;
  }
`;
