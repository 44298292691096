import { useRef } from "react";
import styled from "styled-components";
import X from "../../assets/img/icon/X_black.svg";
import OutsideClick from "../../helpers/OutsideClick";
import { Wrapper, Close, Border } from "./styled";
import logo from "../../assets/img/icon/ENA_logo.svg";
import { QuestionTitle } from "pages/homePage/sections/Question/style";

import { GreenButton, Container } from "../../pages/common/common";
import {
  QuestionFormWrapper,
  QuestionContent,
  QuestionForm,
  InputWrapper,
  CustomInput,
} from "../../pages/abousUsPage/style";

import { useForm } from "hooks";
import { useSnackbar } from "notistack";
import { QuestionService } from "service";
import React from "react";

import { useInternacionalization } from "hooks";
import { create, useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
const Conture = styled.div`
  width: 94%;
  max-width: 1180px;
  padding: 14px;
  margin-left: -16px;
  background: transparent;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;
const Content = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 52px 56px 52px 56px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 64px 16px 24px 16px;
  }
`;

const SubscribeText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: black;
  text-align: center;
  @media screen and (max-width: 768px) {
    height: 100px;
    font-size: 14px;
    line-height: 18px;
  }
`;

const LogoWrapper = styled.div`
  align-items: center;
  text-align: center;
  font-size: 14px;
  line-height: 27px;
  padding: 0 0 0 8px;
`;
const LogoText = styled(QuestionTitle)`
  min-width: fit-content;
  margin: 0;
  @media screen and (max-width: 1180px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 27px;
    display: flex;
    justify-content: center;
  }
`;

const Subscribe = create(({ id }) => {
  const { show: showNotification, hide: hideNotification } = useModal(
    MODAL.SUBSCRIBE_NOTIFICATION_MODAL
  );
  const close = useRef();
  const snackBar = useSnackbar();
  const { visible, hide } = useModal(id);
  OutsideClick(close, () => {
    visible && hide();
  });

  const { form, cleanForm } = useForm({
    user_name: "",
    user_email: "",
    user_phone: "",
    content: "",
  });

  const { translate } = useInternacionalization();

  const sendQuestion = async () => {
    await QuestionService.createQuestion(form);

    snackBar.enqueueSnackbar(translate("Message sent"), {
      variant: "success",
    });

    cleanForm();
  };
  const handleClick = () => {
    sendQuestion();
    showNotification();
    hide();
  };

  return (
    <Wrapper popUp={visible}>
      <Conture
        style={{ width: "900px", height: "600px", marginTop: "50px" }}
        ref={close}
      >
        <Content style={{ padding: "70px", borderRadius: "25px" }}>
          <Close
            onClick={() => {
              hide();
            }}
            popUp={true}
          >
            <img src={X} alt="" />
          </Close>
          <LogoWrapper
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "30px",
            }}
          >
            <img src={logo} alt="" />
            <LogoText>{"NEWS"}</LogoText>
          </LogoWrapper>
          <QuestionFormWrapper
            style={{ width: "100%", height: "100%", borderRadius: "25px" }}
            bg={"#fff"}
          >
            <Container
              style={{
                padding: "50px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <QuestionContent
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                  gap: "50px",
                }}
              >
                <SubscribeText style={{ alignSelf: "center" }}>
                  {
                    "Please leave us Your contact information. Your e-mail address will not be shared with third parties. You can always unsubscribe from the newsletter."
                  }
                </SubscribeText>
                <QuestionForm
                  style={{
                    alignSelf: "center",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <InputWrapper>
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your full name *"
                    />
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your email *"
                      type="email"
                    />
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your phone number"
                      type="number"
                      maxLength={13}
                      pattern="[0-9]*"
                    />
                  </InputWrapper>
                  <GreenButton onClick={handleClick}>SUBSCRIBE</GreenButton>
                </QuestionForm>
              </QuestionContent>
            </Container>
          </QuestionFormWrapper>
        </Content>
      </Conture>
    </Wrapper>
  );
});
export default Subscribe;
