/* eslint-disable no-unused-vars */
import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";
import interiorBg from "../../assets/interiorBG.png";

import InteriorSliderSection from "./section/InteriorSlider/InteriorSliderSection";

import { useApiCall } from "hooks";
import { apiLoadInteriorBlock } from "api/routes/interior";

import { useInternacionalization } from "hooks";

const InteriorPage = function () {
  const [blocks, isBlocksLoading] = useApiCall(apiLoadInteriorBlock, []);

  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={interiorBg} />
        <Main>
          <MainTitle>{translate("Interiors")}</MainTitle>
        </Main>
      </MainWrapper>

      {!isBlocksLoading && (
        <>
          {blocks.map((item, index) => (
            <InteriorSliderSection
              key={item.id}
              interior={item}
              dir={index % 2 === 0 ? "rtl" : undefined}
              bg={index % 2 === 0 ? "#F0F5F3" : undefined}
            />
          ))}
        </>
      )}
    </CommonContent>
  );
};

export default InteriorPage;
