import styled from "styled-components";
import {
  ContainerMobileContent,
  GreenButton,
  VioletButton,
} from "../../../common/common";
export const SpecificationWrapper = styled.div`
  background: #f0f5f3;
  padding: 100px 0;
  overflow: hidden;

  @media screen and (max-width: 1180px) {
    padding: 48px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 24px 0;
  }
`;

export const SpecificationContent = styled(ContainerMobileContent)`
  display: flex;
  margin-left: 100px;
  flex-direction: row;
  justify-content: space-between;

  /* gap: 300px; */

  @media screen and (max-width: 1180px) {
    margin: 0;
    flex-direction: column;
    width: 100%;
  }
`;

export const SpecificationContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin: 0 30px;
  align-self: flex-start;
  @media screen and (max-width: 1180px) {
    margin: 0 auto;
  }
`;

export const SpecificationImage = styled.img`
  height: 515px;
  border-radius: 25px;
  align-self: flex-end;

  @media screen and (max-width: 1180px) {
    /* height: auto; */
    /* width: calc(100vw - 50px); */
    display: none;
  }
`;

export const SpecificationImageWrapper = styled.div`
  width: 100%;
  max-width: 75%;
  overflow: hidden;

  @media screen and (max-width: 1180px) {
    /* height: auto; */
    /* width: calc(100vw - 50px); */
    display: none;
  }
`;

export const SpecificationContentItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  margin: 42px 0 32px 0;
  @media screen and (max-width: 600px) {
    margin: 30px 0 8px 0;
  }
  @media screen and (max-width: 470px) {
    grid-template-columns: none;
  }
`;

export const SpecificationItem = styled.div`
  display: flex;
  margin: 8px 0;
  align-items: center;
`;

export const SpecificationItemImage = styled.img`
  /* margin: 0; */
  width: 40px;
  height: 40px;
  @media screen and (max-width: 600px) {
    width: 34px;
    height: 34px;
  }
`;

export const SpecificationItemContent = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  font-family: "Proxima Nova", sans-serif;
  color: #262626;
  width: 100% div {
    font-weight: normal;
    line-height: 150%;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-left: 8px;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  padding: 18px;
  border-radius: 50px;
  width: 100%;
  div {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(180deg, #750d78 0%, #4c014e 100%);
  }
  @media screen and (max-width: 600px) {
    padding: 12px 16px;
    font-size: 18px;
  }
`;

export const ButtonWrapContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1180px) {
    justify-content: center;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin: 8px 0 8px 0;
  }
`;

export const CalcButton = styled(VioletButton)`
  @media screen and (max-width: 1180px) {
    width: auto;
    margin-bottom: 8px;
    padding: 12px 16px;
  }
  @media screen and (max-width: 600px) {
    padding: 0;
  }
`;

export const BookButton = styled(GreenButton)`
  width: 260px;
  margin-left: 16px;
  @media screen and (max-width: 1180px) {
    width: auto;
    padding: 12px 16px !important;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 8px;
    margin: 0;
    padding: 0;
  }
`;

export const PDFButton = styled.div`
  background: #c9c9c9;
  border-radius: 45px;
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  transition: all 0.3s ease;

  @media screen and (max-width: 1180px) {
    margin: 12px auto 0px auto;
  }

  &:hover {
    background-color: #c72a2a;
  }

  &:active {
    transform: scale(0.95);
  }
`;
