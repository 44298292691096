import { CheckWrapper, StyledButton } from "../style";
import { useEffect } from "react";

import { useApiCall } from "hooks";
import { apiLoadAreaOptions } from "api/routes/calculator";

const AreaSelect = (props) => {
  const { setSelectedArea, selectedArea } = props;
  const [areas, isLoading] = useApiCall(apiLoadAreaOptions);

  useEffect(() => {
    if (!selectedArea && areas) {
      setSelectedArea(areas[0]);
    }
  }, [areas, isLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <DumbAreaSelect
      selectedArea={selectedArea}
      setSelectedArea={setSelectedArea}
      data={areas}
    />
  );
};

export default AreaSelect;

const DumbAreaSelect = (props) => {
  const { data, selectedArea, setSelectedArea } = props;

  return (
    <CheckWrapper style={{ margin: "0px" }}>
      {data.map((area_prop) => (
        <StyledButton
          key={area_prop.id}
          className={selectedArea.id === area_prop.id && "checked"}
          onClick={setSelectedArea.bind(null, area_prop)}
        >
          {area_prop.area} m²
        </StyledButton>
      ))}
    </CheckWrapper>
  );
};
