import styled from "styled-components";
import { ContainerMobileContent } from "../../common/common";

export const InfoWrapper = styled.div`
  background: #fff;
`;

export const InfoContent = styled(ContainerMobileContent)`
  display: flex;
  margin: 100px 0;
  width: 100%;
  height: 160px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1180px) {
    max-width: 550px;
    flex-direction: column;
    height: auto;
  }
  @media screen and (max-width: 1180px) {
    margin: 48px 0;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
  margin-right: 30px;
  border-radius: 25px;

  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  :last-child {
    margin: 0;
  }
  .hover {
    opacity: 0;
  }
  color: #262626;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  :hover {
    background: linear-gradient(180deg, #750d78 0%, #4c014e 100%);
    color: #fff;
    img {
      opacity: 0;
    }
    .hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1180px) {
    margin-right: 0;
    height: 141px;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    img {
      width: 27px;
      height: 27px;
    }
  }
`;

export const InfoItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
export const InfoImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;
export const InfoImage = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  object-fit: contain;
  left: 0;
  opacity: 1;
`;
