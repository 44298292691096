import { Background, HugeContainer } from "../common";

import {
  ConstructorButton,
  ConstructorContent,
  ConstructorContentWrapper,
  ConstructorTitle,
  Description,
  ConstructorWrapper,
} from "./style.js";

import { useModal } from "@ebay/nice-modal-react";
import { apiLoadBlock } from "api";
import { MODAL } from "commponets/modals/register";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { firstBlockId } from "variables";

const ConstructorSection = function ({ style }) {
  const [block, isLoading] = useApiCall(apiLoadBlock(firstBlockId + 3), null);
  const { show } = useModal(MODAL.CALCULATOR_MODAL);

  if (isLoading) {
    return <></>;
  }

  return (
    <ConstructorWrapper>
      <HugeContainer style={{ margin: "0" }}>
        <ConstructorContentWrapper style={style}>
          <ConstructorContent>
            <ConstructorTitle>{block.title}</ConstructorTitle>
            <Description>{block.description}</Description>
            <ConstructorButton onClick={show}>
              {block.button_text}
            </ConstructorButton>
          </ConstructorContent>
          <Background src={getPhoto(block.image)} height={"600px"} />
        </ConstructorContentWrapper>
      </HugeContainer>
    </ConstructorWrapper>
  );
};

export default ConstructorSection;
