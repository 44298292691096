import { useRef } from "react";
import styled from "styled-components";
import X from "../../assets/img/icon/X_black.svg";
import OutsideClick from "../../helpers/OutsideClick";
import { Wrapper, Close } from "./styled";

import { VioletButton, Container } from "../../pages/common/common";
import {
  QuestionFormWrapper,
  QuestionContentTitle,
  CustomInput,
  CustomTextArea,
} from "../../pages/abousUsPage/style";

import { useForm } from "hooks";
import { QuestionService, EmailService } from "service";
import React from "react";

import { useInternacionalization } from "hooks";
import { create, useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { QuestionFormNew, QuestionContainer, InputWrapperNew } from "./styled";

const Conture = styled.div`
  width: 94%;
  max-width: 1180px;
  padding: 14px;
  background: transparent;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;

const Content = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 52px 56px 52px 56px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 64px 16px 24px 16px;
  }
`;

const FormPopUp = create(({ id, callback }) => {
  const { show: showNotification } = useModal(MODAL.NOTIFICATION_MODAL);
  const close = useRef();
  const { visible, hide } = useModal(id);
  OutsideClick(close, () => {
    visible && hide();
  });

  const { form, cleanForm, getInputProps, isValid } = useForm({
    user_name: "",
    user_email: "",
    user_phone: "",
    content: "",
  });

  const { translate } = useInternacionalization();

  const sendQuestion = async () => {
    await QuestionService.createQuestion(form);
    await EmailService.sendQuestionEmail(form);

    cleanForm();
  };
  const handleClick = () => {
    sendQuestion();
    showNotification({ callback });
    hide();
  };

  return (
    <Wrapper popUp={visible}>
      <Conture ref={close}>
        <Content style={{ padding: "0px", borderRadius: "25px" }}>
          <Close
            onClick={() => {
              hide();
            }}
            popUp={true}
          >
            <img src={X} alt="" />
          </Close>
          <QuestionFormWrapper
            style={{ width: "100%", height: "100%", borderRadius: "25px" }}
            bg={"#fff"}
          >
            <Container>
              <QuestionContainer>
                <QuestionContentTitle
                  style={{ alignSelf: "center", padding: 0 }}
                >
                  {"BOOK A CONSULTANT"}
                </QuestionContentTitle>
                <QuestionFormNew>
                  <InputWrapperNew>
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your full name *"
                      {...getInputProps("user_name")}
                    />
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your email *"
                      type="email"
                      {...getInputProps("user_email")}
                    />
                    <CustomInput
                      style={{ borderRadius: "25px" }}
                      placeholder="Enter your phone number *"
                      type="number"
                      maxLength={13}
                      pattern="[0-9]*"
                      {...getInputProps("user_phone")}
                    />
                  </InputWrapperNew>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <CustomTextArea
                      style={{ borderRadius: "25px" }}
                      placeholder="Message"
                      {...getInputProps("content")}
                    />
                    <VioletButton disabled={!isValid} onClick={handleClick}>
                      {translate("Send a question")}
                    </VioletButton>
                  </div>
                </QuestionFormNew>
              </QuestionContainer>
            </Container>
          </QuestionFormWrapper>
        </Content>
      </Conture>
    </Wrapper>
  );
});
export default FormPopUp;
