import { useEffect, useState } from "react";
import Slick from "react-slick";
import styled from "styled-components";
const CommonSliderStyle = styled(Slick)`
  backdrop-filter: blur(8px);
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.62);
  width: 710px;
  height: 504px;
  display: flex;
  font-size: 32px;
  .slick-arrow {
    width: 43px;
    height: 43px;
    display: flex;
    position: absolute;
    margin: 0;
    margin-right: 0;
    top: 50%;
    @media screen and (min-width: 1180px) {
      :hover {
        background: rgba(255, 255, 255, 0.18);
        ::after,
        ::before {
          background: #fff;
        }
      }
    }
  }
  .slick-dots {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-bottom: 12px;
    li {
      max-width: 50px;
      width: 100%;
      height: auto;
      margin: 0 5px;
      button {
        content: "";
        display: block;
        max-width: 50px;
        width: 100%;
        height: 2px;
        margin: 0;
        padding: 0;
        background: rgba(100, 100, 100, 1);
      }
    }
    .slick-active {
      button {
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .slick-list {
    margin: 28px 46px;
    display: flex;
    width: 100%;
    div {
      width: 100%;
      height: auto;
    }
  }
  .slick-track {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      div {
        height: auto;
      }
    }
  }
  .slick-prev {
    left: 0;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev,
  .slick-next {
    ::after,
    ::before {
      position: absolute;
      content: "";
      display: block;
      width: 10px;
      height: 2px;
      background: rgb(128, 128, 128);
      left: 16px;
      opacity: 1;
    }
    ::after {
      top: 23px;
      transform: rotateZ(45deg);
    }
    ::before {
      top: 17px;
      transform: rotateZ(-45deg);
    }
  }
  .slick-next {
    ::after {
      transform: rotateZ(-45deg);
    }
    ::before {
      transform: rotateZ(45deg);
    }
  }
  @media screen and (max-width: 1180px) {
    max-width: calc(100vw - 50px);
  }
  @media screen and (max-width: 600px) {
    height: 420px;
    max-width: calc(100vw - 50px);
  }
`;
export const CommonSlider = function ({
  children,
  settings,
  mobileSettings = null,
  sliderRef = null,
  className,
  asNavFor = null,
  mobileWidth = 600,
  partner = false,
  afterChange,
}) {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    function handler() {
      if (window.innerWidth <= mobileWidth) setMobile(true);
      else if (window.innerWidth > mobileWidth) setMobile(false);
    }
    window.addEventListener("resize", () => handler());
    handler();
  }, []);

  return (
    <CommonSliderStyle
      afterChange={afterChange}
      asNavFor={asNavFor && asNavFor}
      className={className}
      ref={sliderRef}
      {...(mobile && mobileSettings ? mobileSettings : settings)}
      partner={partner}
    >
      {children}
    </CommonSliderStyle>
  );
};
