import axiosBase from "api/axiosBase";

export default class QuestioService {
  static async createQuestion(new_quetion) {
    const { data } = await axiosBase({
      url: "questions/",
      data: new_quetion,
      method: "POST",
    });

    return data;
  }
}
