/* eslint-disable no-unused-vars */
import {
  NewsSectionPaginate,
  NewsSectionContentWrapper,
  NewsSectionContent,
} from "./style";
import ourNewsBg from "../../assets/img/ourNewsBg.png";
import {
  Background,
  CommonContent,
  Container,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";
import NewsItem from "../common/News/NewsItem";
import { apiLoadNews } from "api/routes/news";

import { useApiCall } from "hooks";
import { useEffect, useState } from "react";

import { useInternacionalization } from "hooks";

const OurNewsSection = function () {
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const { translate } = useInternacionalization();

  const [data, isLoading] = useApiCall(
    apiLoadNews(currentPage),
    [],
    [currentPage]
  );

  useEffect(() => {
    if (data) {
      setNumberOfPages(data.num_pages);
    }
  }, [data]);

  const { news } = data;

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={ourNewsBg} />
        <Main>
          <MainTitle>{translate("OUR NEWS")}</MainTitle>
        </Main>
      </MainWrapper>
      <Container style={{ maxWidth: 1250 }}>
        <NewsSectionContentWrapper>
          {!isLoading && (
            <>
              <NewsSectionContent>
                {news.map((item, index) => (
                  <NewsItem key={index} news={item} />
                ))}
              </NewsSectionContent>
              {numberOfPages > 1 && (
                <NewsSectionPaginate
                  pageCount={numberOfPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={0}
                  onPageChange={(page) => {
                    setCurrentPage(page.selected + 1);
                  }}
                  previousLabel={""}
                  nextLabel={""}
                  currentPage={currentPage}
                />
              )}
            </>
          )}
        </NewsSectionContentWrapper>
      </Container>
    </CommonContent>
  );
};

export default OurNewsSection;
