import styled from "styled-components";
import { Background, ContainerMobileContent } from "../../../common/common";
export const ChooseWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const ChooseBackground = styled(Background)`
  position: absolute;
  height: 100%;
  z-index: 1;
  height: 100% !important;
`;

export const ChooseContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2;
`;

export const ChooseContainer = styled(ContainerMobileContent)`
  width: 100%;
  display: flex;
  padding: 48px 0;
  align-items: center;
  @media screen and (max-width: 1180px) {
    width: 760px;
    flex-direction: column;
    padding: 32px 0;
  }
`;

export const ChooseContent = styled.div`
  display: flex;
  z-index: 5;
  max-height: 600px;
  width: 100%;
  @media screen and (max-width: 1180px) {
    max-height: none;
  }
`;

export const ChooseDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #ffffff;
  max-width: 399px;
  text-align: left;
  margin-right: 71px;
  height: 100%;
  font-family: "Proxima Nova", sans-serif;
  @media screen and (max-width: 1180px) {
    max-width: none;
    margin: 0 0 28px 0;
    font-size: 14px;
  }
`;
export const ChooseTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  text-transform: uppercase;
  color: inherit;
  margin: 20px 0;

  @media screen and (max-width: 1180px) {
    margin-bottom: 12px;
    font-size: 32px;
  }
`;
export const ChooseAdvantageWrapper = styled.div`
  border-radius: 25px;
  display: flex;
  flex-wrap: wrap;
  width: 710px;
  height: 496px;
  background: rgba(0, 0, 0, 0.61);
  backdrop-filter: blur(4px);
  padding: 48px 82px;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    padding: 32px 0;
  }

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;
export const ChooseAdvantage = styled.div`
  background: none;
  display: flex;
  font-family: "Proxima Nova", sans-serif;
  flex-direction: column;
  align-items: center;
  margin: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  position: relative;
  color: #ffffff;
  max-width: 234px;
  align-items: flex-end;
  text-align: end;
  /* ::before {
    top: 8px;
    transform: translateX(12px);
    width: 48px;
    height: 48px;
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    background: rgba(107, 1, 109, 1);
    z-index: 3;
  } */
  @media screen and (max-width: 1180px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    max-width: 224px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    margin: 18px auto;
    align-items: center;
    text-align: center;
    max-width: 340px;
    max-width: 100%;
    padding: 0px 18px;
  }
`;

export const ChooseAdvantageTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 10px;
  font-family: "Proxima Nova", sans-serif;
  text-align: left;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
export const ChooseSvgWrapper = styled.div`
  position: relative;
`;
export const ChooseSvg = styled.img`
  z-index: 3;
  position: relative;
  margin-bottom: 20px;
`;
export const Paragraph = styled.p`
  margin: 6px 0px;
  text-align: justify;
`;

export const AdvantageDescription = styled.div`
  white-space: normal;
  max-width: 340px;
`;
