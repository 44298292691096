import { Slider } from "@material-ui/core";
import styled from "styled-components";
import rangeMessageSvg from "../../../../assets/img/icon/rangeMessageSvg.svg";
import { CommonTitle } from "../../../common/common";
export const InputSlider = styled(Slider)`
  border-radius: 50px;
  border-radius: 50px;
  max-width: 570px;
  margin: 0 20px 0 28px;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  .MuiSlider {
    &-rail {
      border-radius: 50px;

      border-radius: 50px;

      height: 10px;
      background: #c8e1d7;
      border-radius: 10px;
    }
    &-track {
      border-radius: 50px;

      border-radius: 50px;

      height: 10px;
      background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
    }
  }
  .MuiSlider-thumb {
    border-radius: 50px;
    border-radius: 50px;
    background: #262626;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    top: 50%;
    &.Mui-focusVisible {
      box-shadow: none;
    }
    :hover {
      box-shadow: none;
    }
    :after {
      display: none;
    }
  }
  .MuiSlider-valueLabel {
    left: -11px;
    top: -28px;
    span {
      background: transparent;
      background-image: url(${rangeMessageSvg});
      height: 35px;
      width: 42px;
      transform: rotate(0deg);
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 27px;
        /* or 193% */

        text-align: center;
        letter-spacing: 0.05em;

        color: #262626;
      }
    }
  }
  @media screen and (max-width: 600px) {
    margin: 0;
    &.MuiSlider-root {
      padding-top: 20px;
      padding-bottom: 8px;
    }
    .MuiSlider {
      &-rail {
        height: 6px;
        background: #c8e1d7;
      }
      &-track {
        height: 6px;
        background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
      }
    }
    .MuiSlider-thumb {
      border-radius: 50px;
      border-radius: 50px;
      width: 10px;
      height: 10px;
      top: calc(50% + 8px);
    }
    .MuiSlider-valueLabel {
      left: -10px;
      top: -20px;
      span {
        height: 26px;
        width: 31px;
        background-size: cover;
        span {
          font-size: 11px;
        }
      }
    }
  }
`;

export const InvestementItem = styled.div`
  width: 100%;
  display: flex;
  margin: 19px 0;
  padding-right: 30px;
  justify-content: flex-end;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #262626;
  align-items: flex-end;
  @media screen and (max-width: 600px) {
    align-items: start;
    flex-direction: column;
    margin: 0;
    padding: 0 20px;
    margin-bottom: 16px;
    :last-child {
      margin: 0;
    }
  }
`;
export const InputSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr;
    grid-template-rows: 2fr;
  }
`;
export const InvestmentItemTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 30px;
  width: 100%;
  max-width: 250px;
  transform: translateY(16px);
  @media screen and (max-width: 600px) {
    align-items: start;
    font-size: 16px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }
`;
export const InvestmentItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: #262626;
  @media screen and (max-width: 600px) {
    font-size: 23px;
  }
`;

export const InvestmentValueRange = styled.div`
  white-space: nowrap;
  white-space: nowrap;
  width: auto;
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  white-space: nowrap;
  color: #262626;
  grid-row: 2;
  flex-shrink: 0;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    :last-child {
      justify-content: flex-end;
    }
  }
`;
export const InvestementWrapper = styled.div`
  width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 72px 0px 100px 0;
  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
    margin: 48px 0px 60px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 32px 0px 32px 0px;
  }
`;

export const InvestementCalculator = styled.div`
  border-radius: 25px;
  border-radius: 25px;
  width: 100%;
  background: #f0f5f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 8px 0 24px 0px;
  border-radius: 24px;
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    padding: 32px 0;
  }
`;

export const InvestementText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  margin-top: 12px;
  color: #262626;
`;
export const InvestementContentText = styled.div`
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 150%;
  color: #262626;
  @media screen and (max-width: 600px) {
    font-size: 23px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const InvestementContentTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 56px;
  color: #262626;
  margin: 0 12px 0 24px;
  @media screen and (max-width: 600px) {
    font-size: 27px;
    margin-right: 8px;
  }
`;

export const InvestementContentDescription = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
  @media screen and (max-width: 600px) {
    justify-content: start;
    flex-direction: column;
    margin-top: 0;
  }
`;

export const InsestementTitle = styled(CommonTitle)`
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const InvestementContentTitleMonth = styled.div`
  font-size: 27px;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;

export const InvestementContentTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;
