import { useEffect } from "react";
import { Link } from "react-router-dom";
import { StringContainer } from "../../../commponets/calculator/style";
import Logo from "../../../assets/img/Logo.svg";
import {
  FooterWrapper,
  FooterContent,
  FooterLogo,
  PartnerWrapper,
  PartnerImg,
  PartnerNavigation,
  PartnerContacts,
  PartnerPatners,
} from "./style";
import { FooterApi, PartnersApi } from "../../homePage/api";
import { useState } from "react";
import { apiLoadMenu } from "../../../api";
import { Container } from "../common";
import { apiLoadPartners } from "api";
import { useApiCall } from "hooks";
import { getPhoto } from "helpers";
const FooterSection = function () {
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const load = async () => {
      const { data } = await apiLoadMenu();
      setMenu(data);
    };
    load();
  }, []);
  function splitData(data, size) {
    return data.slice(0, size);
  }
  const [partners] = useApiCall(apiLoadPartners, []);
  return (
    <FooterWrapper>
      <Container>
        <FooterContent>
          <FooterLogo>
            <img src={Logo} />
            <StringContainer>
              {FooterApi.map((link, index) => (
                <a href={link.url} key={index}>
                  <img src={link.image} />
                </a>
              ))}
            </StringContainer>
          </FooterLogo>
          <PartnerNavigation>
            Top navigation
            {menu &&
              menu.map((link) => (
                <Link key={link.route} to={link.route}>
                  {link.site_display_name}
                </Link>
              ))}
          </PartnerNavigation>
          <PartnerContacts>
            Contacts
            <a href="/contacts">Phone</a>
            <a href="/contacts">Email</a>
            <a href="/contacts">Adress</a>
          </PartnerContacts>
          <PartnerPatners>
            Our partners
            <PartnerWrapper>
              {[...splitData(partners, 8)].map((item, index) => (
                <PartnerImg key={index} src={getPhoto(item.image)} />
              ))}
            </PartnerWrapper>
          </PartnerPatners>
        </FooterContent>
      </Container>
    </FooterWrapper>
  );
};

export default FooterSection;
