import axiosBase from "api/axiosBase";
import { getTemplate } from "./helpers/getTemplate";

export default class EmailService {
  static async sendQuestionEmail(data) {
    await axiosBase({
      url: "email/",
      method: "POST",
      data: {
        subject: "CONTACT US form",
        message: getTemplate(data),
        email: process.env.REACT_APP_EMAIL_SUPPORT_ADDRESS,
      },
    });
  }
}
