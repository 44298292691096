import styled from "styled-components";
import Paginate from "react-paginate";
import { ContainerMobileContent } from "../common/common";
export const NewsSectionPaginate = styled(Paginate)`
  color: #000;
  margin: 0;
  padding: 0;
  width: 275px;
  list-style: none;
  display: flex;
  justify-content: space-between;

  .previous,
  .next {
    position: relative;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    a {
      width: 11px;
      height: 19px;
      ::after,
      ::before {
        position: absolute;
        content: "";
        display: block;
        width: 14px;
        height: 2px;
        background: #262626;
        opacity: 1;
      }
      ::after {
        top: calc(50% + 4.5px);
        transform: rotateZ(45deg);
      }
      ::before {
        top: calc(50% - 4.5px);
        transform: rotateZ(-45deg);
      }
    }
  }
  .next {
    margin: 0;
    margin-left: auto;
    a {
      transform: rotateZ(180deg);
    }
  }

  li {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
    }
  }
  .selected {
    background: #f0f5f3;
  }
  @media screen and (max-width: 600px) {
    width: 265px;
    li {
      width: 31px;
      height: 31px;
      a {
        font-size: 14px;
        width: 31px;
        height: 31px;
      }
    }
    .previous,
    .next {
      width: 31px;
      height: 31px;
      a {
        ::after {
          width: 11.5px;
          top: calc(50% + 3.5px);
          transform: rotateZ(45deg);
        }
        ::before {
          width: 11.5px;
          top: calc(50% - 3.5px);
          transform: rotateZ(-45deg);
        }
      }
    }
  }
`;

export const NewsSectionContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0;

  @media screen and (max-width: 1180px) {
    margin: 48px 0px;
  }
`;

export const NewsSectionContent = styled(ContainerMobileContent)`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1180px) {
    gap: 32px;
  }
`;
