import { HugeContainer } from "../../../common/common";
import { TechnologiesWrapper } from "./style";
import TechnologiesItem from "./TechnologiesItem";

import { apiLoadTechnologiesBlock } from "api/routes/technologies";
import { useApiCall } from "hooks";

const TechnologiesSection = function () {
  const [blocks, isLoading] = useApiCall(apiLoadTechnologiesBlock, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <HugeContainer>
      <TechnologiesWrapper>
        {blocks.map((item, index) => (
          <TechnologiesItem
            key={index}
            techology={item}
            dir={index % 2 === 0 ? "ltr" : "rtl"}
          />
        ))}
      </TechnologiesWrapper>
    </HugeContainer>
  );
};

export default TechnologiesSection;
