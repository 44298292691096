import {
  HouseItemButton,
  HouseItemDetail,
  HouseItemImg,
  HouseItemText,
  HouseItemTitle,
  HouseItemWrapper,
} from "./style";

import { apiLoadProduct } from "api/routes/product";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  padding: 11%;
  padding-bottom: 8%;
  padding-top: 0px;
  width: 100%;
`;

const HouseItem = function ({ house }) {
  const history = useHistory();
  const { roofType } = useParams();
  const [product, isLoading] = useApiCall(apiLoadProduct(house.id, null));
  const isRoof = () => {
    if (roofType) {
      return true;
    } else {
      return false;
    }
  };

  const houseArea = product?.specification?.stats[2].description;
  const innerDim = product?.specification?.stats[1].description;
  const insideArea = product?.specification?.stats[3].description;

  return (
    <HouseItemWrapper>
      <HouseItemImg src={getPhoto(house.image)} />
      <ContentWrapper>
        <HouseItemTitle>{house.title}</HouseItemTitle>
        {isRoof() && !isLoading ? (
          <HouseItemDetail>
            House area: {houseArea}
            <HouseItemText style={{ fontWeight: "400" }}>
              Inner dimensions: {innerDim}
            </HouseItemText>
            <HouseItemText style={{ fontWeight: "400" }}>
              Inside area: {insideArea}
            </HouseItemText>
          </HouseItemDetail>
        ) : (
          <HouseItemDetail>
            House area: {house.area}
            <HouseItemText color={"#025A3B"}>
              Price from: {house.price} €
            </HouseItemText>
            <HouseItemText color={"#4C014E"}>
              Delivery {house.delivery}
            </HouseItemText>
            <HouseItemText color={"#4C014E"}>
              Rooms: {house.rooms_number}
            </HouseItemText>
          </HouseItemDetail>
        )}
        {isRoof() ? (
          <HouseItemButton onClick={() => history.push(`/house/${house.id}`)}>
            {"View Details"}
          </HouseItemButton>
        ) : (
          <HouseItemButton
            onClick={() => history.push("/products/" + house.roof_type)}
          >
            {"View more models"}
          </HouseItemButton>
        )}
      </ContentWrapper>
    </HouseItemWrapper>
  );
};

export default HouseItem;
