import RoofType from "pages/common/House/RoofType";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AboutUsPage from "./pages/abousUsPage/AboutUsPage";
import FooterSection from "./pages/common/Footer/FooterSection";
import Header from "./pages/common/Header/Header";
import ContactsPage from "./pages/contactsPage/ContactsPage";
import Page404 from "./pages/error/Page404";
import HomePage from "./pages/homePage/HomePage";
import InteriorPage from "./pages/interiorPage/InteriorPage";
import NewsDetailPage from "./pages/newsDetailPage/NewsDetailPage";
import OurNewsSection from "./pages/ourNewsPage/OurNewsSection";
import ProcessPage from "./pages/processPage/ProcessPage";
import ProductPage from "./pages/productPage/ProductPage";
import ProductsPage from "./pages/productsPage/ProductsPage";
import RoofPage from "./pages/roofPage/RoofPage";
import TechnologiesPage from "./pages/technologiesPage/TechnologiesPage";
const BaseRouter = () => {
  return (
    <BrowserRouter>
      <Header />
      {/* <Calculator /> */}
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/house/:id" component={ProductPage} />
        <Route exact path="/products" component={ProductsPage} />
        <Route exact path="/interior" component={InteriorPage} />
        <Route exact path="/contacts" component={ContactsPage} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/technologies" component={TechnologiesPage} />
        <Route exact path="/process" component={ProcessPage} />
        <Route exact path="/news" component={OurNewsSection} />
        <Route exact path="/news/:id" component={NewsDetailPage} />
        <Route exact path="/roof" component={RoofPage} />
        <Route exact path="/products/:roofType" component={RoofType} />
        <Route exact path="*" component={Page404} />
      </Switch>
      <FooterSection />
    </BrowserRouter>
  );
};

export default BaseRouter;
