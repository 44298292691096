import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";
import technologiesBg from "../../assets/1_1-min.png";
import TechnologiesSection from "./section/Technologies/TechnologiesSection";

import { useInternacionalization } from "hooks";

const TechnologiesPage = function () {
  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={technologiesBg} />
        <Main>
          <MainTitle>{translate("TECHNOLOGIES")}</MainTitle>
        </Main>
      </MainWrapper>
      <TechnologiesSection />
    </CommonContent>
  );
};

export default TechnologiesPage;
