import { memo, useEffect, useRef, useState } from "react";
import { ImageWrapper, MainImage } from "./style";

import { useModal } from "@ebay/nice-modal-react";

const ImageStack = (props) => {
  const { src, modalId } = props;
  const [images, setImages] = useState([]);

  const { visible } = useModal(modalId);
  const topLevelImage = useRef(0);

  const changeTopLevelImage = (index) => {
    if (topLevelImage.current < index) {
      topLevelImage.current = index;
    }
  };

  useEffect(() => {
    setImages((prev) => [...prev, src]);
  }, [src]);

  useEffect(() => {
    changeTopLevelImage(images.length - 1);
  }, [images]);

  useEffect(() => {
    if (!visible) {
      setImages([src]);
    }
  }, [visible]);

  return (
    <ImageWrapper>
      {images.map((image, index) => (
        <ShowImage
          topLevelImage={topLevelImage.current}
          index={index}
          src={image}
          key={index}
        />
      ))}
    </ImageWrapper>
  );
};

export default ImageStack;

const ShowImage = memo(({ src, index, topLevelImage }) => {
  const [isLoaded, setLoaded] = useState(!(topLevelImage + 1 === index));

  return (
    <MainImage
      style={{ position: "absolute", zIndex: index }}
      src={src}
      isLoaded={isLoaded}
      key={index}
      opacity={isLoaded ? 1 : 0}
      onLoad={() => {
        if (!isLoaded) setLoaded(true);
      }}
    />
  );
});
