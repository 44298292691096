import { useState } from "react";
import {
  InteriorMainImage,
  InteriorMainSlider,
  InteriorSecondImage,
  InteriorSecondSlider,
  InteriorSliderSectionContent,
  InteriorSliderSectionContentWrapper,
  InteriorSliderSectionText,
  InteriorSliderSectionTitle,
  InteriorSliderSectionWrapper,
  Description,
} from "./style";

import { getPhoto } from "helpers";
import { Paragraph } from "pages/common/Description/style";
import { HugeContainer } from "../../../common/common";

const MainSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  infinite: false,
};
const SecondSettings = {
  slidesToShow: 7,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  infinite: true,
  swipeToSlide: true,
  focusOnSelect: true,
};

const SecondMobileSettings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  infinite: true,
  swipeToSlide: true,
  focusOnSelect: true,
  centerMode: true,
  touchThreshold: 8,
};
const InteriorSliderSection = function ({
  interior,
  dir = "ltr",
  bg = "#fff",
  showDots = false,
}) {
  const [mainRef, setMainRef] = useState();
  const [secondRef, setSecondRef] = useState();

  return (
    <InteriorSliderSectionWrapper bg={bg}>
      <HugeContainer>
        <InteriorSliderSectionContentWrapper dir={dir}>
          <InteriorSliderSectionContent dir={dir} bg={bg}>
            <InteriorSliderSectionText dir={dir}>
              <InteriorSliderSectionTitle>
                {interior.title}
              </InteriorSliderSectionTitle>
              <Description>
                {interior.descriptions?.length
                  ? interior.descriptions.map((item) => (
                      <Paragraph key={item.id}>{item.content}</Paragraph>
                    ))
                  : interior.description}
              </Description>
            </InteriorSliderSectionText>
            <InteriorMainSlider
              interior={bg}
              showDots={showDots}
              dir={dir}
              settings={{ ...MainSettings, dots: showDots }}
              sliderRef={(ref) => setMainRef(ref)}
              asNavFor={secondRef}
            >
              {interior.images.map((item, index) => (
                <InteriorMainImage
                  showDots={showDots}
                  key={index}
                  src={getPhoto(item.image)}
                />
              ))}
            </InteriorMainSlider>

            {!showDots && (
              <InteriorSecondSlider
                dir={dir}
                settings={SecondSettings}
                mobileSettings={SecondMobileSettings}
                sliderRef={(ref) => setSecondRef(ref)}
                asNavFor={mainRef}
              >
                {interior.images.map((item, index) => (
                  <div key={index}>
                    <InteriorSecondImage
                      style={{ padding: "10px" }}
                      src={getPhoto(item.image)}
                    />
                  </div>
                ))}
              </InteriorSecondSlider>
            )}
          </InteriorSliderSectionContent>
        </InteriorSliderSectionContentWrapper>
      </HugeContainer>
    </InteriorSliderSectionWrapper>
  );
};

export default InteriorSliderSection;
