import { getPhoto } from "helpers";
import { useRef } from "react";
import { HugeContainer } from "../../../common/common";
import {
  HomePlanSectionContent,
  HomePlanSectionWrapper,
  HomePlanSliderWrapper,
  HomePlanTitle,
  HomePlatItemImage,
  HomePlatItemImageWrapper,
  HomePlatItemsWrapper,
  Paragraph,
  PlanSlider,
  TextContent,
  Row,
  MainSliderImage,
} from "./style";

import { useScrollContainer } from "hooks";
import { useState } from "react";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
};
const mobileSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  rows: 1,
};
const HomePlanSection = function ({ plan }) {
  const ref = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { itemRef, scrollContainer } = useScrollContainer(currentSlide);

  if (!plan) {
    return <></>;
  }

  return (
    <HomePlanSectionWrapper>
      <HugeContainer>
        <HomePlanSectionContent>
          <Row>
            {plan.images && (
              <HomePlanSliderWrapper>
                <PlanSlider
                  afterChange={(currentIndex) => setCurrentSlide(currentIndex)}
                  className="plan-slider"
                  settings={settings}
                  mobileSettings={mobileSettings}
                  sliderRef={ref}
                >
                  {plan.images.map((image) => (
                    <MainSliderImage
                      alt={image.id.toString()}
                      key={image.id}
                      src={getPhoto(image.image)}
                    />
                  ))}
                </PlanSlider>
              </HomePlanSliderWrapper>
            )}
            <TextContent>
              <HomePlanTitle>{plan.title}</HomePlanTitle>
              {plan.descriptions?.length
                ? plan.descriptions.map((desc) => (
                    <Paragraph key={desc.id}>{desc.content}</Paragraph>
                  ))
                : plan.description}
            </TextContent>
          </Row>

          <HomePlatItemsWrapper ref={scrollContainer}>
            {plan.images.map((image, index) => (
              <HomePlatItemImageWrapper
                ref={currentSlide === index ? itemRef : null}
                key={index}
                isActive={currentSlide === index}
              >
                <HomePlatItemImage
                  src={getPhoto(image.image)}
                  onClick={() => ref.current.slickGoTo(index, false)}
                />
              </HomePlatItemImageWrapper>
            ))}
          </HomePlatItemsWrapper>
        </HomePlanSectionContent>
      </HugeContainer>
    </HomePlanSectionWrapper>
  );
};

export default HomePlanSection;
