import MainSection from "./sections/Main/MainSection";
import NewsSection from "./sections/News/NewsSection";
import AboutSection from "./sections/About/AboutSection";
import ChooseSection from "./sections/Choose/ChooseSection";
import ProcessSection from "./sections/Process/ProcessSection";
import PartnersSection from "./sections/Patners/PartnersSection";
import QuestionSection from "./sections/Question/QuestionSection";
import { CommonContent } from "../common/common";
import QuestionFormSection from "../common/QuestionForm";
import HouseSection from "../common/House/HouseSection";
import ConstructorSection from "../common/Constructor/ConstructorSection";

import { useInternacionalization } from "hooks";

const HomePage = function () {
  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainSection />
      <NewsSection />
      <AboutSection />
      <ChooseSection />
      <HouseSection title={true} />
      <ConstructorSection />
      <ProcessSection />
      <PartnersSection />
      <QuestionSection />
      <QuestionFormSection bg={"#f0f5f3"}>
        {translate("STILL HAVE QUESTIONS?")}
      </QuestionFormSection>
    </CommonContent>
  );
};

export default HomePage;
