import { Link } from "react-router-dom";
import styled from "styled-components";

export const Navigations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #474747;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
export const Nav = styled(Link)`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 18px;
  }
`;
export const NavWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  /* background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 40%
  ); */
  background: rgba(0, 0, 0, 0.4);
`;
export const Title = styled.div`
  width: 45%;
  position: absolute;
  text-align: center;
  font-family: Alata;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 150%;
  z-index: 1;
  color: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 1024px) {
    font-size: 38px;
    width: 70%;
  }
  @media screen and (max-width: 600px) {
    font-size: 27px;
    width: 90%;
  }
`;
export const ImgWrap = styled.div`
  position: relative;
  width: 100%;
  @media screen and (max-width: 1024px) {
    margin: 59px 0 0 0;
  }
`;
export const Img = styled.img`
  width: 100%;
  max-height: 100vh;
  object-fit: cover;
  display: block;
  @media screen and (max-width: 1024px) {
    min-height: 40vh;
  }
`;
export const Social = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 40px;
  display: inline-block;
  background: rgba(15, 15, 15, 0.37);
  border-radius: 100px;
  padding: 0 10px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export const SocialMobile = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: flex-end;
  }
`;
export const Icon = styled.a`
  margin: 15px 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  & i:hover {
    color: #000;
  }
`;
export const IconMobile = styled.a`
  margin-right: 8px;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  & i:hover {
    color: #000;
  }
`;
