import styled from "styled-components";
export const NewsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const NewsContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  z-index: 2;
  height: 100%;
  width: 100%;
`;

export const NewsTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  text-transform: uppercase;
  color: inherit;
  @media screen and (max-width: 960px) {
    padding-right: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 30px;
  }
`;

export const NewsHr = styled.hr`
  width: 1px;
  background: #fff;
  border: 0;
  height: 45px;
  @media screen and (max-width: 600px) {
    margin: 0 15px;
  }
`;

export const NewsButtonWrapper = styled.div`
  display: block;

  @media screen and (max-width: 1180px) {
    display: flex;
  }
`;

export const NewsButton = styled.button`
  border-radius: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  text-transform: uppercase;
  color: inherit;
  background: transparent;
  border: 1px solid #fff;
  padding: 10px 44px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    padding: 10px 16px;
  }
`;

export const NewsBackground = styled.img`
  top: 0;
  width: 100%;
  min-height: 65px;
  max-height: 98px;
  height: auto;
  z-index: 1;
  object-fit: cover;
`;

export const NewsDescription = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  @media screen and (max-width: 960px) {
    display: none;
  }
`;
