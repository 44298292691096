import styled from "styled-components";
import sunSvg from "../../../../assets/img/icon/Sun.svg";
import moonSvg from "../../../../assets/img/icon/moonSvg.svg";
import { CommonTitle, ContainerMobileContent } from "../../../common/common";
export const LightWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

export const LightContent = styled.div`
  display: flex;
  margin: 100px 0 100px 130px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  color: #262626;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 48px 0;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 32px 0;
  }
`;

export const LightBackground = styled.div`
  border-radius: 25px;
  display: flex;
  position: relative;
  height: 567px;
  min-width: 906px;
  @media screen and (max-width: 1180px) {
    min-width: auto;
    width: 100%;
    border-radius: 0px;
    height: auto;
  }
`;

export const LightSwitcher = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  background: rgba(248, 248, 248, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 100px;
  width: 250px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 17px 25px;
  left: 35px;
  bottom: 30px;
  button {
    right: 0;
    position: absolute;
    background: rgba(255, 255, 255, 0.48);
    border-radius: 50px;
    margin: 8px 10px;
    width: 68px;
    height: 36px;
    &.night {
      ::before {
        opacity: 0;
      }
      ::after {
        opacity: 1;
      }
    }
    ::before,
    ::after {
      top: 8px;
      width: 20px;
      height: 20px;
      content: "";
      position: absolute;
      opacity: ${(props) => (props.night ? "0" : "1")};
      transition: all 0.3s linear;
    }
    ::before {
      left: 8px;
      background-image: url(${sunSvg});
    }
    ::after {
      right: 8px;
      opacity: ${(props) => (props.night ? "1" : "0")};
      background-image: url(${moonSvg});
    }
  }
  @media screen and (max-width: 600px) {
    width: 167px;
    height: 29px;
    font-size: 12px;
    padding: 0px 14px;
    left: 25px;
    bottom: 8px;
    button {
      width: 36px;
      height: 21px;
      right: -4px;
      ::before,
      ::after {
        left: 5px;
        top: 5px;
        width: 11px;
        height: 11px;
        background-size: cover;
      }
      ::after {
        left: auto;
        right: 5px;
      }
    }
  }
`;

export const LightImage = styled.img`
  top: 0;
  bottom: 0;
  border-radius: 25px;
  position: absolute;
  opacity: ${(props) => (props.night ? "0" : "1")};
  transition: opacity 0.7s linear;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 1180px) {
    border-radius: 0px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
`;

export const NightImage = styled(LightImage)`
  position: relative;
`;

export const LightText = styled(ContainerMobileContent)`
  margin-right: 24px;
  margin-bottom: 24px;
  min-width: 372px;

  @media screen and (max-width: 600px) {
    margin-right: 0;
    font-size: 13px;
    line-height: 140%;
    min-width: 100%;
  }
`;

export const LightTitle = styled(CommonTitle)`
  margin-bottom: 32px;

  @media screen and (max-width: 1180px) {
    margin-bottom: 20px;
  }
`;

export const Paragraph = styled.p`
  margin: 6px 0px;
`;

export const Description = styled.div`
  text-align: justify;
`;
