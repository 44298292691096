import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";
import NewsSliderSection from "./section/newsSlider/NewsSliderSection";

import { apiLoadNewsDetail } from "api/routes/newsDetail";
import { useApiCall } from "hooks";

import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";

import { getPhoto } from "helpers";

import { Container } from "../common/common";
import { Description, Paragraph, SectionWrapper } from "./style";

const NewsDetailPage = function () {
  const [news, isLoading] = useApiCall(apiLoadNewsDetail, []);
  const { id } = useParams();

  const relatedNews = useMemo(
    () => news.filter((nw) => nw.id !== Number(id)),
    [news, id]
  );

  const currentNews = useMemo(
    () => news.find((nw) => nw.id === Number(id)),
    [news, id]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={getPhoto(currentNews?.image)} />
        <Main>
          <MainTitle>{currentNews?.title}</MainTitle>
        </Main>
      </MainWrapper>

      <SectionWrapper>
        <Container>
          <Description>
            {currentNews?.descriptions.map((item) => (
              <Paragraph key={item.id}>{item.content}</Paragraph>
            ))}
          </Description>
        </Container>
      </SectionWrapper>

      {!isLoading && <NewsSliderSection news={relatedNews} />}
    </CommonContent>
  );
};

export default NewsDetailPage;
