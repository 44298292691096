import styled from "styled-components";

export const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0px;
  right: 130px;
  width: 100px;
  height: 100px;
  z-index: 5;
  align-items: flex-start;

  @media (max-width: 1180px) {
    height: 30px;
    bottom: 10px;
    right: 10px;
  }
`;

export const YtLogo = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  img:hover {
    opacity: 0.7;
  }
`;

export const SoundLogo = styled.div`
  align-self: flex-start;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  img:hover {
    opacity: 0.7;
  }
`;

export const Ping = styled.div`
  background: transparent;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
  animation: ${(props) => (props.isMuted ? "pulse-white" : "none")} 1.5s
    infinite;
  position: absolute;
  height: 13px;
  top: 4px;
  left: 2px;
  width: 13px;
  z-index: 7;
  border-radius: 150px;

  @keyframes pulse-white {
    0% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 19px rgba(255, 255, 255, 0);
    }

    100% {
      transform: scale(0.75);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
`;

export const SoundIconWrapper = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
`;
