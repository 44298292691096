import styled from "styled-components";
import { CommonSlider } from "../../../../commponets/sliders/CommonSlider";
import { CommonTitle, ContainerMobileContent } from "../../../common/common";

export const HomePlanSectionWrapper = styled.div`
  /* padding: 0px 20px; */
`;
export const PlanSlider = styled(CommonSlider)`
  max-width: 50vw;
  width: 100%;
  margin: 0;
  padding: 22px 43px;
  position: relative;
  max-height: 600px;
  border-radius: 25px;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  height: auto;

  @media screen and (max-width: 1180px) {
    max-width: 100%;
  }

  .slick-list {
    margin: 0;
  }
  .slick-arrow {
    ::before,
    ::after {
      background: #262626;
    }
    :hover {
      background: rgba(38, 38, 38, 0.4);
      ::before,
      ::after {
        background: #fff;
      }
    }
  }
  .slick-dots {
    li {
      button {
        background: #a0a0a0;
      }
    }
    .slick-active {
      button {
        background: #262626;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
    .slick-dots {
      width: 100%;
      left: 0;
      li {
        max-width: 30px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .slick-dots {
      li {
        max-width: 30px;
      }
    }
  }
`;
export const HomePlanSliderWrapper = styled.div`
  margin-right: 24px;
  width: 100%;
  @media screen and (max-width: 1180px) {
    margin: 0;
    width: calc(100vw - 50px);
  }
`;
export const HomePlanSectionContent = styled(ContainerMobileContent)`
  display: flex;
  margin: 100px;
  margin-left: 0;
  font-style: normal;
  font-weight: normal;
  flex-direction: column;
  font-size: 15px;
  line-height: 150%;
  color: #262626;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    margin: 60px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 32px 0px;
  }
`;
export const HomePlatItemsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  padding-left: 12px;
  padding-bottom: 12px;

  overflow-y: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (max-width: 1180px) {
    max-width: calc(100vw - 50px);
    width: 100%;
  }
`;
export const HomePlatItemImageWrapper = styled.div`
  border-radius: 20px;
  width: auto;
  height: auto;
  margin: 16px 17px 0px 0px;
  min-width: calc(33.3% - 16px);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isActive ? 1 : 0.7)};

  :last-child {
    margin: 16px 17px 0 0;
  }
  @media screen and (max-width: 1180px) {
    margin-right: 16px;
    margin-top: 10px;
    max-width: none;
    width: calc(33.3% - 16px);
    :last-child {
      margin-top: 10px;
      margin-right: 0;
    }
  }
  @media screen and (max-width: 600px) {
    margin-right: 8px;
    margin-top: 5px;
    :last-child {
      margin-top: 5px;
    }
  }
`;

export const MainSliderImage = styled.img`
  max-height: 500px;
  object-fit: contain;
  height: 100%;
`;

export const HomePlatItemImage = styled.img`
  cursor: pointer;
  border-radius: 25px;
  object-fit: contain;
  height: auto;
  max-height: 230px;
  width: 100%;
`;

export const HomePlanTitle = styled(CommonTitle)`
  margin-bottom: 24px;
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const Paragraph = styled.p`
  margin: 6px 0px;
  text-align: justify;
`;

export const TextContent = styled.div`
  position: relative;
  padding: 20px;

  @media screen and (max-width: 1180px) {
    padding: 0px 0px 20px 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 1180px) {
    flex-direction: column-reverse;
  }
`;
