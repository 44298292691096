import styled from "styled-components";
import { CommonSlider } from "../../../../commponets/sliders/CommonSlider";
import { CommonTitle } from "../../../common/common";
export const NewsSlider = styled(CommonSlider)`
  width: 1180px;
  height: 100%;
  background: transparent;
  .slick-slide.slick-active.slick-current button {
    background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
  }
  .slick-arrow {
    top: 165.1px;
    ::before,
    ::after {
      background: #fff;
    }
    :hover {
      background: rgba(255, 255, 255, 0.4);
      ::before,
      ::after {
        background: #262626;
      }
    }
  }
  .slick-list {
    margin: 0;
    margin-bottom: 30px;
  }
  .slick-dots {
    bottom: 0;
    padding: 0;
    li {
      button {
        background: #a0a0a0;
      }
      &.slick-active {
        button {
          background: #262626;
        }
      }
    }
  }
  .slick-slide {
    display: flex;
    flex-direction: row;
    :nth-child(odd) {
      justify-content: flex-start;
    }
    :nth-child(even) {
      justify-content: flex-end;
    }
    div {
      justify-content: start;
      align-items: flex-start;
      width: auto;
    }
    .news-item {
      align-items: flex-start;
      height: 100%;
      justify-content: space-between;
    }
    .news-text {
      width: 100%;
      justify-content: space-between;
    }
    .news-date {
      display: flex;
      justify-content: flex-end;
    }
    .news-desc {
      display: flex;
      width: 100%;
    }
  }
  @media screen and (max-width: 1180px) {
    max-width: none;
    width: calc(100vw);
    .slick-slide {
      margin-left: 10px;
      .news-item {
        width: calc(100vw - 50px);
      }
      .news-image {
        width: calc(100vw - 50px);
      }
    }
  }
`;

export const NewsSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 72px 0px 100px 0;

  @media screen and (max-width: 768px) {
    margin: 32px 0px 64px 0px;
  }
`;

export const NewsSliderSectionTitle = styled(CommonTitle)`
  margin-bottom: 42px;
  margin-left: 12px;
  @media screen and (max-width: 1180px) {
    margin-left: 20px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1180px) {
    flex-direction: column;
  }
`;
