// import {
//   Document,
//   Text,
//   Image,
//   View,
//   Page,
//   StyleSheet,
// } from "@react-pdf/renderer";
import { getPhoto } from "helpers";

const CalculatorPDF = (props) => {
  const { rooms, roof, totalPrice } = props;

  return (
    <></>
    // <Document>
    //   <Page>
    //     <View style={styles.container}>
    //       {rooms.map((room) => (
    //         <View>
    //           <Text>{room.name}</Text>
    //           <Image src={getPhoto(room.image)} />
    //         </View>
    //       ))}
    //     </View>
    //   </Page>
    // </Document>
  );
};

export default CalculatorPDF;

// const styles = StyleSheet.create({
//   img: {
//     marginBottom: "10px",
//   },

//   container: {
//     padding: "30px",
//     textAlign: "center",
//     display: "flex",
//     color: "#1f2937",
//     justifyContent: "center",
//     flexDirection: "column",
//   },

//   heading: { marginBottom: 5, fontSize: 22 },
// });
