import styled from "styled-components";
import { ContainerMobileContent, VioletButton } from "../common";
export const ConstructorWrapper = styled.div`
  background: rgba(240, 245, 243, 1);
  display: flex;
  justify-content: center;
`;
export const ConstructorContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  overflow: hidden;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const ConstructorContent = styled(ContainerMobileContent)`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
  color: #262626;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-right: 35px;
  margin-left: 130px;
  margin-top: 50px;
  min-width: 370px;
  @media screen and (max-width: 1180px) {
    margin: 32px 0 28px 0;
    padding: 0;
    min-width: 100%;
  }
`;

export const Description = styled.div`
  /* word-break: break-all; */
  text-align: justify;
`;

export const ConstructorTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  color: #262626;
  margin-bottom: 20px;

  @media screen and (max-width: 1180px) {
    margin-bottom: 16px;
  }

  @media screen and (max-width: 600px) {
    font-size: 27px;
  }
`;

export const ConstructorButton = styled(VioletButton)`
  margin-top: 48px;
  width: 226px;
  border-radius: 50px;
  font-size: 16px;

  @media screen and (max-width: 1180px) {
    margin-top: 24px;
    width: 164px;
    font-size: 14px;
  }
`;
