import styled from "styled-components";
import { CommonSlider } from "../../../commponets/sliders/CommonSlider";
import { CommonTitle } from "../../common/common";

export const ProcessSlider = styled(CommonSlider)`
  background: inherit;
  width: 1180px;
  height: 100%;
  .slick-list {
    margin: 0;
  }
  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
  }
`;

export const SecondProcessSlider = styled(ProcessSlider)`
  width: 1194px;
  .slick-list {
    margin: 0;
  }
  @media screen and (max-width: 1180px) {
    width: calc((100vw - 38px) / 3 * 5 + 100px);
    max-width: none;
    bottom: 0px;
    height: auto;
    margin-left: 12px;
  }
  @media screen and (max-width: 600px) {
    width: calc((100vw - 45px) / 3 * 5 + 100px);
    height: 72px;
    margin-left: 5px;
  }
`;
export const ProcessImage = styled.div`
  margin-top: 12px;
  cursor: pointer;
  img {
    width: 183px;
    object-fit: cover;
    object-position: left;
    height: 114px;
  }
  :last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1180px) {
    img {
      width: calc(100% - 12px);
      object-fit: cover;
      object-position: center;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      margin-top: 5px;
      width: calc(100% - 5px);
      height: 72px;
    }
  }
`;

export const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f0f5f3;
  padding: 100px 0;
`;

export const ProcessContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  position: relative;
  @media screen and (max-width: 1180px) {
    align-items: center;
  }
`;

export const ProcessTitle = styled(CommonTitle)`
  margin-bottom: 50px;
  @media screen and (max-width: 1180px) {
    margin: 0 25px 50px 25px;
  }
  @media screen and (max-width: 600px) {
    width: calc(100vw - 50px);
    margin-bottom: 30px;
    text-align: start;
  }
`;
