import processBg from "../../assets/img/processBg1.png";
import ProcessView from "../common/Process/ProcessView";
import {
  Background,
  CommonContent,
  Container,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";

import { apiLoadProcessBlocks } from "api/routes/process";
import { useApiCall } from "hooks";

import { VideoPlayer } from "commponets/video";
import { getPhoto } from "helpers";
import { useInternacionalization } from "hooks";
import HouseConstruction from "../../assets/img/HouseConstruction.png";
import { ProcessContent } from "./section/style";
import { VideoWrapper } from "./style";

const ProcessPage = function () {
  const [blocks, isLoading] = useApiCall(apiLoadProcessBlocks, []);

  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={processBg} />
        <Main>
          <MainTitle>{translate("HOW IT WORKS")}</MainTitle>
        </Main>
      </MainWrapper>
      <Container>
        <ProcessView />
      </Container>
      <ProcessContent>
        {!isLoading && (
          <VideoWrapper>
            <VideoPlayer
              preview={HouseConstruction}
              src={getPhoto(blocks[0].video)}
            />
          </VideoWrapper>
        )}
      </ProcessContent>
    </CommonContent>
  );
};

export default ProcessPage;

{
  /* <ProcessVideo
controls
playsInline
src={getPhoto(block.video)}
></ProcessVideo> */
}
