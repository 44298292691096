import { PlanWrapper, PlanImage } from "../style";
import { HOUSE_LOOKS } from "variables";
import { MODAL } from "commponets/modals/register";
import { useModal } from "@ebay/nice-modal-react";
import { useHouseSchemeContext } from "./context";
import { getPhoto } from "helpers";

const HousePlan = ({ houseLook, room, area }) => {
  const { show: showPlan } = useModal(MODAL.IMAGE_PREVIEW_MODAL);
  const { getCurrentSchema } = useHouseSchemeContext();

  const schema = getCurrentSchema(room, area);

  const showImage = () => {
    showPlan({ image: getPhoto(schema?.image) });
  };

  return (
    <PlanWrapper isVisible={houseLook === HOUSE_LOOKS.INTERIOR}>
      <PlanImage onClick={showImage} src={getPhoto(schema?.image)} />
    </PlanWrapper>
  );
};

export default HousePlan;
