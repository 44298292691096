import { useState, useEffect } from "react";

const useApiCall = (fetchFunction, initialState, deps = []) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const load = async () => {
      const { data } = await fetchFunction();
      setData(data);
      setIsLoading(false);
    };

    load();
  }, [...deps]);

  return [data, isLoading];
};

export default useApiCall;
