import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";

import ConstructorSection from "../common/Constructor/ConstructorSection";
import DescriptionBlock from "../common/Description/DescriptionBlock";
import ProcessView from "../common/Process/ProcessView";
import EquipmentSection from "./section/Equipment/EquipmentSection";
import HomePlanSection from "./section/HomePlan/HomePlanSection";
import InvestementSection from "./section/Inverstement/InvestementSection";
import LightningSection from "./section/Lightning/LightningSection";
import SpecificationSection from "./section/specification/SpecificationSection";

import { apiLoadProduct } from "api/routes/product";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import InteriorSliderSection from "pages/interiorPage/section/InteriorSlider/InteriorSliderSection";
import { useParams } from "react-router-dom";

const ProductPage = function () {
  const { id } = useParams();
  const [product, isLoading] = useApiCall(apiLoadProduct(id, null));

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={getPhoto(product?.image)} />
        <Main>
          <MainTitle>{product?.title}</MainTitle>
        </Main>
      </MainWrapper>

      {!isLoading && (
        <>
          <DescriptionBlock
            width={700}
            fill
            height="100%"
            item={product.about}
          />
          <SpecificationSection
            product={product}
            specification={product.specification}
          />

          <HomePlanSection plan={product.house_plan} />
          <EquipmentSection />
          <LightningSection hl={product.hightlights[0]} />

          <InteriorSliderSection
            showDots
            interior={product.house_interior}
            dir={"rtl"}
            bg={"#F0F5F3"}
          />

          {/* {blocks.length &&
            (product.roof_type === "gable" ? (
              <>
                {blocks.slice(2, 3).map((item, index) => (
                  <InteriorSliderSection
                    key={item.id}
                    interior={item}
                    dir={index % 2 === 0 ? "rtl" : undefined}
                    bg={index % 2 === 0 ? "#F0F5F3" : undefined}
                  />
                ))}
              </>
            ) : (
              <>
                {blocks.slice(3, 4).map((item, index) => (
                  <InteriorSliderSection
                    key={item.id}
                    interior={item}
                    dir={index % 2 === 0 ? "rtl" : undefined}
                    bg={index % 2 === 0 ? "#F0F5F3" : undefined}
                  />
                ))}
              </>
            ))} */}

          <InvestementSection
            services={product.services}
            delivery_cost={product.delivery_cost}
            estimated_tax={product.estimated_tax}
            price={product.price}
            currency={product.currency}
            product={product}
          />
        </>
      )}

      <ConstructorSection />
      <ProcessView />
    </CommonContent>
  );
};

export default ProductPage;
