import {
  Background,
  CommonContent,
  Container,
  Main,
  MainTitle,
  MainWrapper,
} from "../common";
import HouseItem from "./HouseItem";

import { HouseContent, HouseDataWrapper, HouseWrapper } from "./style";

import { apiLoadHouses } from "api";
import { useApiCall } from "hooks";

import { getPhoto } from "helpers";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ConstructorSection from "../Constructor/ConstructorSection";
import Card360 from "./Card360";

const RoofType = () => {
  const [houses, isLoading] = useApiCall(apiLoadHouses, []);
  const [filteredHouses, setFilteredHouses] = useState([]);
  const { roofType } = useParams();

  useEffect(
    () =>
      !isLoading &&
      setFilteredHouses(() => {
        console.log(roofType);
        const resp = houses.filter((house) => house.roof_type === roofType);

        console.log(resp, houses);

        return resp;
      }),
    [isLoading]
  );

  if (isLoading) {
    return <></>;
  }

  const { image, detail_image, image_360 } = filteredHouses?.[0] || {};

  return (
    <CommonContent>
      <MainWrapper>
        {filteredHouses && (
          <Background src={getPhoto(detail_image ? detail_image : image)} />
        )}
        <Main>
          <MainTitle style={{ letterSpacing: "0.1em" }}>
            HOUSE WITH A {roofType} ROOF
          </MainTitle>
        </Main>
      </MainWrapper>

      <HouseWrapper>
        <Container>
          <HouseContent>
            <HouseDataWrapper>
              {filteredHouses &&
                filteredHouses
                  .slice(1)
                  .map((elt, index) => <HouseItem key={index} house={elt} />)}
              {filteredHouses && (
                <Card360 bg={image_360 ? image_360 : image}></Card360>
              )}
            </HouseDataWrapper>
          </HouseContent>
        </Container>
      </HouseWrapper>
      <ConstructorSection />
    </CommonContent>
  );
};

export default RoofType;

{
  /* <MainWrapper>
  <Background src={productsBg} />
  <Main>
    <MainTitle>PRODUCTS</MainTitle>
  </Main>
</MainWrapper>
<HouseSection view={true} />
<ConstructorSection /> */
}
