import styled from "styled-components";

export const NewsDetailPageWrapper = styled.div``;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  text-align: start;
  width: 100%;
  line-height: 150%;
  font-family: "Proxima Nova", sans-serif;
  color: #262626;

  @media screen and (max-width: 1180px) {
    font-size: 18px;
  }

  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
`;

export const SectionWrapper = styled.div`
  position: relative;
  margin-top: 72px;
  padding: 0px 16px;

  @media screen and (max-width: 768px) {
    margin-top: 32px;
  }
`;

export const Paragraph = styled.div`
  margin: 6px 0px;
`;
