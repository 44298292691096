import styled from "styled-components";
import info from "../../assets/img/icon/info.svg";
import loadPDF from "../../assets/img/loadPDF.png";
import loadPDFhover from "../../assets/img/loadPDFhover.png";
import loadPDFpressed from "../../assets/img/loadPDFpressed.png";

export const ContainerWrapper = styled.div`
  top: 0;
  position: fixed;
  display: ${(props) => (props.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(13px);
  width: 100%;
  height: 100vh;
  z-index: 10;
  /* &.active {
    display: flex;
  } */
`;

export const Container = styled.div`
  border-radius: 25px;

  position: relative;
  padding: 25px 40px;
  background: #fff;
  max-height: 100vh;
  @media screen and (max-width: 1180px) {
    max-height: 100%;
    overflow-y: scroll;
    padding: 25px;
    padding-top: 48px;
    width: 100%;
    border-radius: 0px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1180px;
  display: flex;
  flex-direction: column;
  z-index: 9;
  color: #000;
  background: #fff;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1180px) {
    margin: 0 auto;
    max-width: 740px;
  }
`;
export const ManageButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 0 auto;
  }
`;
export const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 18px;
  position: relative;
  @media screen and (max-width: 1180px) {
    margin: 0;
    width: calc(50vw - 40px);
    justify-content: center;
  }
  @media screen and (max-width: 620px) {
    width: auto;
  }
`;

export const StyledWideButton = styled.button`
  border-radius: 25px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.35);
  background: #f9f9f9;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  padding: 11px 17px;
  width: 169px;
  margin: 6px 0;
  &.checked {
    color: #000;
    background: #f1f1f1;
  }
  @media screen and (max-width: 1180px) {
    max-width: 365px;
    width: calc((100vw - 50px) / 2 - 12px);
    :nth-child(odd) {
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 620px) {
    max-width: none;
    width: calc((100vw - 50px) / 2 - 5px);
    margin: 0;
    margin-bottom: 8px;
    :nth-child(odd) {
      margin-right: 10px;
    }
  }
`;
export const StyledButton = styled(StyledWideButton)`
  width: auto;
  margin: 15px 6px;
  height: 40px;
  .def {
    display: inline;
  }
  .hover {
    display: none;
  }
  &.checked {
    color: #000;
    background: #f1f1f1;
    .hover {
      display: inline;
    }
    .def {
      display: none;
    }
  }
  @media screen and (max-width: 1180px) {
    max-width: none;
  }
  @media screen and (max-width: 620px) {
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0;
    margin-bottom: 8px;
    margin-left: 8px;
    :nth-child(odd) {
      :nth-child(odd) {
        margin-right: 0px;
      }
    }
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 145%;
  height: 35px;

  @media screen and (max-width: 1360px) {
    font-size: 11px;
  }

  @media screen and (max-width: 1180px) {
    margin: 0;
    height: auto;
    font-size: 20px;
  }

  @media screen and (max-width: 620px) {
    margin-bottom: auto;
    font-size: 10px;
    width: auto;
  }
`;

export const StyledImg = styled.img`
  margin-right: 8px;
  @media screen and (max-width: 620px) {
    width: 12px;
    height: 12px;
  }
`;

export const StyledHr = styled.hr`
  border: 0;
  width: 100%;
  height: 1px;
  background: #d1d1d1;
  margin: 0;
  @media screen and (max-width: 1180px) {
    margin: 0 auto;
  }
`;

export const WideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 30px;
  &.desktop {
    display: flex;
  }
  &.mobile {
    display: none;
  }
  @media screen and (max-width: 1180px) {
    justify-content: center;
    max-width: 100%;
    margin: 0;

    &.desktop {
      display: none;
    }
    &.mobile {
      display: flex;
    }
  }
`;
export const RoomWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 6px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const MainImage = styled.img`
  border-radius: 25px;
  position: relative;
  margin: 6px 0;
  max-width: 100%;
  width: 100%;
  max-height: 588px;
  height: 79vh;
  object-fit: cover;
  opacity: ${(props) => props.opacity};
  animation-name: ${(props) => (props.isLoaded ? "show" : "none")};
  animation-duration: 1.5s;

  @media screen and (max-width: 1180px) {
    height: 100%;
    width: 100%;
  }

  @keyframes show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
export const MaterialWrapper = styled.div`
  overflow-y: scroll;
  direction: rtl;
  height: 100%;
  padding-left: 30px;
  ::-webkit-scrollbar {
    width: 3px;
    background-color: rgba(0, 0, 0, 0.35);
    border: 1px solid #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #000;
  }
  @media screen and (max-width: 1180px) {
    overflow-y: hidden;
    padding-left: 0;
    margin-bottom: 10px;
  }
`;
export const MaterialDesc = styled.div`
  direction: ltr;
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: baseline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  margin: 12px 0;
  color: #0f0f0f;
  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #000;
    margin-left: 12px;
  }
`;
export const Material = styled.div`
  direction: ltr;
  display: flex;
  align-items: center;
  margin: 8px 0;
  position: relative;
  img {
    border-radius: 25px;
  }
`;
export const Info = styled.div`
  right: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  background-image: url(${info});
  background-repeat: no-repeat;
`;
export const StyledRadio = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid #000;
  :hover {
    border-color: #a0a0a0;
  }
  ::after {
    content: "";
    display: none;
    width: 10px;
    height: 10px;
    background: #000;
  }
  &.checked {
    border-color: #000;
  }
  &.checked::after {
    display: block;
  }
`;
export const MaterialTitle = styled.div`
  margin-left: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
export const LinearContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  margin-left: 30px;
  @media screen and (max-width: 1180px) {
    margin: 0;
  }
`;

export const ButtonsContainer = styled(LinearContainer)`
  gap: 12px;
`;

export const StringContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    width: auto;
  }
  @media screen and (max-width: 620px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const PriceWrapper = styled.div`
  font-style: normal;
  border-radius: 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  background: #f0f0f0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 13px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;
export const PriceTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const PriceButton = styled.button`
  color: #fff;
  border-radius: 25px;
  text-transform: uppercase;
  padding: 7.7px 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  // margin-right: ${(props) => (props.bg === "green" ? "17px" : "0")};
  background: ${(props) =>
    props.bg === "green"
      ? "linear-gradient(180deg, #007A4F 0%, #025A3B 80.73%);"
      : "linear-gradient(180deg, #750d78 0%, #4c014e 100%);"};
  :hover {
    background: ${(props) => (props.bg === "green" ? "#007A4F;" : "#750C78;")};
  }
  :active {
    background: ${(props) => (props.bg === "green" ? "#025A3B;" : "#4c014e;")};
  }
  @media screen and (max-width: 1180px) {
    font-size: 12px;
    padding: 0 18px;
    height: 37px;
    width: 100%;
    :first-child {
      margin-right: 10px;
    }
  }
`;

export const PDFButton = styled.button`
  margin-left: 12px;
  width: 37px;
  height: 37px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${loadPDF});
  :hover {
    background-image: url(${loadPDFhover});
  }
  :active {
    background-image: url(${loadPDFpressed});
  }
`;

export const RoomManageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 600px;
  height: 81vh;
  @media screen and (max-width: 1180px) {
    height: auto;
    width: 100%;
    max-height: none;
    justify-content: flex-start;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 20px;
  width: 16px;
  height: 16px;
  ::after,
  ::before {
    top: 8px;
    position: absolute;
    content: "";
    display: block;
    width: 22.62px;
    height: 1px;
    background: #262626;
  }
  ::after {
    transform: rotateZ(45deg);
  }
  ::before {
    transform: rotateZ(-45deg);
  }
`;

export const MainImageWrapper = styled.div`
  border-radius: 25px;
  position: relative;

  display: flex;
  justify-content: center;
  width: 65%;
  @media screen and (max-width: 1180px) {
    width: 100%;
    height: 600px;
  }

  @media screen and (max-width: 650px) {
    height: 93vw;
  }

  @media screen and (max-width: 470px) {
    height: 92vw;
  }
  @media screen and (max-width: 370px) {
    height: 90vw;
  }
  overflow: hidden;
`;

export const StyledButtonWrapper = styled.div`
  border-radius: 25px;
  display: flex;
  @media screen and (max-width: 620px) {
    flex-direction: column;
  }
`;

export const PlanWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  cursor: pointer;
  left: 10px;
  border-radius: 12px;
  overflow: hidden;
  width: 168px;
  height: 95px;
  z-index: 100000;
  transition: all 0.7s ease;

  opacity: ${(props) => (props.isVisible ? 1 : 0)};

  @media screen and (max-width: 600px) {
    width: 117px;
    height: 66.5px;
  }

  @media screen and (max-width: 420px) {
    width: 84px;
    height: 47.5px;
  }
`;

export const PlanImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
