import { create, useModal } from "@ebay/nice-modal-react";
import { useRef } from "react";
import X from "../../assets/img/icon/X_black.svg";
import OutsideClick from "../../helpers/OutsideClick";
import {
  Wrapper,
  Content,
  Close,
  Title,
  Conture,
  Img,
  SubTitle,
  ContentContainer,
  Border,
} from "./styled";
import { GreenButton } from "pages/common/common";
import styled from "styled-components";

const NotificationTitle = styled(Title)`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  padding: 0px;
  text-align: center;

  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
`;

export const NotificationDesc = styled(SubTitle)`
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  padding: 0px;

  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ContentWrap = styled.div`
  padding: 0px;
  border-radius: 25px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 36px;
  height: 100%;
  width: 100%;
`;

const Notification = create(
  ({
    id,
    message = "Thank you for your request",
    subText = "Our manager will contact you soon",
    callback,
  }) => {
    const close = useRef(null);
    const { visible, hide } = useModal(id);
    OutsideClick(close, () => {
      visible && hide();
    });

    return (
      <Wrapper popUp={visible}>
        <ContentContainer>
          <Conture
            className="circuit"
            style={{
              maxHeight: "330px",
              maxWidth: "580px",
              width: "100%",
              borderRadius: "25px",
              background: "white",
            }}
            ref={close}
          >
            <ContentWrapper>
              <Close
                onClick={() => {
                  if (callback) callback();
                  hide();
                }}
              >
                <img src={X} alt="" />
              </Close>
              <ContentWrap className="content-wrap">
                <NotificationTitle style={{ color: "black" }}>
                  {message}
                </NotificationTitle>
                <NotificationDesc style={{ color: "black" }}>
                  {subText}
                </NotificationDesc>
                <GreenButton
                  style={{ width: "fit-content", fontWeight: "400" }}
                  onClick={() => {
                    if (callback) callback();
                    hide();
                  }}
                  className="after-horizontal"
                >
                  <Border className="after-vertical">Back to site</Border>
                </GreenButton>
              </ContentWrap>
            </ContentWrapper>
          </Conture>
        </ContentContainer>
      </Wrapper>
    );
  }
);
export default Notification;
