import styled from "styled-components";

import { InputWrapper, QuestionForm } from "../../pages/abousUsPage/style";
import { QuestionContent } from "pages/homePage/sections/Question/style";

export const QuestionFormNew = styled(QuestionForm)`
  align-self: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }

  @media screen and (max-width: 769px) {
    gap: 0px;
  }
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.popUp ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 15;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    .circuit {
      height: 280px;
      max-width: 450px;
    }
    .content-wrap {
      gap: 24px;
    }
  }
`;

export const QuestionContainer = styled(QuestionContent)`
  flex-direction: column;
  align-content: center;
  gap: 48px;

  @media screen and (max-width: 1180px) {
    gap: 0px;
  }
`;

export const InputWrapperNew = styled(InputWrapper)`
  @media screen and (max-width: 1024px) {
    margin-right: 0px;
  }
`;

export const Conture = styled.div`
  width: 660px;
  height: 580px;
  padding: 16px;
  background: #0f0f0f;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 400px;
  }
`;
export const Content = styled.div`
  position: relative;
  background: #0f0f0f;
  width: 100%;
  height: 100%;
  padding: 72px 0 24px 0;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 44px 16px 24px 16px;
  }
`;
export const Img = styled.img`
  height: 135px;
  @media screen and (max-width: 600px) {
    height: 60px;
  }
`;
export const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  font-size: 22px;
  top: 16px;
  right: 16px;
  color: #fff;
  border: 1px solid transparent;
  transition: transform 0.3s;
  z-index: 3;
  &:hover {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }
  @media screen and (max-width: 768px) {
    right: 8px;
  }
`;
export const Title = styled.div`
  position: relative;
  z-index: 2;
  font-family: Alata;
  font-weight: 500;
  font-size: 48px;
  line-height: 150%;
  padding: 50px 0 0 0;
  @media screen and (max-width: 600px) {
    font-size: 30px;
    line-height: 120%;
    padding: 32px 0 0 0;
  }
`;
export const SubTitle = styled.div`
  position: relative;
  z-index: 2;
  font-family: Alata;
  font-weight: 500;
  font-size: 27px;
  line-height: 150%;
  color: #ffffff;
  padding: 24px 0 50px 0;
  text-align: center;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 120%;
    padding: 24px 0 32px 0;
  }
`;
export const Border = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 12px 24px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 180px;
  font-family: ProximaNova;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background-color: transparent;
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 19px;
  }
`;
