import styled from "styled-components";
import { GreenButton } from "../common";

export const NewsSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 575px;
  max-height: 590px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  margin-bottom: 40px;
  margin-left: 18px;
  :nth-child(odd) {
    margin-right: 30px;
    margin: ${(props) => props.slider && "0"};
    @media screen and (max-width: 1180px) {
      margin-bottom: 32px;
    }
  }
  @media screen and (max-width: 1180px) {
    :nth-child(odd) {
      margin-right: 16px;
    }
    margin-right: 16px;
    margin-bottom: 32px;
    max-width: none;
    max-height: none;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const NewsSectionItemContent = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1180px) {
    max-height: none;
  }
`;

export const NewsSectionItemImage = styled.img`
  min-height: 320px;
  max-height: 320px;
  object-fit: cover;
  width: 100%;
  border-radius: 25px;
  @media screen and (max-width: 1180px) {
    width: ${(props) =>
      props.slider ? " calc(100vw - 50px)" : " calc(100vw - 30px)"};
    height: auto;
  }
`;

export const NewsSectionItemText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin-top: 0px;
  }
`;

export const NewsSectionItemTitle = styled.div`
  font-weight: 600;
  line-height: 150%;
  font-size: 27px;
  color: inherit;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

export const NewsSectionItemDescription = styled.div`
  margin: 16px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* max-height: 81px; */
  /* @media screen and (max-width: 600px) { */
  /* max-height: 72px; */
  /* } */
`;

export const NewsSectionItemDate = styled.div`
  font-size: 12px;
  line-height: 27px;
  text-align: right;
  color: #262626;
  opacity: 0.5;
  min-width: 100px;
  @media screen and (max-width: 600px) {
    text-align: start;
    margin: 4px 0;
  }
`;

export const ReadMoreButton = styled(GreenButton)`
  height: 45px;
  @media screen and (max-width: 600px) {
    height: 37px;
    width: 164px;
    font-size: 16px;
  }
`;

export const NewsWrapper = styled.div`
  margin-left: 14px;
`;
