import {
  NewsSectionItem,
  NewsSectionItemContent,
  NewsSectionItemText,
  NewsSectionItemTitle,
  NewsSectionItemDate,
  NewsSectionItemDescription,
  NewsSectionItemImage,
  ReadMoreButton,
  NewsWrapper,
} from "./style";

import { getPhoto } from "helpers";
import { useHistory } from "react-router-dom";

const NewsItem = function ({ news, slider = false }) {
  const history = useHistory();

  const routeChange = () => {
    let path = `/news/` + news.id;
    history.push(path);
  };

  return (
    <NewsWrapper style={{ marginLeft: slider ? 14 : 0 }}>
      {news && (
        <NewsSectionItem slider={slider} className="news-item">
          <NewsSectionItemContent className="news-content">
            <NewsSectionItemImage
              src={getPhoto(news.image)}
              className="news-image"
            />
            <NewsSectionItemText className="news-text">
              <NewsSectionItemTitle className="news-title">
                {news.title}
              </NewsSectionItemTitle>
              <NewsSectionItemDate className="news-date">
                {news.date}
              </NewsSectionItemDate>
            </NewsSectionItemText>
            <NewsSectionItemDescription className="news-desc">
              {slider
                ? news.description.slice(0, 180) + "..."
                : news.description}
            </NewsSectionItemDescription>
          </NewsSectionItemContent>
          <ReadMoreButton onClick={routeChange}> Read more </ReadMoreButton>
        </NewsSectionItem>
      )}
    </NewsWrapper>
  );
};

export default NewsItem;
