import styled from "styled-components";
import { CommonTitle, ContainerMobileContent } from "../common";
export const ProcessViewWrapper = styled(ContainerMobileContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 100px 0px 48px 0px;
  width: 100%;

  @media screen and (max-width: 1180px) {
    margin: 48px 0px;
  }

  @media screen and (max-width: 600px) {
    margin: 60px 0;
  }
`;

export const ProcessViewItemWrapper = styled.div`
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto;
  grid-template-rows: 1fr 1fr;
  position: relative;
  background: #f0f5f3;
  padding: 0 34px 0 31px;
  width: 100%;
  ::after {
    left: 31px;
    content: "";
    height: 4px;
    //100% - last-child width - padding
    width: calc(100% - 111px - 65px);
    top: 50%;
    display: block;
    position: absolute;
    background: #a0a0a0;
  }
  @media screen and (max-width: 1180px) {
    padding: 0;
    max-width: calc(100vw - 50px);
    grid-template-columns: 50% 50%;
    ::after {
      height: calc(74% - 70px);
      width: 4px;
      left: calc(50% - 2px);
      top: 20%;
    }
  }
`;

export const ProcessViewItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 0;
  padding-left: 4px;
  width: 156px;
  position: relative;
  :nth-child(odd) {
    grid-column: ${(props) => (props.column ? props.column : "auto")};
    grid-row: 1;
    justify-content: flex-end;
    ::before,
    ::after {
      bottom: 0;
    }
    ::after {
      left: ${(props) => (props.min ? "-7px" : "-9px")};
      bottom: ${(props) => (props.min ? "-10px" : "-12px")};
      z-index: 3;
    }
  }
  :nth-child(even) {
    grid-column: ${(props) => (props.column ? props.column : "auto")};
    grid-row: 2;
    justify-content: flex-start;
    ::before,
    ::after {
      top: 0;
    }
    ::after {
      left: ${(props) => (props.min ? "-7px" : "-9px")};
      top: ${(props) => (props.min ? "-6px" : "-8px")};
      z-index: 3;
    }
  }
  :first-child {
    ::before {
      height: 78px;
      bottom: -39px;
    }
  }
  :last-child {
    width: 111px;
    ::before {
      height: 78px;
      top: -39px;
    }
  }
  ::before {
    left: 0;
    bottom: 0;
    width: 2px;
    height: 39px;
    content: "";
    display: block;
    position: absolute;
    background: #a0a0a0;
  }
  ::after {
    left: 0;
    bottom: 0;
    content: "";
    display: block;
    position: absolute;
    border-radius: 100%;
    background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
    width: ${(props) => (props.min ? "16px" : "20px")};
    height: ${(props) => (props.min ? "16px" : "20px")};
  }
  &.top {
    ::before {
      top: 0;
    }
    ::after {
      top: 0;
    }
  }
  @media screen and (max-width: 1180px) {
    padding: 0 22px;
    padding-bottom: 32px;
    width: 100%;
    :nth-child(odd) {
      grid-row: ${(props) => (props.column ? props.column : "auto")};
      grid-column: 1;
      ::before {
        left: auto;
        right: 0;
        top: 10px;
      }
      ::after {
        top: ${(props) => (props.min ? "3px" : "1px")};
        right: ${(props) => (props.min ? "-8px" : "-10px")};
        left: auto;
      }
    }
    :nth-child(even) {
      grid-row: ${(props) => (props.column ? props.column : "auto")};
      grid-column: 2;
      ::before {
        right: auto;
        left: 0;
        top: 10px;
      }
      ::after {
        top: ${(props) => (props.min ? "3px" : "1px")};
        left: ${(props) => (props.min ? "-8px" : "-10px")};
        right: auto;
      }
    }
    :first-child {
      padding-bottom: 47px;
      ::after {
        display: none;
      }
      span {
        position: relative;
        ::before,
        ::after {
          right: -24px;
          content: "";
          display: block;
          position: absolute;
        }
        ::before {
          width: 4px;
          height: 200px;
          background: #a0a0a0;
        }
        ::after {
          border-radius: 100%;
          background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
          width: ${(props) => (props.min ? "16px" : "20px")};
          height: ${(props) => (props.min ? "16px" : "20px")};
          right: ${(props) => (props.min ? "-30px" : "-32px")};
          top: ${(props) => (props.min ? "-6px" : "-8px")};
        }
      }
      ::before {
        display: none;
      }
    }
    :last-child {
      width: 100%;
      ::after {
        top: ${(props) => (props.min ? "3px" : "1px")};
        left: ${(props) => (props.min ? "-8px" : "-10px")};
        right: auto;
      }
      ::before {
        right: auto;
        left: 0;
        top: 10px;
        width: ${(props) => (props.min ? "14px" : "16px")};
        height: 2px;
      }
    }
    ::before {
      top: 0;
      width: ${(props) => (props.min ? "14px" : "16px")};
      height: 2px;
    }
  }
`;

export const ProcessViewItemTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  color: #262626;
  @media screen and (max-width: 600px) {
    font-size: 18px;
  }
`;
export const ProcessViewItemText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #262626;
  position: relative;
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
`;

export const ProcessViewItemHr = styled.span`
  display: block;
  border: 0;
  height: 3px;
  width: 100%;
  margin: 0;
  margin: 12px 0;
  background: linear-gradient(180deg, #007a4f 0%, #025a3b 80.73%);
`;

export const ProcessImage = styled.img`
  position: absolute;
  width: 95px;
  top: calc(50% - 8px);
  right: 22px;
  @media screen and (max-width: 1180px) {
    top: auto;
    right: auto;
    left: 32px;
    bottom: 65px;
  }
`;

export const ProcessViewTitle = styled(CommonTitle)`
  margin-bottom: 50px;
  @media screen and (max-width: 1180px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;
