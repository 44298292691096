import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";
import contactsBg from "../../assets/img/contactsBg.png";
import QuestionFormSection from "../common/QuestionForm";
import InfoSection from "./section/InfoSection";

import { useInternacionalization } from "hooks";

const ContactsPage = function () {
  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={contactsBg} />
        <Main>
          <MainTitle>{translate("Get in touch with us")}</MainTitle>
        </Main>
      </MainWrapper>
      <InfoSection />
      <QuestionFormSection bg={"#f0f5f3"}>
        {translate("Contact us")}
      </QuestionFormSection>
    </CommonContent>
  );
};

export default ContactsPage;
