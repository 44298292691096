import { Container } from "../../common/common";
import HouseItem from "../../common/House/HouseItem";
import { RoofSectionWrapper } from "./style";

const RoofSection = function ({ houses }) {
  return (
    <Container>
      <RoofSectionWrapper>
        {houses.map((house, index) => (
          <HouseItem key={index} house={house} />
        ))}
      </RoofSectionWrapper>
    </Container>
  );
};

export default RoofSection;
