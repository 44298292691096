/* eslint-disable no-undef */
import styled from "styled-components";
import Image404 from "../../assets/img/Image404.png";
import { useHistory } from "react-router-dom";
import { Gradient } from "../../commponets/styled";

import { useInternacionalization } from "hooks";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  /* background: url(${Image404}) no-repeat; */
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.div`
  z-index: 1;
  font-family: Alata;
  font-style: normal;
  font-weight: normal;
  font-size: 400px;
  line-height: 400px;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 220px;
    line-height: 220px;
  }
  @media screen and (max-width: 600px) {
    font-size: 150px;
    line-height: 150px;
  }
`;
const Subtitle = styled.div`
  text-align: center;
  z-index: 1;
  font-family: Alata;
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 150%;
  padding: 40px 0 32px 0;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;
const Button = styled.button`
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  min-width: 140px;
  height: 50px;
  background: transparent;
  border: 1px solid #fff;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #fff;
`;
const Border = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
const Page404 = () => {
  const history = useHistory();

  const { translate } = useInternacionalization();

  return (
    <Wrapper>
      <Gradient />
      <Title>404</Title>
      <Subtitle>
        {translate("The page your requested could not be found")}
      </Subtitle>
      <Button onClick={() => history.goBack()} className="after-horizontal">
        <Border className="after-vertical">{translate("Go back")}</Border>
      </Button>
    </Wrapper>
  );
};
export default Page404;
