import { useEffect, useRef } from "react";

const useScrollContainer = (active) => {
  const itemRef = useRef(null);
  const scrollContainer = useRef(null);

  useEffect(() => {
    const container = scrollContainer.current;
    const target = itemRef.current;
    if (container && target) {
      const containerWidth = container.offsetWidth;
      const targetWidth = target.offsetWidth;
      const targetLeft = target.offsetLeft;
      const scrollLeft = targetLeft - (containerWidth - targetWidth) / 2;
      container.scrollTo({ left: scrollLeft, behavior: "smooth" });
    }
  }, [active]);

  return {
    scrollContainer,
    itemRef,
  };
};

export default useScrollContainer;
