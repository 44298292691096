import { createContext, useContext, useState } from "react";
import { apiLoadCombinations } from "api/routes/calculator";
import { getCombinationKey } from "./helpers";

const CalculatorContext = createContext({
  state: {},
  setState: () => {},
  loadCombinations: () => {},
  getElement: () => {},
  currentCombination: null,
  setCurrentCombination: () => {},
});

export const CalculatorProvider = ({ children }) => {
  const [state, setState] = useState({});
  const [currentCombination, setCurrentCombination] = useState({});

  const getElement = async (payload) => {
    const el = state[getCombinationKey(payload)];

    if (!el) {
      await loadCombinations(payload);
    } else {
      setCurrentCombination(el);
    }
  };

  const loadCombinations = async (payload) => {
    const { data } = await apiLoadCombinations(payload);

    data.forEach((combination) => {
      setState((prev) => ({
        ...prev,
        [getCombinationKey(combination)]: combination,
      }));

      if (getCombinationKey(combination) === getCombinationKey(payload)) {
        setCurrentCombination(combination);
      }
    });
  };

  return (
    <CalculatorContext.Provider
      value={{
        loadCombinations,
        state,
        setState,
        getElement,
        setCurrentCombination,
        currentCombination,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};

export const useCalculatorContext = () => useContext(CalculatorContext);
