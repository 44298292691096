import { Container } from "../../../common/common";
import {
  QuestionWrapper,
  QuestionTitle,
  QuestionContent,
  QuestionItem,
  QuestionItemTitle,
  QuestionItemDescription,
} from "./style";

import { QuestionForm } from "../../../common/QuestionForm";

import { useApiCall } from "hooks";
import { apiLoadQuestions } from "api";

import { useInternacionalization } from "hooks";

const QuestionSection = function () {
  const [questions, isLoading] = useApiCall(apiLoadQuestions, []);

  const { translate } = useInternacionalization();

  if (isLoading) {
    return <></>;
  }

  function SmoothMove(e) {
    e.stopPropagation();
    const target = e.currentTarget;
    const childrens = e.currentTarget.parentElement.children;
    if (childrens) {
      for (let child of childrens) {
        const desc = child.getElementsByClassName("desc")[0];
        if (desc && child.classList.contains("active"))
          desc.style.maxHeight = 0;
        if (child !== target) child.classList.remove("active");
      }
    }
    target.classList.toggle("active");
    const desc = target.getElementsByClassName("desc")[0];
    desc && target.classList.contains("active")
      ? (desc.style.maxHeight = desc.scrollHeight + 32 + "px")
      : (desc.style.maxHeight = 0);
  }

  return (
    <>
      <QuestionWrapper>
        <Container>
          <QuestionContent>
            <div>
              {questions.map((item) => (
                <QuestionItem key={item.id} onClick={(e) => SmoothMove(e)}>
                  <QuestionItemTitle className="title">
                    {item.name}
                  </QuestionItemTitle>
                  <QuestionItemDescription className="desc">
                    {item.content}
                  </QuestionItemDescription>
                </QuestionItem>
              ))}
            </div>
            <QuestionTitle>
              {translate("FRIENDLY ASKED QUESTIONS")}
            </QuestionTitle>
          </QuestionContent>
        </Container>
      </QuestionWrapper>
      <QuestionForm />
    </>
  );
};

export default QuestionSection;
