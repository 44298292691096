import styled from "styled-components";
import partnersBg from "../../../../assets/img/partnersBg.png";
import { ContainerMobileContent } from "../../../common/common";
export const PartnersWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  background-image: url(${partnersBg});
  background-repeat: no-repeat;
  background-size: cover;
`;
export const PartnersContentWrapper = styled.div`
  height: 100%;
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const PartnersContent = styled(ContainerMobileContent)`
  display: flex;
  height: 100%;
  padding-bottom: 48px;
  padding-top: 48px;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
    max-width: 710px;
    padding-bottom: 24px;
    padding-top: 0px;
  }
`;

export const PartnersItems = styled.div`
  display: flex;
`;

export const PartnersImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
`;

export const PartnersContentDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #fff;
  margin-right: 90px;
  @media screen and (max-width: 1180px) {
    margin-bottom: 28px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    margin-right: 0;
  }
`;
export const PartnersContentTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  text-transform: uppercase;
  color: inherit;
  margin: 24px 0;
  @media screen and (max-width: 600px) {
    font-size: 27px;
    margin: 32px 0px 20px 0px;
  }
`;

export const PartnersImage = styled.img`
  height: auto;
  object-fit: cover;
  margin: 0 auto;
  max-height: 57px;
  @media screen and (max-width: 600px) {
    width: 100px;
  }
`;

export const Description = styled.div`
  text-align: justify;
`;
