import styled from "styled-components";
import { ContainerMobileContent } from "../../../common/common";

export const QuestionWrapper = styled.div`
  background: #fff;
  display: flex;
  justify-content: center;
`;

export const QuestionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  text-transform: uppercase;
  color: #262626;
  min-width: 350px;
  margin-left: 30px;
  /* padding-right: 50px; */
  @media screen and (max-width: 1180px) {
    min-width: auto;
    margin: 0 0 24px 0;
  }
  @media screen and (max-width: 1180px) {
    font-size: 27px;
  }
`;

export const QuestionContent = styled(ContainerMobileContent)`
  display: flex;
  margin: 100px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column-reverse;
    margin: 32px 0;
  }
`;

export const QuestionItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  &.active {
    .desc {
      opacity: 1;
      padding: 16px 32px;
    }
    .title {
      color: #025a3b;
      ::before {
        transform: rotateZ(-45deg);
        background: #025a3b;
      }
      ::after {
        transform: rotateZ(45deg);
        background: #025a3b;
      }
    }
  }
  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
  }
`;
export const QuestionItemTitle = styled.div`
  border: 1px solid rgba(38, 38, 38, 1);
  border-radius: 25px;
  margin-top: 0px;
  transition: color 0.5s;
  position: relative;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  line-height: 150%;
  color: #262626;
  font-size: 20px;
  width: 100%;
  display: flex;
  padding: 10px 0;
  padding-left: 30px;
  padding-right: 45px;
  ::after,
  ::before {
    top: 50%;
    width: 13px;
    height: 2px;
    content: "";
    display: block;
    background: #262626;
    position: absolute;
    transition: all 0.5s;
  }
  ::after {
    transform: rotateZ(-45deg);
    right: 25px;
  }
  ::before {
    transform: rotateZ(45deg);
    right: 33px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 150%;
    /* padding: 8px 0;
    padding-left: 16px; */
    font-weight: 400;
    height: auto;
    ::before,
    ::after {
      width: 9px;
      right: 14px;
    }
    ::after {
      right: 19px;
    }
  }
`;
export const QuestionItemDescription = styled.div`
  background: #f0f5f3;
  border-radius: 25px;
  height: 120% !important;
  padding: 0 32px;
  position: relative;
  transition: all 0.5s;
  max-height: 0px;
  height: auto;
  overflow: hidden;
  opacity: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #262626;
  margin-bottom: 16px;
  @media screen and (max-width: 600px) {
    margin-bottom: 8px;
  }
`;
