import axiosBase from "./axiosBase";

export {
  apiLoadBlock,
  apiLoadBlockWithAdvantages,
  apiLoadHouses,
  apiLoadLatestNews,
  apiLoadPartners,
  apiLoadQuestions,
} from "./routes/home";

export const apiLoadMenu = () => axiosBase.get("menu");

export const apiLoadAboutMain = () => axiosBase.get("block/page/main");
export const apiLoadAboutBlocks = () => axiosBase.get("block/page/blocks");

export const apiLoadHome = () => axiosBase.get("statics/home/");
export const apiSendConstructor = (data) =>
  axiosBase.post("messages/create/", data);
export const apiLoadProductPage = () => axiosBase.get("statics/products/");
export const apiLoadProduct = (id) => axiosBase.get(`statics/product/${id}/`);

export const apiLoadNews = (page) => axiosBase.get(`statics/news/${page}`);
export const apiLoadTechnologies = () => axiosBase.get("statics/technologies/");
export const apiLoadProcess = () => axiosBase.get("statics/process/");

export const apiLoadContactBlock = () => axiosBase.get("statics/contact/");
export const apiLoadAboutBlock = () => axiosBase.get("statics/about/");
export const apiLoadInterior = () => axiosBase.get("statics/interior/");

export const apiLoadSocials = () => axiosBase.get("social/networks");
export const apiLoadNewsById = (id) => axiosBase.get(`news/${id}`);

export const apiLoadContacts = () => axiosBase.get("statics/contact/");
export const apiLoadLastNews = () => axiosBase.get("news/first/");
export const apiLoadHouse = (id) => axiosBase.get(`houses/${id}`);
export const apiLoadFirstHouse = () => axiosBase.get("houses/first/");
export const apiSendQuestion = (data) =>
  axiosBase.post("messages/question/", data);

export const apiLoadConstructor = () => axiosBase.get("statics/constructor/");
