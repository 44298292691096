import newsBg from "assets/img/newsBg.png";
import { Container } from "../../../common/common";

import {
  NewsWrapper,
  NewsContent,
  NewsTitle,
  NewsHr,
  NewsButtonWrapper,
  NewsButton,
  NewsBackground,
  NewsDescription,
} from "./style";

import { useApiCall } from "hooks";
import { apiLoadLatestNews } from "api";
import { useHistory } from "react-router-dom";

import { useInternacionalization } from "hooks";
import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";

const NewsSection = function () {
  const [news, isLoading] = useApiCall(apiLoadLatestNews, null);
  const { show: showSubscribe } = useModal(MODAL.SUBSCRIBE_MODAL);
  const history = useHistory();

  const { translate } = useInternacionalization();

  const pushRouter = () => {
    history.push("/news");
  };

  const handleClick = () => {
    history.push("/news/" + news.id);
  };

  return (
    <NewsWrapper>
      <NewsContent>
        <Container>
          <NewsTitle>{translate("News")}</NewsTitle>
          <NewsDescription onClick={handleClick} style={{ cursor: "pointer" }}>
            <NewsHr />
            {!isLoading && news.title}
            <NewsHr />
          </NewsDescription>

          <NewsButtonWrapper>
            <NewsButton onClick={pushRouter} style={{ marginRight: "16px" }}>
              {translate("Read more")}
            </NewsButton>
            <NewsButton
              onClick={() => {
                showSubscribe();
              }}
            >
              {translate("Subscribe")}
            </NewsButton>
          </NewsButtonWrapper>
        </Container>
      </NewsContent>
      <NewsBackground src={newsBg} />
    </NewsWrapper>
  );
};

export default NewsSection;
