import DescriptionBlock from "../common/Description/DescriptionBlock";

import {
  Background,
  CommonContent,
  Main,
  MainTitle,
  MainWrapper,
} from "../common/common";

import { apiLoadAboutBlocks } from "api/routes/about";
import { useApiCall } from "hooks";
import QuestionFormSection from "../abousUsPage/QuestionFormAboutUs";
import aboutUsBg from "../../assets/img/aboutUs.png";

import { useInternacionalization } from "hooks";

const AboutUsPage = function () {
  const [blocks, isBlocksLoading] = useApiCall(apiLoadAboutBlocks, []);

  const { translate } = useInternacionalization();

  return (
    <CommonContent>
      <MainWrapper>
        <Background src={aboutUsBg} />
        <Main>
          <MainTitle>{translate("ABOUT US")}</MainTitle>
        </Main>
      </MainWrapper>

      {!isBlocksLoading &&
        blocks.map((item, index) => (
          <DescriptionBlock
            key={index}
            item={item}
            height={"414px"}
            dir={index % 2 === 0 ? false : true}
          />
        ))}

      <QuestionFormSection>{translate("CONTACT US")}</QuestionFormSection>
    </CommonContent>
  );
};

export default AboutUsPage;
