import { getPhoto } from "helpers";
import { useInternacionalization } from "hooks";
import { useHistory } from "react-router-dom";
import {
  DescriptionBlockContent,
  DescriptionBlockImage,
  DescriptionBlockText,
  DescriptionBlockTitle,
  DescriptionBlockWrapper,
  DescriptionContentConteiner,
  InteriorButton,
  Paragraph,
  Description,
} from "./style";

const DescriptionBlock = function ({
  interior = false,
  item,
  height = "387px",
  bg = "#fff",
  dir = false,
  fill = false,
  width,
}) {
  const { translate } = useInternacionalization();
  const history = useHistory();

  return (
    <DescriptionBlockWrapper interior={interior} bg={dir ? "#F0F5F3" : bg}>
      <DescriptionBlockContent dir={dir}>
        <DescriptionBlockImage
          fill={fill}
          src={getPhoto(item.image)}
          height={height}
          dir={dir}
          width={width}
        />
        <DescriptionContentConteiner>
          <DescriptionBlockText>
            <DescriptionBlockTitle>{item.title}</DescriptionBlockTitle>

            <Description>
              {item.descriptions.length
                ? item.descriptions.map((desc, index) => (
                    <Paragraph key={index}> {desc.content}</Paragraph>
                  ))
                : item.description}
            </Description>
          </DescriptionBlockText>
          {interior && (
            <InteriorButton onClick={() => history.push("/interior")}>
              {translate("LEARN MORE")}
            </InteriorButton>
          )}
        </DescriptionContentConteiner>
      </DescriptionBlockContent>
    </DescriptionBlockWrapper>
  );
};

export default DescriptionBlock;
