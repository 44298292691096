import { createContext, useState, useEffect, useContext } from "react";
import { apiLoadSchemas } from "api/routes/calculator";

const HouseSchemeContext = createContext({
  schemas: {},
  setSchemas: () => {},
  roof: 0,
  getCurrentSchema: () => {},
});

export const HouseSchemeProvider = ({ children, roof }) => {
  const [schemas, setSchemas] = useState({});

  const loadRoof = async () => {
    if (!roof) {
      return;
    }

    if (schemas[roof]) {
      return;
    }

    const { data } = await apiLoadSchemas(roof);
    setSchemas((prev) => ({ ...prev, [roof]: data }));
  };

  useEffect(() => {
    loadRoof();
  }, [roof]);

  const getCurrentSchema = (room, area) =>
    (schemas?.[roof] || [])?.find?.(
      (schema) => schema.room === room && schema.area === area
    );

  return (
    <HouseSchemeContext.Provider
      value={{ schemas, setSchemas, roof, getCurrentSchema }}
    >
      {children}
    </HouseSchemeContext.Provider>
  );
};

export const useHouseSchemeContext = () => useContext(HouseSchemeContext);
