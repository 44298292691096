/* eslint-disable no-unused-vars */
import { apiLoadMenu, apiLoadSocials } from "api";
import LogosDark from "assets/img/Logo-dark.svg";
import Logos from "assets/img/Logo.svg";
import { IconMobile, SocialMobile } from "commponets/styled";
import OutsideClick from "helpers/OutsideClick";
import useOnScreen from "helpers/UseOnScreen";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import { useInternacionalization } from "hooks";

import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { HeaderSocial } from "./api";
import {
  Body,
  Box,
  BurgerMenu,
  CalculatorButton,
  Container,
  HeaderHr,
  Invision,
  Logo,
  Menu,
  Nav,
  Wrapper,
} from "./style";

const Header = () => {
  const location = useLocation();
  const [socials, setSocial] = useState([]);
  const [lang, setLang] = useState(false);
  const [langMobile, setLangMobile] = useState(false);
  const [isActiveMenu, setIsActiveMenu] = useState(false);
  const { i18n } = useTranslation();
  const ref = useRef(null);
  const [screenEvent, setIsVisible] = useOnScreen(ref, "0px");
  const [onScreen, setOnScreen] = useState(false);
  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSocials, setLoadingSocials] = useState(true);
  const isMenu = useRef();
  const { show: showCalculator } = useModal(MODAL.CALCULATOR_MODAL);
  const history = useHistory();

  const { translate } = useInternacionalization();

  OutsideClick(isMenu, () => {
    setIsActiveMenu(false);
  });

  const langs = useRef();
  OutsideClick(langs, () => {
    setLang(false);
  });

  const langsMobile = useRef();
  OutsideClick(langsMobile, () => {
    setLangMobile(false);
  });

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflowY = isActiveMenu ? "hidden" : "auto";
  }, [isActiveMenu]);

  useEffect(() => {
    const load = async () => {
      const { data } = await apiLoadMenu();
      setMenu(data);
      setLoading(false);
    };
    const loads = async () => {
      const { data } = await apiLoadSocials();
      setSocial(data);
      setLoadingSocials(false);
    };
    load();
    loads();
  }, [i18n.language]);

  useEffect(() => setOnScreen(screenEvent), [screenEvent]);

  if (loading || loadingSocials) {
    return <Invision ref={ref} />;
  }
  return (
    <>
      <Invision ref={ref} />
      <Wrapper
        scroll={!onScreen}
        className="header"
        isActiveMenu={isActiveMenu}
        ref={isMenu}
        main={location.pathname === "/"}
      >
        <Container>
          <Body>
            {/* ########## DO NOT DELETE THIS CODE !!!!! ##########


             <LangBoxMobile ref={langsMobile} isActiveMenu={isActiveMenu}>
              <Lang
                scroll={!onScreen}
                onClick={() => setLangMobile(!langMobile)}
                langMobile={langMobile}
              >
                {i18n.language}
                <div></div>
              </Lang>
              <Langs lang={langMobile} scroll={!onScreen}>
                {i18n.languages.slice(1, i18n.languages.length).map((lng) => (
                  <LangItem
                    key={lng}
                    scroll={!onScreen}
                    onClick={() => {
                      changeLaguage(lng);
                      setLangMobile(!langMobile);
                    }}
                  >
                    {lng}
                  </LangItem>
                ))}
              </Langs>
            </LangBoxMobile> */}
            <Logo
              onClick={() => {
                history.push("/");
                setIsVisible(false);
                setIsActiveMenu(false);
                window.scrollTo(0, 0);
              }}
            >
              <img
                className="mobile"
                src={onScreen ? Logos : LogosDark}
                alt=""
              />
              <img src={onScreen ? Logos : LogosDark} alt="" />
            </Logo>

            <Menu
              isActiveMenu={isActiveMenu}
              scroll={!onScreen}
              className={isActiveMenu ? "header-menu active" : "header-menu"}
            >
              {/* ########## DO NON DELETE THIS CODE !!!!! ##########


               <LangBox ref={langs}>
                <Lang
                  scroll={!onScreen}
                  onClick={() => setLang(!lang)}
                  lang={lang}
                >
                  {i18n.language}
                  <div></div>
                </Lang>

                <Langs lang={lang} scroll={!onScreen}>
                  {i18n.languages.slice(1, i18n.languages.length).map((lng) => (
                    <LangItem
                      key={lng}
                      scroll={!onScreen}
                      onClick={() => {
                        localStorage.setItem("language", lng);
                        changeLaguage(lng);
                        setLang(!lang);
                      }}
                    >
                      {lng}
                    </LangItem>
                  ))}
                </Langs>
              </LangBox> */}

              {menu.slice(0, menu.length).map((tab) => (
                <Nav
                  onClick={() => {
                    setIsVisible(false);
                    setIsActiveMenu(false);
                    window.scrollTo(0, 0);
                  }}
                  scroll={!onScreen}
                  key={tab.route}
                  to={tab.route}
                  isActiveNav={location.pathname === tab.route}
                >
                  {tab.site_display_name}
                </Nav>
              ))}

              <CalculatorButton
                header={true}
                visible={
                  isActiveMenu
                    ? true
                    : location.pathname === "/" && onScreen
                    ? false
                    : true
                }
                onClick={() => {
                  showCalculator();
                }}
              >
                {translate("Calculator")}
              </CalculatorButton>
              <HeaderHr />
              <SocialMobile>
                {HeaderSocial.map((social) => (
                  <IconMobile key={social.id} href={social.url}>
                    <img src={social.icon} alt=""></img>
                  </IconMobile>
                ))}
              </SocialMobile>
            </Menu>
            <Box>
              <button
                scroll={!onScreen}
                onClick={() => {
                  if (screenEvent) {
                    setIsActiveMenu(!isActiveMenu);
                    setOnScreen(!onScreen);
                  } else setIsActiveMenu(!isActiveMenu);
                }}
                className={
                  isActiveMenu
                    ? "header-menu__toggle active"
                    : "header-menu__toggle"
                }
                type="button"
              >
                <BurgerMenu
                  scroll={!onScreen}
                  isActiveMenu={isActiveMenu}
                  className="header-menu__toggle__item"
                >
                  Menu
                </BurgerMenu>
              </button>
            </Box>
          </Body>
        </Container>
      </Wrapper>
    </>
  );
};
export default Header;
