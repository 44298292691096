import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Video = styled.video`
  width: 100%;
  height: auto;

  @media screen and (max-width: 1180px) {
    width: 100%;
    height: auto;
  }
`;

export const Layer = styled.div`
  background: rgba(0, 122, 79, 0.7);
  position: absolute;
  transition: all 0.3s ease;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;

  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const Button = styled.div`
  background: #b90303;
  backdrop-filter: blur(2px);
  border-radius: 8px;
  width: 78px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    transform: scale(0.95);
  }

  @media screen and (max-width: 600px) {
    width: calc(78px * 0.8);
    height: calc(54px * 0.8);
  }
`;

export const Image = styled.img`
  height: 20px;
  width: 20px;

  &::selection {
    background: transparent;
  }
  @media screen and (max-width: 600px) {
    height: 16px;
    width: 16px;
  }
`;

export const Preview = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
`;
