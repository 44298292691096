import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { locales } from "./locales";
import axiosBase from "../api/axiosBase";

export const language = () => {
  return localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "En";
};

i18n.use(initReactI18next).init({
  resources: locales,
  fallbackLng: ["En", "Ru", "Et"],
  lng: language(),
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const changeLaguage = (languageKey) => {
  i18n.changeLanguage(languageKey);
  axiosBase.defaults.baseURL = `http://localhost:8000/${languageKey.toLowerCase()}/`;
};

export default i18n;
