import { useRef } from "react";
import styled from "styled-components";
import X from "../../assets/img/icon/X.svg";
import OutsideClick from "../../helpers/OutsideClick";
import { Wrapper, Close, Border } from "../form/styled";

import { useForm } from "hooks";
import { useSnackbar } from "notistack";
import { QuestionService } from "service";
import React from "react";

import { useInternacionalization } from "hooks";
import { create, useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
const Conture = styled.div`
  width: 94%;
  max-width: 1180px;
  padding: 14px;
  margin-left: -16px;
  background: transparent;
  @media screen and (max-width: 1024px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    padding: 8px;
  }
`;
const Content = styled.div`
  position: relative;
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 52px 56px 52px 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 64px 16px 24px 16px;
  }
`;
export const CloseBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  font-size: 22px;
  top: 16px;
  right: 16px;
  color: #fff;
  border: 1px solid transparent;
  transition: transform 0.3s;
  z-index: 3;
  right: -40px;
  &:hover {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }
  @media screen and (max-width: 768px) {
    right: -1px;
    top: -1px;
  }
`;

const Modal360 = create(({ id, roomid }) => {
  const { show: showNotification, hide: hideNotification } = useModal(
    MODAL.NOTIFICATION_MODAL
  );
  const close = useRef();
  const snackBar = useSnackbar();
  const { visible, hide } = useModal(id);
  OutsideClick(close, () => {
    visible && hide();
  });

  const { form, cleanForm } = useForm({
    user_name: "",
    user_email: "",
    user_phone: "",
    content: "",
  });

  const { translate } = useInternacionalization();

  const sendQuestion = async () => {
    await QuestionService.createQuestion(form);

    snackBar.enqueueSnackbar(translate("Message sent"), {
      variant: "success",
    });

    cleanForm();
  };
  const handleClick = () => {
    sendQuestion();
    showNotification();
    hide();
  };

  return (
    <Wrapper
      style={{ zIndex: "2000", background: "rgba(0, 0, 0, 0.9)" }}
      popUp={visible}
    >
      <Conture ref={close}>
        <Content
          style={{ padding: "0px", borderRadius: "25px", marginTop: "30px" }}
        >
          <CloseBtn
            onClick={() => {
              hide();
            }}
            popUp={true}
          >
            <img src={X} alt="" />
          </CloseBtn>
          <iframe
            width="100%"
            height="540"
            frameborder="0"
            allow="xr-spatial-tracking; gyroscope; accelerometer"
            allowfullscreen
            scrolling="no"
            src={`https://kuula.co/share/${roomid}?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1&margin=30&alpha=0.83`}
          ></iframe>
        </Content>
      </Conture>
    </Wrapper>
  );
});
export default Modal360;
