import { VioletButton, Container } from "../../pages/common/common";
import {
  QuestionFormWrapper,
  QuestionContent,
  QuestionForm,
  InputWrapper,
  QuestionContentTitle,
  CustomInput,
  CustomTextArea,
} from "../../pages/abousUsPage/style";

import { useForm } from "hooks";
import { useSnackbar } from "notistack";
import { QuestionService } from "service";
import React from "react";

import { useInternacionalization } from "hooks";

const QuestionFormSection = function ({ children, bg }) {
  const { form, cleanForm, getInputProps, isValid } = useForm({
    user_name: "",
    user_email: "",
    user_phone: "",
    content: "",
  });

  const snackBar = useSnackbar();

  const { translate } = useInternacionalization();

  const sendQuestion = async () => {
    await QuestionService.createQuestion(form);

    snackBar.enqueueSnackbar(translate("Message sent"), {
      variant: "success",
    });

    cleanForm();
  };

  return (
    <QuestionFormWrapper bg={bg}>
      <Container>
        <QuestionContent>
          <QuestionContentTitle>
            {children ? children : "CONTACT US"}
          </QuestionContentTitle>
          <QuestionForm>
            <InputWrapper>
              <CustomInput
                style={{ borderRadius: "25px" }}
                placeholder="Enter your full name *"
                {...getInputProps("user_name")}
              />
              <CustomInput
                style={{ borderRadius: "25px" }}
                placeholder="Enter your email *"
                type="email"
                {...getInputProps("user_email")}
              />
              <CustomInput
                style={{ borderRadius: "25px" }}
                placeholder="Enter your phone number *"
                type="number"
                maxLength={13}
                pattern="[0-9]*"
                {...getInputProps("user_phone")}
              />
            </InputWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <CustomTextArea
                style={{ borderRadius: "25px" }}
                placeholder="Message"
                {...getInputProps("content")}
              />
              <VioletButton disabled={!isValid} onClick={sendQuestion}>
                {translate("Send a question")}
              </VioletButton>
            </div>
          </QuestionForm>
        </QuestionContent>
      </Container>
    </QuestionFormWrapper>
  );
};

export default QuestionFormSection;
