import { useHistory } from "react-router-dom";
import {
  GreenButton,
  Main,
  MainTitle,
  MainWrapper,
  VideoBackground,
  VioletButton,
} from "../../../common/common";
import { apiLoadBlock } from "api";
import { useApiCall } from "hooks";
import { firstBlockId } from "variables";

import { useModal } from "@ebay/nice-modal-react";
import homePageBg from "assets/img/pm1.mp4";
import { MODAL } from "commponets/modals/register";
import { useState } from "react";
import mute from "../../../../assets/img/icon/mute.svg";
import unmuted from "../../../../assets/img/icon/unmute_hover.svg";
import { ControlsWrapper, Ping, SoundIconWrapper, SoundLogo } from "./styles";

const MainSection = function () {
  const { show: showCalculator } = useModal(MODAL.CALCULATOR_MODAL);
  const history = useHistory();
  const [block, isLoading] = useApiCall(apiLoadBlock(firstBlockId - 1), null);
  const [isMuted, setIsMuted] = useState(true);

  const toggleMute = () => {
    setIsMuted((current) => !current);
  };

  // const afterLoad = () => {
  // setIsMuted(false);
  // };

  return (
    <MainWrapper>
      <VideoBackground
        muted={isMuted}
        // onLoadedData={afterLoad}
        type="video/mp4"
        src={homePageBg}
        playsInline
        autoPlay
        loop
      />
      <ControlsWrapper>
        {/* <YtLogo>
          <Link to={{ pathname: "https://www.youtube.com/" }} target="_blank">
            <img src={ytlogo} />
          </Link>
        </YtLogo> */}
        <SoundIconWrapper>
          <SoundLogo onClick={toggleMute}>
            {isMuted ? (
              <img src={mute} />
            ) : (
              <img
                style={{ transform: "translate(-2px, -2px)" }}
                src={unmuted}
              />
            )}
          </SoundLogo>

          <Ping isMuted={isMuted} />
        </SoundIconWrapper>
      </ControlsWrapper>
      <Main>
        {!isLoading && (
          <>
            <MainTitle>{block.title}</MainTitle>
            <span style={{ display: "flex" }}>
              <GreenButton onClick={() => history.push("/products")}>
                {block.button_text}
              </GreenButton>
              <VioletButton
                style={{ marginLeft: "16px" }}
                onClick={() => {
                  showCalculator();
                }}
              >
                {block.button_text_2}
              </VioletButton>
            </span>
          </>
        )}
      </Main>
    </MainWrapper>
  );
};

export default MainSection;
