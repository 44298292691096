import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { getPhoto } from "helpers";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ArrowUpIcon from "../../../assets/img/arrowUp.png";
import logo from "../../../assets/img/icon/360.svg";
import { Container } from "../common";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding-bottom: 0px;
  margin-top: 30px;
  max-width: 580px;
  margin-bottom: 25px;
  border-radius: 25px;

  width: auto;
  overflow: hidden;
  width: 100%;
  @media screen and (max-width: 521px) {
    height: 500px;
  }
`;

const CardContentWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  height: 720px;
  width: 100%;
  border-radius: 25px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6.5px);
  overflow: hidden;
  padding: 25px;
`;

const CardContent = styled.div`
  margin-top: 110px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 521px) {
    margin-top: 0px;
  }
`;

const TextContent = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// const Blur = styled.div`
//   position: absolute;
//   background: rgba(0, 0, 0, 0.5);
//   backdrop-filter: blur(6.5px);
// `;
const Title = styled.div`
  font-size: 27px;
  font-family: ProximaNova;
  font-weight: 600;
  white-space: nowrap; /* border-bottom: 2px solid white; */
  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;

const ChooseRoom = styled(Title)`
  font-size: 17px;
  margin-top: 12px;
  white-space: nowrap;
  @media screen and (max-width: 521px) {
    margin-top: 40px;
  }
`;

const Logo360 = styled.div`
  height: 100%;
  @media screen and (max-width: 521px) {
    img {
      height: 108px;
    }
  }
`;

const Room = styled.div`
  font-size: 20px;
  font-family: ProximaNova;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
`;

const Rooms = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  @media screen and (max-width: 521px) {
    text-align: center;
    flex-direction: column;
    gap: 15px;
  }
`;
const Separator = styled.div`
  border: 1.2px solid #ffffff;
  margin: 0 auto;
  width: 85%;
  border-radius: 4px;
`;

const CardImage = styled.img`
  border-radius: 25px;
  object-fit: cover;
  max-width: 100vw;

  width: 100%;
  height: 720px;
  /* @media screen and(max-width: 1180px) {
    height: 200%;
  } */
`;

const Card360 = ({ bg = false }) => {
  const { show: show360 } = useModal(MODAL.MODAL_360);
  const { roofType } = useParams();
  const handleClick = (roomid) => {
    show360({ roomid: roomid });
  };

  return (
    <CardWrapper>
      <Container>
        <CardImage src={getPhoto(bg)} alt="" />

        <CardContentWrapper>
          <CardContent>
            <Logo360>
              <img src={logo} />
            </Logo360>

            <TextContent>
              <Title>MAKE YOUR 360 TOUR </Title>
              <Separator />

              {roofType === "gable" ? (
                <Rooms>
                  <Room onClick={() => handleClick("NdLNK")}>Living room</Room>
                  <Room onClick={() => handleClick("NdLNR")}>Bedroom</Room>
                  <Room onClick={() => handleClick("NdLNj")}>Bedroom 2</Room>
                  <Room onClick={() => handleClick("NdLNH")}>Bathroom</Room>
                </Rooms>
              ) : (
                <Rooms>
                  <Room onClick={() => handleClick("NTrwQ")}>Living room</Room>
                  <Room onClick={() => handleClick("NTr2l")}>Bedroom</Room>
                  <Room onClick={() => handleClick("NTr2m")}>Bedroom 2</Room>
                  <Room onClick={() => handleClick("NTr2h")}>Bathroom</Room>
                </Rooms>
              )}

              <img
                style={{ marginTop: 16 }}
                src={ArrowUpIcon}
                width={35}
                height={35}
              />

              <ChooseRoom> CHOOSE YOUR ROOM </ChooseRoom>
            </TextContent>
          </CardContent>
        </CardContentWrapper>
      </Container>
    </CardWrapper>
  );
};

export default Card360;
