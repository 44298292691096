import axiosBase from "../axiosBase";

export const apiLoadAreaOptions = () => axiosBase("area_option/");
export const apiLoadRoomOptions = () => axiosBase("room_option/");
export const apiLoadRoofTypes = () => axiosBase("roof_option/");
export const apiLoadCombinations = (body) =>
  axiosBase.post("combination/", body);
export const apiLoadExterior = () => axiosBase("room_option/exterior");
export const apiLoadSchemas = (roofId) =>
  axiosBase(`combination/schemas?roof_id=${roofId}`);
