import { useState } from "react";
import { HugeContainer } from "../../../common/common";

import {
  LightBackground,
  LightContent,
  LightImage,
  LightSwitcher,
  LightText,
  LightTitle,
  LightWrapper,
  NightImage,
  Paragraph,
  Description,
} from "./style";

import { getPhoto } from "helpers";

const LightningSection = function ({ hl }) {
  const [switchValue, setSwitchValue] = useState(false);

  return (
    <LightWrapper>
      <HugeContainer>
        <LightContent>
          <LightText>
            <LightTitle>{hl?.title}</LightTitle>
            <Description>
              {hl?.descriptions?.length
                ? hl.descriptions.map((desc) => (
                    <Paragraph key={desc.id}>{desc.content}</Paragraph>
                  ))
                : hl.description}
            </Description>
          </LightText>
          <LightBackground>
            <NightImage src={getPhoto(hl?.dark_image)} />
            <LightImage src={getPhoto(hl?.light_image)} night={switchValue} />

            <LightSwitcher night={switchValue}>
              Day or night view
              <button onClick={() => setSwitchValue(!switchValue)} />
            </LightSwitcher>
          </LightBackground>
        </LightContent>
      </HugeContainer>
    </LightWrapper>
  );
};

export default LightningSection;
