import {
  Background,
  CommonContent,
  MainWrapper,
  Main,
  MainTitle,
} from "../common/common";
import ConstructorSection from "../common/Constructor/ConstructorSection";
import RoofSection from "./section/RoofSection";
// import productsBg from "../../assets/img/productsBg.png";
import { useState } from "react";
import { useEffect } from "react";
import { Api } from "../homePage/api";

const RoofPage = function () {
  const [data, setData] = useState();
  useEffect(
    // () => setData(Api.houses.filter((item) => item.roof_type === "gable")),
    () => setData(Api.houses),
    []
  );
  return (
    <CommonContent>
      <MainWrapper>
        {/* <Background src={productsBg} /> */}
        <Main>
          <MainTitle>HOUSE WITH A GABLE ROOF</MainTitle>
        </Main>
      </MainWrapper>
      {data && <RoofSection houses={data} />}
      <ConstructorSection />
    </CommonContent>
  );
};

export default RoofPage;
