import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  background: #fff;
  position: relative;
`;

export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 751px;
  max-height: 407px;
`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 20px;
  width: 16px;
  height: 16px;
  ::after,
  ::before {
    top: 8px;
    position: absolute;
    content: "";
    display: block;
    width: 22.62px;
    height: 1px;
    background: #262626;
  }
  ::after {
    transform: rotateZ(45deg);
  }
  ::before {
    transform: rotateZ(-45deg);
  }
`;
