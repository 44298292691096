import styled from "styled-components";

export const VideoWrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
`;
