import { WideWrapper, StyledWideButton } from "../style";

const templateItems = ["Interior", "Exterior"];

const InteriorExteriorSelect = (props) => {
  const { setHouseLook, houseLook } = props;

  return (
    <WideWrapper>
      {templateItems.map((item) => (
        <StyledWideButton
          className={item === houseLook && "checked"}
          onClick={() => setHouseLook(item)}
        >
          {item}
        </StyledWideButton>
      ))}
    </WideWrapper>
  );
};

export default InteriorExteriorSelect;
