import NiceModal from "@ebay/nice-modal-react";
import FormPopUp from "commponets/form/FormPopUp";
import Notification from "commponets/form/Notification";
import Calculator from "commponets/calculator/Calculator";
import Subscribe from "commponets/form/Subscribe";
import SubscribeNotification from "commponets/form/SubscribeNotification";
import Modal360 from "./modal360";
import ImagePreviewModal from "./image-preview";

export const MODAL = {
  FORM_MODAL: "FORM_MODAL",
  NOTIFICATION_MODAL: "NOTIFICATION_MODAL",
  CALCULATOR_MODAL: "CALCULATOR_MODAL",
  SUBSCRIBE_MODAL: "SUBSCRIBE_MODAL",
  SUBSCRIBE_NOTIFICATION_MODAL: "SUBSCRIBE_NOTIFICATION_MODAL",
  MODAL_360: "MODAL_360",
  IMAGE_PREVIEW_MODAL: "IMAGE_PREVIEW_MODAL",
};

NiceModal.register(MODAL.FORM_MODAL, FormPopUp);
NiceModal.register(MODAL.NOTIFICATION_MODAL, Notification);
NiceModal.register(MODAL.CALCULATOR_MODAL, Calculator);
NiceModal.register(MODAL.SUBSCRIBE_MODAL, Subscribe);
NiceModal.register(MODAL.SUBSCRIBE_NOTIFICATION_MODAL, SubscribeNotification);
NiceModal.register(MODAL.MODAL_360, Modal360);
NiceModal.register(MODAL.IMAGE_PREVIEW_MODAL, ImagePreviewModal);
