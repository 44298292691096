import facebookSvg from "../../../tmp/header/socials/facebookSvg.svg";
import facebookHoverSvg from "../../../tmp/header/socials/facebookHoverSvg.svg";
import linkedinSvg from "../../../tmp/header/socials/linkedinSvg.svg";
import linkedinHoverSvg from "../../../tmp/header/socials/linkedinHoverSvg.svg";
import youtubeSvg from "../../../tmp/header/socials/youtubeSvg.svg";
import youtubeHoverSvg from "../../../tmp/header/socials/youtubeSvg.svg";
import pinterestSvg from "../../../tmp/header/socials/pinterestSvg.svg";
import pinterestHoverSvg from "../../../tmp/header/socials/pinterestHoverSvg.svg";
import instagramSvg from "../../../tmp/header/socials/instagramSvg.svg";
import instagramHoverSvg from "../../../tmp/header/socials/instagramHoverSvg.svg";
export const HeaderSocial = [
  {
    icon: facebookSvg,
    hover: facebookHoverSvg,
  },
  {
    icon: linkedinSvg,
    hover: linkedinHoverSvg,
  },
  {
    icon: youtubeSvg,
    hover: youtubeHoverSvg,
  },
  {
    icon: pinterestSvg,
    hover: pinterestHoverSvg,
  },
  {
    icon: instagramSvg,
    hover: instagramHoverSvg,
  },
];
