import {
  InvestmentItemTitleWrapper,
  InputSliderWrapper,
  InputSlider,
  InvestementItem,
  InvestmentItemTitle,
  InvestmentValueRange,
} from "./style";

const InvestmentServiceItem = ({ service, value, onChange }) => {
  return (
    <InvestementItem>
      <InvestmentItemTitleWrapper>
        <InvestmentItemTitle>{service.title}</InvestmentItemTitle>
        {service.description}
      </InvestmentItemTitleWrapper>
      <InputSliderWrapper>
        <InvestmentValueRange>
          {service.minValue} {service.tag}
        </InvestmentValueRange>
        <InputSlider
          min={service.minValue}
          max={service.maxValue}
          valueLabelDisplay="on"
          onChange={(event, value) => {
            onChange({
              target: {
                value,
              },
            });
          }}
          defaultValue={service.minValue}
        />
        <InvestmentValueRange style={{ width: "90px" }}>
          {service.maxValue} {service.tag}
        </InvestmentValueRange>
      </InputSliderWrapper>
    </InvestementItem>
  );
};

export default InvestmentServiceItem;
