import { ModalOverlay, ModalWrapper, ModalImage, CloseButton } from "./style";
import { create, useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";

const ImagePreviewModal = create(({ image }) => {
  const { hide, visible } = useModal(MODAL.IMAGE_PREVIEW_MODAL);

  if (!visible) {
    return <></>;
  }

  return (
    <ModalOverlay>
      <ModalWrapper>
        <CloseButton onClick={hide} />
        <ModalImage src={image} alt="image" />
      </ModalWrapper>
    </ModalOverlay>
  );
});

export default ImagePreviewModal;
