import styled from "styled-components";
import { ContainerMobileContent } from "../../../common/common";
export const AboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 100px 0;
  background: #fff;

  @media screen and (max-width: 1180px) {
    padding: 48px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 32px 0;
  }
`;

export const AboutImg = styled.img`
  border-radius: 25px;
  object-fit: cover;
  max-width: 100%;
  @media screen and (max-width: 1180px) {
    width: calc(100vw - 50px);
  }
  @media screen and (max-width: 600px) {
    width: calc(100vw - 50px);
  }
`;

export const AboutContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1180px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
export const AboutContent = styled(ContainerMobileContent)`
  font-style: normal;
  font-weight: normal;
  font-size: 14.5px;
  line-height: 140%;
  font-family: "Proxima Nova", sans-serif;
  color: #262626;
  margin-left: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1180px) {
    margin: 0;
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    font-size: 12px;
  }
`;

export const AboutTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  font-family: "Proxima Nova", sans-serif;
  line-height: 43px;
  text-align: center;
  color: inherit;
  margin-bottom: 24px;
  @media screen and (max-width: 600px) {
    margin-bottom: 16px;
    font-size: 28px;
    line-height: 36px;
    text-align: start;
  }
`;

export const AboutButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 100%;
  margin-top: 16px;

  @media screen and (max-width: 1180px) {
    margin-bottom: 20px;
  }
`;

export const Paragraph = styled.p`
  margin: 4px 0px;
`;

export const Description = styled.div`
  text-align: justify;
`;
