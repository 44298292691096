import { CommonTitle, HugeContainer } from "../../../common/common";

import { getPhoto } from "helpers";

import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { useInternacionalization } from "hooks";
import {
  BookButton,
  ButtonWrap,
  ButtonWrapContent,
  CalcButton,
  PDFButton,
  PriceWrapper,
  SpecificationContent,
  SpecificationContentDescription,
  SpecificationContentItem,
  SpecificationImage,
  SpecificationItem,
  SpecificationItemContent,
  SpecificationItemImage,
  SpecificationWrapper,
} from "./style";

import PdfDown from "../../../../assets/img/icon/PdfDown.svg";

const SpecificationSection = function ({ specification, product }) {
  const { translate } = useInternacionalization();
  const { show: showForm } = useModal(MODAL.FORM_MODAL);
  const { show: showCalculator } = useModal(MODAL.CALCULATOR_MODAL);

  return (
    <SpecificationWrapper>
      <HugeContainer>
        <SpecificationContent>
          <SpecificationContentDescription>
            <CommonTitle>Specification</CommonTitle>

            <SpecificationContentItem>
              {[...specification.stats]
                .sort((a, b) => a.position - b.position)
                .map((stat) => (
                  <SpecificationItem key={stat.id}>
                    <SpecificationItemImage src={getPhoto(stat.icon)} />
                    <SpecificationItemContent>
                      {stat.title}
                      <div style={{ fontWeight: "400" }}>
                        {stat.description}
                      </div>
                    </SpecificationItemContent>
                  </SpecificationItem>
                ))}
            </SpecificationContentItem>

            <PriceWrapper>
              <div>Total Price + VAT 0%:</div>
              <div>
                {specification.total_price} {specification.currency}
              </div>
            </PriceWrapper>
            <ButtonWrap>
              <ButtonWrapContent>
                <CalcButton
                  onClick={() => {
                    showCalculator();
                  }}
                >
                  {translate("Calculator")}
                </CalcButton>
                <BookButton onClick={() => showForm()}>
                  BOOK A CONSULTATION
                </BookButton>

                <PDFButton>
                  <img src={PdfDown} />
                </PDFButton>
              </ButtonWrapContent>
            </ButtonWrap>
          </SpecificationContentDescription>
          <SpecificationImage
            style={{ width: "50%", height: "500px", objectFit: "cover" }}
            src={getPhoto(specification.image)}
          />
        </SpecificationContent>
      </HugeContainer>
    </SpecificationWrapper>
  );
};

export default SpecificationSection;
