import { Container } from "../../../common/common";
import {
  ChooseAdvantage,
  ChooseAdvantageTitle,
  ChooseAdvantageWrapper,
  ChooseBackground,
  ChooseContainer,
  ChooseContainerWrapper,
  ChooseContent,
  ChooseDescription,
  ChooseSvg,
  ChooseTitle,
  ChooseWrapper,
  AdvantageDescription,
  Paragraph,
} from "./style";

import { apiLoadBlock, apiLoadBlockWithAdvantages } from "api";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { firstBlockId } from "variables";

const ChooseSection = function () {
  const [advantages, isAdvantagesLoading] = useApiCall(
    apiLoadBlockWithAdvantages(firstBlockId + 2),
    null
  );

  const [block, isLoading] = useApiCall(apiLoadBlock(firstBlockId + 2), null);

  if (isLoading) {
    return <></>;
  }

  return (
    <ChooseWrapper>
      <ChooseContent>
        <ChooseBackground src={getPhoto(block.image)} auto={true} />
        <ChooseContainerWrapper>
          <Container>
            <ChooseContainer>
              <ChooseDescription>
                <ChooseTitle>{block.title}</ChooseTitle>
                <Paragraph>
                  {block?.descriptions.length
                    ? block.descriptions.map((item, index) => (
                        <Paragraph key={index}>{item.content}</Paragraph>
                      ))
                    : block.description}
                </Paragraph>
              </ChooseDescription>
              <ChooseAdvantageWrapper>
                {advantages?.map((advantage) => (
                  <ChooseAdvantage>
                    <ChooseSvg src={getPhoto(advantage.image)} />
                    <ChooseAdvantageTitle>
                      {advantage.title}
                    </ChooseAdvantageTitle>
                    <AdvantageDescription>
                      {advantage.description}
                    </AdvantageDescription>
                  </ChooseAdvantage>
                ))}
              </ChooseAdvantageWrapper>
            </ChooseContainer>
          </Container>
        </ChooseContainerWrapper>
      </ChooseContent>
    </ChooseWrapper>
  );
};

export default ChooseSection;
