import { useForm } from "hooks";
import { Container } from "../../../common/common";

import { InvestmentCaption, InvestmentServiceItem } from "./";

import {
  InsestementTitle,
  InvestementCalculator,
  InvestementText,
  InvestementWrapper,
} from "./style";

const InvestementSection = function ({
  services,
  estimated_tax,
  price,
  delivery_cost,
  product,
}) {
  const initialValue = services.reduce((acc, item) => {
    acc[item.title] = item.minValue;
    return acc;
  }, {});

  const { form, getInputProps } = useForm(initialValue, 0);

  const totalPrice = Object.keys(form).reduce((acc, key, index, keys) => {
    if (index === keys.length - 1) {
      acc -= form[key];
    } else if (key.includes("day")) {
      acc *= form[key];
    } else {
      acc += form[key];
    }
    return acc;
  }, 0);

  return (
    <Container>
      <InvestementWrapper>
        <InsestementTitle>Calculate your investment return</InsestementTitle>
        <InvestementText>
          Disclaimer: current numbers are just an example of ENA house
        </InvestementText>
        <InvestementText>
          {product.title} cost {price} € + Estimated Taxes {estimated_tax}% +
          Approx. Delivery Cost {delivery_cost} €
        </InvestementText>
        <InvestementCalculator>
          {services &&
            services.map((item) => (
              <InvestmentServiceItem
                {...getInputProps(item.title)}
                service={item}
                key={item.id}
              />
            ))}
        </InvestementCalculator>
        <InvestmentCaption
          totalPrice={price + (price / 100) * estimated_tax + delivery_cost}
          value={totalPrice}
        />
      </InvestementWrapper>
    </Container>
  );
};
// Math.floor(totalPrice-(totalPrice/100)*estimated_tax)
export default InvestementSection;
