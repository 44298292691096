import { CommonSlider } from "commponets/sliders/CommonSlider";
import { Container } from "../../../common/common";
import {
  PartnersContent,
  PartnersContentDescription,
  PartnersContentTitle,
  PartnersContentWrapper,
  PartnersImage,
  PartnersImageWrapper,
  PartnersWrapper,
  Description,
} from "./style";

import { useEffect, useRef } from "react";

import { apiLoadBlock, apiLoadPartners } from "api";
import { getPhoto } from "helpers";
import { useApiCall } from "hooks";
import { firstBlockId } from "variables";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  rows: 5,
};
const mobileSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  rows: 5,
};

const PartnersSection = function () {
  const [block, isLoading] = useApiCall(apiLoadBlock(firstBlockId + 5), null);
  const [partners, isPartnersLoading] = useApiCall(apiLoadPartners, []);

  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      setTimeout(() => {
        sliderRef.current.slickPrev();
      }, 200);
    }
  }, [isPartnersLoading]);

  if (isLoading) {
    return <></>;
  }

  return (
    <PartnersWrapper>
      <PartnersContentWrapper>
        <Container>
          <PartnersContent>
            <PartnersContentDescription>
              <PartnersContentTitle>{block.title}</PartnersContentTitle>
              <Description>{block.description}</Description>
            </PartnersContentDescription>
            <CommonSlider
              id="partners-slick"
              settings={settings}
              mobileSettings={mobileSettings}
              partner={true}
              sliderRef={sliderRef}
            >
              {!isPartnersLoading &&
                partners.map((partner) => (
                  <PartnersImageWrapper key={partner.id}>
                    <PartnersImage
                      src={getPhoto(partner.image)}
                    ></PartnersImage>
                  </PartnersImageWrapper>
                ))}
            </CommonSlider>
          </PartnersContent>
        </Container>
      </PartnersContentWrapper>
    </PartnersWrapper>
  );
};

export default PartnersSection;
