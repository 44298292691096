import { useRef, useState } from "react";
import { Button, Image, Layer, Preview, Video, Wrapper } from "./styles";

import PauseIcon from "../../../assets/img/icon/pause.png";
import PlayIcon from "../../../assets/img/icon/play.png";

const VideoPlayer = (props) => {
  const { src, preview, styles } = props;
  const videoRef = useRef(null);
  const timer = useRef(null);
  const [isPlaying, setPlaying] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(true);

  const toggleVideo = () => {
    setPlaying((prev) => !prev);

    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };

  const onMouseMove = () => {
    clearTimeout(timer.current);
  };

  const onMouseLeave = () => {
    if (!isPlaying) {
      clearTimeout(timer.current);
    }
  };

  const onEnded = () => {
    videoRef.current.currentTime = 0;
    setPlaying(false);
    setPreviewVisible(true);
  };

  return (
    <Wrapper style={styles}>
      {isPreviewVisible && <Preview src={preview} alt="construction preview" />}

      <Layer
        onMouseLeave={onMouseLeave}
        isVisible={!isPlaying}
        onMouseMove={onMouseMove}
      >
        <Button onClick={toggleVideo}>
          {isPlaying ? (
            <Image alt="someValue" src={PauseIcon} />
          ) : (
            <Image alt="someValue" src={PlayIcon} />
          )}
        </Button>
      </Layer>
      <Video
        onPlay={() => setPreviewVisible(false)}
        onEnded={onEnded}
        ref={videoRef}
        src={src}
      />
    </Wrapper>
  );
};

export default VideoPlayer;
