// import {
//   Document,
//   Text,
//   Image,
//   View,
//   Page,
//   PDFDownloadLink,
//   StyleSheet,
// } from "@react-pdf/renderer";

import { PDFButton } from "./styles";

const PdfButtonComponents = ({ Document }) => {
  return (
    <>
      {/* <PDFDownloadLink document={Document}>
        <PDFButton />
      </PDFDownloadLink> */}
    </>
  );
};

export default PdfButtonComponents;

// const styles = StyleSheet.create({
//   img: {
//     marginBottom: "10px",
//   },

//   container: {
//     padding: "30px",
//     textAlign: "center",
//     display: "flex",
//     color: "#1f2937",
//     justifyContent: "center",
//     flexDirection: "column",
//   },

//   aboutThisHouse: {
//     fontSize: "15px",
//   },

//   planWrapper: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "center",
//   },
//   heading: { marginBottom: 5, fontSize: 22 },
// });

export const PDFDocument = ({ product }) => {
  return (
    <></>
    // <Document>
    //   <Page>
    //     <View style={styles.container}>
    //       <Image
    //         style={styles.img}
    //         src={
    //           "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlvguNopCtALKDHX8GjqLvIRhE2OH0jg3M6g&usqp=CAU"
    //         }
    //       />

    //       <Text style={styles.heading}>About</Text>

    //       <Text style={styles.aboutThisHouse}>{product.about.description}</Text>
    //     </View>
    //   </Page>

    //   <Page>
    //     <View style={{ ...styles.container, paddingHorizontal: "100px" }}>
    //       <Text style={styles.heading}>Plan of this house</Text>

    //       <View styles={styles.planWrapper}>
    //         {product.plan.images.map((image) => (
    //           <Image
    //             style={{ ...styles.img, height: "250px" }}
    //             src={
    //               "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlvguNopCtALKDHX8GjqLvIRhE2OH0jg3M6g&usqp=CAU"
    //             }
    //           />
    //         ))}
    //       </View>
    //     </View>

    //     <View style={{ ...styles.container, paddingHorizontal: "100px" }}>
    //       <Text style={styles.heading}>Specification of this house</Text>

    //       <Image
    //         style={{ ...styles.img, height: "250px" }}
    //         src={
    //           "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlvguNopCtALKDHX8GjqLvIRhE2OH0jg3M6g&usqp=CAU"
    //         }
    //       />

    //       {product?.specification?.stats?.map((item) => (
    //         <View style={{ display: "flex" }}>
    //           <Image
    //             style={{ ...styles.img, height: "40px", width: "40px" }}
    //             src={
    //               "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlvguNopCtALKDHX8GjqLvIRhE2OH0jg3M6g&usqp=CAU"
    //             }
    //           />
    //           <View>
    //             <Text>{item.title}</Text>
    //             <Text>{item.title}</Text>
    //           </View>
    //         </View>
    //       ))}
    //     </View>
    //   </Page>
    // </Document>
  );
};
