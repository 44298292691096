import { Link } from "react-router-dom";
import styled from "styled-components";
import { VioletButton } from "../common";
export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  top: ${(props) => (props.scroll ? "0" : "0px")};
  width: 100%;
  z-index: 1000;
  height: 71px;
  transition: all 0.3s linear;
  color: ${(props) => (props.scroll ? "#000" : "#fff")};
  background-color: ${(props) => (props.scroll ? "#fff" : "null")};
  z-index: 10;
  @media screen and (max-width: 1180px) {
    top: 0;
    z-index: 6;
    height: 55px;
    background: ${(props) =>
      props.scroll
        ? "#fff"
        : props.main
        ? "transparent"
        : "linear-gradient(180deg, rgba(0, 0, 0, 0.62) 0%, rgba(0, 0, 0, 0.39) 54.69%, rgba(0, 0, 0, 0) 100%)"};
  }
`;
export const Container = styled.div`
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1180px) {
    margin: 0 25px;
  }
`;
export const Body = styled.div`
  padding: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;
export const Logo = styled.div`
  z-index: 3;
  object-fit: cover;
  img {
    display: block;
    &.mobile {
      display: none;
    }
  }
  @media screen and (max-width: 1180px) {
    width: 112px;
    img {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }
  & img {
    position: relative;
    width: 112px;
  }
  @media screen and (max-width: 768px) {
    width: 70px;
    img {
      position: relative;
      width: auto;
      height: 23px;
    }
  }
`;
export const Menu = styled.div`
  color: ${(props) => (props.scroll ? "#000" : "#fff")};
  right: 0;
  width: auto;
  z-index: 2;
  @media screen and (max-width: 1180px) {
    background: #fff;
    padding: 91px 25px 0 25px !important;
    align-items: start;

    ::before,
    ::after {
      content: "";
      display: block;
      width: 100%;
      left: 0;
      height: 1px;
      background: #d1d1d1;
      position: absolute;
      z-index: 5;
      top: 55px;
    }
  }
  @media screen and (max-width: 1180px) {
    left: 0;
  }
`;

export const Nav = styled(Link)`
  display: flex;
  color: inherit;
  align-items: center;
  white-space: nowrap;
  position: relative;
  font-family: ProximaNova;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); */
  margin-left: 35px;
  &::after {
    height: 2px;
    background: ${(props) =>
      props.scroll
        ? "linear-gradient(270deg, rgba(38, 38, 38, 0) 0%, #262626 47.4%, rgba(38, 38, 38, 0) 100%);"
        : "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 46.87%, rgba(255, 255, 255, 0) 100%);"};
    content: "";
    width: ${(props) => (props.isActiveNav ? "100%" : "0")};
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0%);
    position: absolute;
    transition: 0.3s;
  }
  &:hover::after {
    width: 100%;
    transition: 0.3s;
  }
  @media screen and (max-width: 1180px) {
    color: #262626;
    font-size: 23px;
    font-weight: 600;
    margin: 0;
    height: 28px;
    margin-bottom: 18px;
    text-transform: none;
    ::after {
      display: none;
    }
  }
`;
export const Lang = styled.button`
  color: ${(props) => (props.scroll ? "#000" : "#fff")};
  padding: 2px 4px;
  border-radius: 6px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 27px;
  white-space: nowrap;
  position: relative;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;

  &::after {
    height: 2px;
    content: "";
    background: ${(props) =>
      props.scroll
        ? "linear-gradient(270deg, rgba(38, 38, 38, 0) 0%, #262626 47.4%, rgba(38, 38, 38, 0) 100%);"
        : "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 46.87%, rgba(255, 255, 255, 0) 100%);"};
    position: absolute;
    bottom: -2px;
    left: 40%;
    width: 0;
    transform: translate(-50%, 0%);
    transition: 0.3s;
    @media screen and (max-width: 1180px) {
      display: none;
    }
  }
  div {
    position: relative;
    width: 10px;
    height: 5.7px;
    ::before,
    ::after {
      top: 2px;
      position: absolute;
      content: "";
      display: block;
      width: 7.6px;
      height: 2px;
      background: ${(props) => (props.scroll ? "#000" : "#fff")};
      transition: transform 0.3s linear;
    }
    ::after {
      left: 18px;
      transform: ${(props) =>
        props.lang || props.langMobile ? "rotateZ(-45deg)" : "rotateZ(45deg)"};
    }
    ::before {
      left: 22.9px;
      transform: ${(props) =>
        props.lang || props.langMobile ? "rotateZ(45deg)" : "rotateZ(-45deg)"};
    }
  }
  &:hover::after {
    width: 50%;
    transition: 0.3s;
  }
`;
export const LangMobile = styled(Lang)`
  @media screen and (max-width: 1180px) {
    display: flex;
    margin: 16px 0 8px 0;
    padding: 0;
    z-index: 3;
    background-color: #0f0f0f;
  }
`;
export const Invision = styled.div`
  width: 100%;
  height: 0px;
`;
export const Box = styled.div`
  width: 40%;
  display: none;
  margin-left: 44px;
  @media screen and (max-width: 1180px) {
    position: relative;
    display: block;
    width: 38px;
    height: 18px;
  }
  button {
    position: relative;
    top: auto;
    right: auto;
    height: 16px;
    width: 38px;
  }
`;
export const LangBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1180px) {
    display: none;
  }
`;
export const LangBoxMobile = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 1180px) {
    display: ${(props) => (props.isActiveMenu ? "none" : "block")};
  }
`;
export const Langs = styled.div`
  display: ${(props) => (props.lang ? "flex" : "none")};
  background: ${(props) => (props.scroll ? "#fff" : "none")};
  flex-direction: column;
  position: absolute;
  top: 36px;
  left: -4px;
  width: 55px;
  padding: 2px 4px;
  border-radius: 6px;
  @media screen and (max-width: 1180px) {
    background: none;
  }
`;
export const LangsMobile = styled(Langs)`
  display: ${(props) => (props.langMobile ? "flex" : "none")};
  @media screen and (max-width: 1180px) {
    padding: 0;
    left: -19px;
    top: auto;
    width: 75px;
    background-color: #0f0f0f;
    z-index: 3;
  }
`;
export const LangItem = styled.button`
  color: ${(props) => (props.scroll ? "#000" : "#fff")};
  text-transform: uppercase;
  font-family: ProximaNova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  padding: 0 10px 0 14px;
  margin: 0 0 8px 0;
  &::first-letter {
    text-transform: uppercase;
  }
  @media screen and (max-width: 1180px) {
    display: block;
    padding: 0;
  }
`;
export const BoxLine = styled.div`
  display: none;
  @media screen and (max-width: 1180px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
  }
`;
export const SmallLine = styled.div`
  width: 65%;
  height: 1px;
  margin: 2px 0;
  background: linear-gradient(#d1d1d1);
`;
export const BigLine = styled(SmallLine)`
  width: 100%;
`;

export const CalculatorButton = styled(VioletButton)`
  border-radius: 50px;
  margin-left: 62px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  height: 45px;
  @media screen and (max-width: 1180px) {
    margin-left: ${(props) => (props.header ? "0" : "62px")};
    height: 37px;
    margin-top: 14px;
  }
`;

export const BurgerMenu = styled.span`
  transition: all 0.3s linear;
  z-index: 15;
  width: 38px;
  background: ${(props) => (props.scroll ? "#262626;" : "#fff")};
  ::before,
  ::after {
    background: ${(props) => (props.scroll ? "#262626;" : "#fff")};
  }
  @media screen and (max-width: 1180px) {
    background: ${(props) => (props.scroll ? "#262626;" : "#fff")};
    ::before,
    ::after {
      background: ${(props) => (props.scroll ? "#262626;" : "#fff")};
    }
  }
  &.active {
    ::before,
    ::after {
      width: 23px;
    }
  }
`;

export const HeaderHr = styled.hr`
  display: none;
  background: #d1d1d1;
  border: 0;
  margin: 32px 0;
  @media screen and (max-width: 1180px) {
    display: block;
    width: 100%;
    height: 1px;
  }
`;
