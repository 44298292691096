import { locales } from ".";

const useInternacionalization = () => {
  const lang = localStorage.getItem("language") || "En";

  const translate = (key) => locales?.[lang.toLowerCase()]?.[key];
  return { translate };
};

export default useInternacionalization;
