import styled from "styled-components";
import { CommonTitle, ContainerMobileContent, VioletButton } from "../common";

export const DescriptionBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  padding: 0px 16px;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
`;

export const DescriptionBlockContent = styled.div`
  display: flex;
  max-width: 1380px;
  margin: 0 auto;
  align-items: start;
  flex-direction: row;
  font-size: 15px;
  line-height: 150%;
  color: #262626;
  margin: ${(props) => (props.dir ? "100px 0 100px 0px" : "100px 0px 100px 0")};
  direction: ${(props) => (props.dir ? "rtl" : "ltr")};
  @media screen and (max-width: 1180px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 48px 0px 20px 0px;
  }
  @media screen and (max-width: 600px) {
    direction: ltr;
    flex-direction: column-reverse;
    margin: 24px 0px 0px 0px;
    font-size: 16px;
  }
`;

export const DescriptionContentConteiner = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DescriptionBlockImage = styled.img`
  border-radius: 25px;
  margin: ${(props) => (props.dir ? "0 0 0 36px" : "0 36px 0 0")};
  max-height: ${(props) => (props.height ? props.height : "387px")};
  height: ${(props) => (props.fill ? "100%" : "auto")};
  max-width: ${(props) => props.width || 800}px;
  object-fit: cover;
  @media screen and (max-width: 1180px) {
    margin: 0;
    width: calc(100vw - 50px);
    border-radius: 0px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    min-height: 250px;
    /* border-radius: 0px; */
  }
`;

export const DescriptionBlockText = styled(ContainerMobileContent)`
  direction: ltr;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media screen and (max-width: 1180px) {
    width: 100%;
    margin-bottom: 28px;
  }

  @media screen and (max-width: 769px) {
    font-size: 12px;
    line-height: 150%;
  }
`;

export const DescriptionBlockTitle = styled(CommonTitle)`
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-width: 769px) {
    font-size: 28px;
    margin-bottom: 16px;
    line-height: 36px;
  }
`;

// export const InteriorButtonWrapper = styled.div`
//   display: block;

//   @media screen and (max-width: 1180px) {
//     display: flex;
//   }
// `;

export const InteriorButton = styled(VioletButton)`
  margin-top: 40px;
  width: fit-content;
  border-radius: 50px;
  @media screen and (max-width: 1180px) {
    margin: 0;
    margin-top: 24px;
    margin-left: 24px;
    margin-bottom: 24px;
    width: fit-content;
  }
`;

export const Paragraph = styled.p`
  margin: 6px 0px;
`;
export const Description = styled.div`
  text-align: justify;
`;
