import styled from "styled-components";
import { QuestionTitle } from "../homePage/sections/Question/style";
import { Container, ContainerMobileContent, VioletButton } from "./common";

import { useForm } from "hooks";
import { useSnackbar } from "notistack";
import { EmailService, QuestionService } from "service";

import { useModal } from "@ebay/nice-modal-react";
import { MODAL } from "commponets/modals/register";
import { useInternacionalization } from "hooks";

export const QuestionFormWrapper = styled.div`
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  display: flex;
  justify-content: center;
`;
const QuestionContent = styled(ContainerMobileContent)`
  display: flex;
  margin: 100px 0;
  @media screen and (max-width: 1180px) {
    flex-direction: column;
    margin: 60px 0;
  }
`;
export const QuestionForm = styled.div`
  display: flex;
  textarea,
  input {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #262626;
    border: 0;
    border-bottom: 1px solid #262626;
    outline: 0;
    resize: none;
    width: 372px;
    padding: 7px 16px;
    margin-bottom: 16px;
    position: relative;
    text-align: start;
    ::placeholder {
      color: #262626;
    }
    :last-child {
      margin: 0;
    }
  }
  textarea {
    height: 100%;
    background: ${(props) => (props.bg ? props.bg : "#fff")};
    ::placeholder {
      position: absolute;
      bottom: 4px;
    }
  }
  input {
    height: 45px;
  }
  button {
    width: 372px;
    padding: 10px 0;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    button {
      text-align: center;
    }
    input,
    button {
      height: 37px;
    }
    input,
    textarea,
    button {
      margin: 0;
      margin-top: 8px;
      width: 325px;
      :last-child {
        margin-top: 8px;
      }
    }
  }
`;
const InputWrapper = styled.div`
  display: flex;

  flex-direction: column;
  margin-right: 28px;
  @media screen and (max-width: 600px) {
    margin-right: 0;
  }
`;
const QuestionContentTitle = styled(QuestionTitle)`
  margin: 0;
  @media screen and (max-width: 1180px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 27px;
    display: flex;
    justify-content: center;
  }
`;

const CustomInput = styled.input`
  border-radius: 50px;
  font-family: "Proxima Nova", sans-serif;
  &:hover {
    &::placeholder {
      color: #6c6c6c;
    }
    border-bottom-color: #750c78;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
    border-bottom-color: #750c78;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const CustomTextArea = styled.textarea`
  border-radius: 25px;
  font-family: ProximaNova !important;

  &:hover {
    &::placeholder {
      color: #6c6c6c;
    }
    border-bottom-color: #750c78;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
    border-bottom-color: #750c78;
  }
`;

const QuestionFormSection = function ({ children, bg }) {
  const { form, cleanForm, getInputProps, isValid } = useForm({
    user_name: "",
    user_email: "",
    user_phone: "",
    content: "",
  });

  const snackBar = useSnackbar();
  const { show } = useModal(MODAL.NOTIFICATION_MODAL);

  const { translate } = useInternacionalization();

  const sendQuestion = async () => {
    show();

    await QuestionService.createQuestion(form);
    await EmailService.sendQuestionEmail(form);

    snackBar.enqueueSnackbar(translate("Message sent"), {
      variant: "success",
    });

    cleanForm();
  };

  return (
    <QuestionFormWrapper bg={bg}>
      <Container>
        <QuestionContent>
          <QuestionContentTitle>
            {children ? children : "CONTACT US"}
          </QuestionContentTitle>
          <QuestionForm>
            <InputWrapper>
              <CustomInput
                {...getInputProps("user_name")}
                placeholder="Enter your full name *"
              />
              <CustomInput
                {...getInputProps("user_email")}
                placeholder="Enter your email *"
                type="email"
              />
              <CustomInput
                {...getInputProps("user_phone")}
                placeholder="Enter your phone number *"
                type="number"
                maxLength={13}
                pattern="[0-9]*"
              />
            </InputWrapper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <CustomTextArea
                {...getInputProps("content")}
                placeholder="Message"
              />
              <VioletButton disabled={!isValid} onClick={sendQuestion}>
                {translate("Send a question")}
              </VioletButton>
            </div>
          </QuestionForm>
        </QuestionContent>
      </Container>
    </QuestionFormWrapper>
  );
};

export default QuestionFormSection;
