import BaseRouter from "./router";
import { SnackbarProvider } from "notistack";
import "./assets/css/slick-theme.css";
import "./assets/css/slick.css";
import "./commponets/modals/register";
import "./localization/i18";
import NiceModal from "@ebay/nice-modal-react";

const App = () => (
  <NiceModal.Provider>
    <SnackbarProvider maxSnack={3}>
      <BaseRouter />
    </SnackbarProvider>
  </NiceModal.Provider>
);

export default App;
