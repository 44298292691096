/* eslint-disable no-unused-vars */
import { Container } from "../../../common/common";
import NewsItem from "../../../common/News/NewsItem";

import {
  NewsSlider,
  NewsSliderSectionTitle,
  NewsSliderWrapper,
  NewsWrapper,
} from "./style";

const settings = {
  speed: 500,
  infinite: false,
  dots: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  rows: 1,
};
const mobileSettings = {
  ...settings,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};
const NewsSliderSection = function ({ news }) {
  return (
    <Container>
      <NewsSliderWrapper>
        <NewsSliderSectionTitle>Recent posts</NewsSliderSectionTitle>

        {news.length > 2 ? (
          <NewsSlider
            mobileWidth={1180}
            settings={settings}
            mobileSettings={mobileSettings}
          >
            {news &&
              news.map((item, index) => (
                <NewsItem key={index} news={item} slider={true} />
              ))}
          </NewsSlider>
        ) : (
          <NewsWrapper>
            {news.map((item, index) => (
              <NewsItem key={index} news={item} slider={false} />
            ))}
          </NewsWrapper>
        )}
      </NewsSliderWrapper>
    </Container>
  );
};

export default NewsSliderSection;
