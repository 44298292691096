import { CheckWrapper, StyledButton, StyledImg } from "../style";

import gableRoof from "assets/img/icon/gableRoof.svg";
import gableRoofHover from "assets/img/icon/gableRoofHover.svg";
import flatRoof from "assets/img/icon/flatRoof.svg";
import flatRoofHover from "assets/img/icon/flatRoofHover.svg";

import { useApiCall } from "hooks";
import { apiLoadRoofTypes } from "api/routes/calculator";
import { useEffect } from "react";

const RoofSelect = (props) => {
  const { setRoof, currentRoof } = props;
  const [roofs, isLoading] = useApiCall(apiLoadRoofTypes, null);

  useEffect(() => {
    if (!currentRoof && roofs) {
      setRoof(roofs[0]);
    }
  }, [roofs]);

  if (isLoading || !roofs) {
    return <></>;
  }

  return (
    <CheckWrapper>
      <StyledButton
        className={currentRoof?.type === "gable" && "checked"}
        onClick={setRoof.bind(null, roofs[0])}
      >
        <StyledImg className="hover" src={gableRoofHover}></StyledImg>
        <StyledImg className="def" src={gableRoof}></StyledImg>
        {roofs[0]?.name}
      </StyledButton>

      <StyledButton
        className={currentRoof?.type === "flat" && "checked"}
        onClick={setRoof.bind(null, roofs[1])}
      >
        <StyledImg className="hover" src={flatRoofHover}></StyledImg>
        <StyledImg className="def" src={flatRoof}></StyledImg>
        {roofs[1]?.name}
      </StyledButton>
    </CheckWrapper>
  );
};

export default RoofSelect;
